<template>
  <div class="confirmation">
    <slot name="header"></slot>
    <h1 class="title-1">{{ modalData.title }}</h1>
    <p>{{ modalData.text }}</p>
    <button
      class="primary-button"
      @click="modalResolve(!modalData.dangerousAction)"
    >
      {{ modalData.dangerousAction ? $t("cancel") : modalData.button }}
    </button>
    <button
      class="cancel-button"
      @click="modalResolve(modalData.dangerousAction)"
    >
      {{ modalData.dangerousAction ? modalData.button : $t("cancel") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ConfirmationModal",
  computed: {
    ...mapGetters({
      modalData: "modals/modalData",
      modalResolve: "modals/modalResolve",
    }),
  },
};
</script>

<style scoped lang="scss">
.confirmation {
  padding: 50px;

  .primary-button {
    width: 350px;
    margin-top: 50px;
  }

  .cancel-button {
    margin-top: 30px;
    color: $red-color;
    font-size: 16px;
    font-weight: $font-bold;
    background: none;
    cursor: pointer;
  }
}
</style>
