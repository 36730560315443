<template>
  <div
    class="input"
    :class="{
      'color-input': type === 'color',
      open: inputOpen,
      title: label,
      'no-required': !required,
      error,
    }"
  >
    <label class="label" v-if="label" :for="label">{{ label }}</label>
    <ColorPicker v-if="colorsOpen" v-model="color" />
    <div
      class="input-container"
      :style="{
        height: inputOpen ? filteredOption.length * 40 + 50 + 'px' : '50px',
      }"
      @click="inputOpen = !inputOpen"
      v-clickout="() => (inputOpen = false)"
    >
      <input
        ref="input"
        :id="label"
        v-model="value"
        :placeholder="placeholder"
        :style="{ color: color || 'unset' }"
        v-if="hasSearch"
      />
      <div class="value" v-else :class="{ placeholder: !value }">
        {{ optionValue(value) || placeholder }}
      </div>
      <div
        class="color-picker"
        :style="{
          background: color || (error ? '#ec2654' : '#79adf9'),
        }"
        v-if="type === 'color'"
        @click.stop="colorsOpen = !colorsOpen"
      >
        <span v-if="!color"><AddIcon /></span>
      </div>
      <div v-else class="arrow">
        <DropdownIcon />
      </div>
      <div class="options">
        <div
          class="option"
          v-for="option in filteredOption"
          :key="optionValue(option)"
          @click.stop="selectOption(option)"
          :style="{ color: option.color || '#031e41' }"
        >
          {{ optionValue(option) }}
        </div>
      </div>
    </div>
    <div class="error-message" v-if="error">
      <ErrorIcon />{{
        type === "color"
          ? $t("projects.colorMandatoryField")
          : $t("mandatoryField")
      }}
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/inputs/ColorPicker";

export default {
  name: "SelectInput",
  components: {
    ColorPicker,
  },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: [String, Object],
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
    },
    colorValue: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    hasSearch: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "updateColor"],
  data() {
    return {
      inputOpen: false,
      search: "",
      value: "",
      color: "",
      error: false,
      colorsOpen: false,
    };
  },
  computed: {
    filteredOption() {
      if (this.search) {
        return this.options.filter((option) =>
          this.optionValue(option)
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toUpperCase()
            .includes(
              this.search
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .toUpperCase()
            )
        );
      } else {
        return this.options;
      }
    },
  },
  watch: {
    modelValue(modelValue) {
      if (this.value !== modelValue) {
        this.value = modelValue;
      }
    },
    colorValue(modelValue) {
      if (this.color !== modelValue) {
        this.color = modelValue;
      }
    },
    value(value) {
      this.$emit("update:modelValue", value);
      if (this.inputOpen) {
        this.search = this.value;
      }
    },
    color(value) {
      this.$emit("updateColor", value);
      this.colorsOpen = false;
    },
  },
  created() {
    if (this.type === "color") {
      this.value = this.modelValue;
      this.color = this.colorValue;
    } else {
      this.value = this.modelValue;
    }
  },
  mounted() {
    this.$el.style.setProperty(
      "--maVariable",
      this.options.find((item) => item.name === this.value)?.color || "#000000"
    );
  },
  methods: {
    optionValue(option) {
      if (option?.firstName) {
        return `${option.firstName} ${option.lastName}`;
      } else if (option?.name) {
        return option.name;
      } else {
        return option;
      }
    },
    selectOption(option) {
      if (this.type === "color") {
        this.value = option.name;
        this.color = option.color;
      } else {
        this.$el.style.setProperty("--maVariable", option.color || "#000000");
        this.value = option;
      }
      this.inputOpen = false;
    },
    check() {
      this.error = false;

      if (this.required) {
        this.error = this.error || !this.value;
      }

      if (this.type === "color") {
        this.error = this.error || !this.color;
      }

      return this.error;
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  @include input;
  position: relative;
  width: 100%;
  height: 68px;
  overflow: visible;

  &.no-required {
    height: 50px;
    padding-bottom: 0;
  }

  &.light-shadow {
    margin: 0;
    .input-container {
      background-color: white;
      border: none;
      color: black;
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.08);
    }
  }

  &.light {
    .input-container {
      background-color: white;
    }
  }

  &.title {
    height: 94px;

    .input-container {
      top: 26px;
    }
  }

  &.open {
    .input-container {
      max-height: 210px;
      z-index: 100;
    }

    .arrow {
      transform: rotate(180deg);
    }
  }

  .input-container {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 50px;
    transition: 0.3s;
    overflow: hidden;

    .value {
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      padding: 0 20px;
      flex-shrink: 0;
      color: var(--maVariable);

      &.placeholder {
        font-style: italic;
        color: mix($black-color, $white-color, 20%);
      }
    }
  }

  .options {
    width: 100%;
    height: 100%;
    border-top: 1px solid #d8d8d8;
    height: 100%;
    overflow-y: auto;

    .option {
      padding: 0 15px;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: #e6ebf2;
      }
    }
  }

  .color-picker {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
    transition: 0.3s;
    position: absolute;

    svg {
      width: 10px;
      color: $white-color;
    }

    &:hover {
      box-shadow: 0 0 0 4px rgba($primary-color, 0.07);
    }
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
      transition: 0.3s;
      width: 100%;
    }
  }
}
</style>
