<template>
  <div
    class="product-card"
    :style="{
      backgroundColor: `${project?.color}15`,
    }"
  >
    <div class="title">
      <div class="dates">
        {{ new Date(project?.periods[0]?.startDate).toLocaleDateString() }}
        -
        {{
          new Date(
            project?.periods[project?.periods.length - 1]?.endDate
          ).toLocaleDateString()
        }}
      </div>
      <div
        class="product-delete"
        @click="deleteProject(project)"
        v-if="deletable"
      >
        <TrashIcon />
      </div>
    </div>
    <div class="names">
      <span
        :style="{
          color: project?.color,
          fontWeight: 'bold',
        }"
      >
        {{ project?.name }},
      </span>
      <span
        :style="{
          color: project?.color,
        }"
      >
        {{ project?.clientName }}
      </span>
    </div>
    <div class="team">
      <TeamMember
        :userId="project?.referrer.id"
        :role="project?.referrer.businessRole"
        :referrer="true"
        :hoverDisabled="true"
      />
      -
      <TeamMember
        v-for="member in project?.members"
        :key="member.id"
        :userId="member.id"
        :role="member.businessRole"
        :hoverDisabled="true"
      />
    </div>
  </div>
</template>

<script>
import TeamMember from "../TeamMember.vue";

export default {
  components: { TeamMember },

  name: "ProductCard",

  props: {
    project: {
      type: Object,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async deleteProject(project) {
      const response = await this.$store.dispatch("modals/setModal", {
        modalType: "ConfirmationModal",
        modalData: {
          header: { ...project, headerType: "product" },
          dangerousAction: true,
          title: this.$t("deleteProductModal.title"),
          text: this.$t("deleteProductModal.text"),
          button: this.$t("deleteProductModal.button"),
        },
      });

      if (response) {
        this.$store.dispatch("project/deleteProject", project.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-card {
  border-radius: 6px;
  padding: 6px 12px 12px 12px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dates {
      font-weight: $font-bold;
      letter-spacing: 0.5px;
      line-height: 42px;
    }
  }

  .product-delete {
    color: $red-color;
    width: 40px;
    cursor: pointer;
  }

  .names {
    margin-bottom: 12px;
  }

  .team {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
  }
}
</style>
