import { DateTime, Interval } from "luxon";

export default {
  weekdays: ["D", "L", "M", "M", "J", "V", "S", "D"],
  locale: "fr-FR",

  getMonthsVisible({ year }, period) {
    let interval = Interval.fromDateTimes(
      new Date(year, 0, 1),
      new Date(year, 11, 31)
    );
    if (year === period.start.year) {
      interval = Interval.fromDateTimes(period.start, new Date(year, 11, 31));
    } else if (year === period.end.year) {
      interval = Interval.fromDateTimes(new Date(year, 0, 1), period.end);
    }
    return interval.count("months");
  },
  getDaysPerMonth({ year, month }) {
    return DateTime.utc(year, month).daysInMonth;
  },
  isToday({ year, month, day }) {
    return DateTime.utc(year, month, day).hasSame(DateTime.utc(), "day");
  },
  getMonthName({ year, month, day }) {
    return new Date(year, month - 1, day).toLocaleString(this.locale, {
      month: "long",
    });
  },
  isMonday({ year, month, day }) {
    return new Date(year, month - 1, day).getDay() === 1;
  },
  getDateString({ year, month, day }) {
    return new Date(year, month - 1, day).toLocaleDateString();
  },
  getWeekNumber({ year, month, day }) {
    return DateTime.utc(year, month, day).weekNumber;
  },
  getWeekdayName({ year, month, day }) {
    return this.weekdays[new Date(year, month - 1, day).getDay()];
  },
  getWeekday({ year, month, day }) {
    return new Date(year, month - 1, day).getDay();
  },
  isSameDate(date1, date2) {
    return date1.hasSame(date2, "day");
  },
};

export function isExpired(rawDate) {
  const expirationDate = new Date(rawDate);
  var now = new Date();
  return expirationDate < now;
}

export function getTimeBeforeProjectEnd(project) {
  return project.periods.length
    ? new Date(project.periods[project.periods.length - 1].endDate) - new Date()
    : new Date();
}

export function getTimeBeforeProjectStart(project) {
  return project.periods.length
    ? new Date(project.periods[0].startDate) - new Date()
    : new Date();
}
