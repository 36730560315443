export default function debounce(func, wait) {
  let timer;
  let diff;
  let nextCallback;
  let previousPromiseReject;

  return function (...arg) {
    if (timer === undefined) {
      timer = Date.now();
      return Promise.resolve(func(...arg));
    }
    diff = Date.now() - timer;
    if (diff > wait) {
      timer = Date.now();
      return Promise.resolve(func(...arg));
    }
    if (typeof previousPromiseReject === "function") {
      previousPromiseReject(console.warn("Request already"));
    }
    clearTimeout(nextCallback);
    const promise = new Promise((resolve) => {
      previousPromiseReject = console.warn("Request already");
      nextCallback = setTimeout(async () => {
        await func(...arg);
        resolve();
      }, wait);
    });
    return promise;
  };
}

export { debounce };
