<template>
  <svg
    viewBox="0 0 44 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon_activity</title>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icon_activity">
        <g
          id="ICONS/Activity"
          transform="translate(10.083333, 11.083333)"
          stroke="CurrentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <g id="ICONS/admin" transform="translate(0.000000, 0.000000)">
            <polyline
              id="admin1"
              stroke-width="2.0625"
              points="8.19270833 23.8333333 0 23.8333333 0 0.000125437936 8.19270833 0.000125437936"
            ></polyline>
            <polyline
              id="admin2"
              stroke-width="1.7875"
              points="15.0470182 10.1822067 17.875 10.5981297 14.8958333 13.4607184 15.5993818 17.5026042 11.9166667 15.5942117 8.23395155 17.5026042 8.9375 13.4607184 5.95833333 10.5981297 10.075519 10.0080673 11.9166667 6.33072917 13.1901016 8.8777932"
            ></polyline>
            <polyline
              id="admin3"
              stroke-width="2.0625"
              points="15.640625 0 23.8333333 0 23.8333333 23.8332079 15.640625 23.8332079"
            ></polyline>
          </g>
        </g>
        <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
      </g>
    </g>
  </svg>
</template>
