<template>
  <div class="form">
    <Textarea
      class="textarea"
      :label="$t('projects.projectDescription')"
      :placeholder="$t('projects.projectDescription')"
      v-model="project.description"
      ref="description"
    />
    <label class="label">{{ $t("projects.linksAssociated") }}</label>
    <div
      class="link"
      v-for="(link, index) in project.links"
      :key="link.id"
      :ref="`link_${link.id}`"
    >
      <div class="logo">
        <img
          v-if="link.url || link.domain"
          :src="`https://s2.googleusercontent.com/s2/favicons?domain=${
            link.domain || getLinkDomain(link.url)
          }&sz=64`"
          alt="site logo"
        />
      </div>
      <label for="link" class="link-label" v-if="link.type">
        {{ link.name }}
      </label>
      <Input
        class="input"
        :htmlType="'text'"
        :placeholder="$t('projects.linkType')"
        v-model="link.name"
        v-else
      />
      <Input
        class="input url"
        :htmlType="'text'"
        :placeholder="$t('projects.addALink')"
        v-model="link.url"
      />
      <div
        class="delete"
        @click="deleteLink(link)"
        v-if="index !== project.links.length - 1"
      >
        <TrashIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createId } from "@/helpers/guid.js";

import Input from "@/components/inputs/Input";
import Textarea from "@/components/inputs/Textarea.vue";

export default {
  name: "AssetsForm",
  components: {
    Input,
    Textarea,
  },
  data() {
    return {
      links: [
        {
          name: this.$t("projects.defaultLinks.drive"),
          url: "",
          domain: "drive.google.com",
          id: createId(),
          type: [1, 2],
        },
        {
          name: this.$t("projects.defaultLinks.backlog"),
          url: "",
          domain: "visualstudio.com",
          id: createId(),
          type: [1, 2],
        },
        {
          name: "",
          url: "",
          id: createId(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      project: "project/currentProject",
    }),
    lastLinkName() {
      return this.project.links[this.project.links.length - 1]?.name;
    },
  },
  created() {
    if (!this.project.id) {
      this.project.links = this.links;
    } else {
      this.addLink();
    }
  },
  watch: {
    lastLinkName(value, oldValue) {
      if (!oldValue && value) {
        this.addLink();
      }
    },
  },
  methods: {
    addLink() {
      this.project.links.push({
        name: "",
        url: "",
        id: createId(),
      });
    },
    deleteLink(link) {
      const index = this.project.links.findIndex((l) => l.id === link.id);
      this.project.links.splice(index, 1);
    },
    getLinkDomain(url) {
      const usefullDomains = ["invisionapp.com", "visualstudio.com"];

      let domainInfo = new URL(url);
      const domain = usefullDomains.find((domain) =>
        domainInfo.hostname.includes(domain)
      );
      return domain || domainInfo.hostname;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .label {
    color: $secondary-color;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: $font-bold;
    margin-bottom: 24px;
    width: 100%;
  }
}

.input {
  width: 45%;
  margin-bottom: 40px;
}

.textarea {
  width: 100%;
  margin-bottom: 40px;
}

.link {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 56px;
  padding-left: 30px;
  transition: 0.3s;
  opacity: 0;
  overflow: hidden;
  opacity: 1;
  overflow: visible;

  &:last-child {
    margin-bottom: 0;
  }

  .logo {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-grey-color;
    margin-right: 30px;
    flex-shrink: 0;
    overflow: hidden;

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  .link-label {
    width: 40%;
    font-size: 12px;
    opacity: 0.6;
  }

  .input {
    margin: 0;
    width: 40%;
    padding: 0;

    &.url {
      width: calc(100% - 120px);
      margin: 15px 0 0 56px;
    }
  }
}

.delete {
  width: 48px;
  height: 48px;
  margin: 15px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red-color;
  cursor: pointer;
  transition: 0.3s;

  svg {
    width: 48px;
  }
}
</style>
