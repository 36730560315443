<template>
  <svg viewBox="0 0 18 16">
    <title>Group 8</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-323.000000, -325.000000)">
        <g transform="translate(30.000000, 56.000000)">
          <g transform="translate(0.000000, 249.000000)">
            <g transform="translate(294.000000, 21.000000)">
              <path
                d="M7.99995993,5.26267332 C7.06142363,5.26267332 6.29801132,6.04194078 6.29801132,6.99996932 C6.29801132,7.95799786 7.06142363,8.73726532 7.99995993,8.73726532 C8.93849622,8.73726532 9.70190853,7.95799786 9.70190853,6.99996932 C9.70190853,6.04194078 8.93849622,5.26267332 7.99995993,5.26267332 M7.99995993,9.7337459 C6.52322797,9.7337459 5.32200571,8.50737119 5.32200571,6.99996932 C5.32200571,5.49256745 6.52322797,4.26639727 7.99995993,4.26639727 C9.47669188,4.26639727 10.6781145,5.49256745 10.6781145,6.99996932 C10.6781145,8.50737119 9.47669188,9.7337459 7.99995993,9.7337459"
                id="Fill-16"
                fill="currentColor"
              ></path>
              <path
                d="M7.99995993,5.26267332 C7.06142363,5.26267332 6.29801132,6.04194078 6.29801132,6.99996932 C6.29801132,7.95799786 7.06142363,8.73726532 7.99995993,8.73726532 C8.93849622,8.73726532 9.70190853,7.95799786 9.70190853,6.99996932 C9.70190853,6.04194078 8.93849622,5.26267332 7.99995993,5.26267332 Z M7.99995993,9.7337459 C6.52322797,9.7337459 5.32200571,8.50737119 5.32200571,6.99996932 C5.32200571,5.49256745 6.52322797,4.26639727 7.99995993,4.26639727 C9.47669188,4.26639727 10.6781145,5.49256745 10.6781145,6.99996932 C10.6781145,8.50737119 9.47669188,9.7337459 7.99995993,9.7337459 Z"
                id="Stroke-18"
                stroke="currentColor"
                stroke-width="0.58752"
              ></path>
              <path
                d="M8,1.39552279 C5.29740019,1.39552279 2.80498923,2.0103464 0.839753544,3.07800418 C0.459049241,3.2849907 0,2.9992593 0,2.55910614 L0,2.55910614 C0,2.35866464 0.100185343,2.17254039 0.265290788,2.06434289 C2.30766919,0.726702694 5.02669939,0 8,0 C10.9733006,0 13.6923308,0.726702694 15.7347092,2.06434289 C15.8998147,2.17254039 16,2.35866464 16,2.55910614 L16,2.55910614 C16,2.9992593 15.5409508,3.2849907 15.1602465,3.07800418 C13.1950108,2.0103464 10.7025998,1.39552279 8,1.39552279"
                id="Fill-20"
                fill="#0068FF"
              ></path>
              <path
                d="M8,1.39552279 C5.29740019,1.39552279 2.80498923,2.0103464 0.839753544,3.07800418 C0.459049241,3.2849907 0,2.9992593 0,2.55910614 L0,2.55910614 C0,2.35866464 0.100185343,2.17254039 0.265290788,2.06434289 C2.30766919,0.726702694 5.02669939,0 8,0 C10.9733006,0 13.6923308,0.726702694 15.7347092,2.06434289 C15.8998147,2.17254039 16,2.35866464 16,2.55910614 L16,2.55910614 C16,2.9992593 15.5409508,3.2849907 15.1602465,3.07800418 C13.1950108,2.0103464 10.7025998,1.39552279 8,1.39552279 Z"
                id="Stroke-22"
                stroke="#0068FF"
                stroke-width="0.9792"
              ></path>
              <path
                d="M8,12.6044772 C10.7025998,12.6044772 13.1950108,11.9896536 15.1602465,10.9219958 C15.5409508,10.7150093 16,11.0007407 16,11.4408939 L16,11.4408939 C16,11.6413354 15.8998147,11.8274596 15.7347092,11.9356571 C13.6923308,13.2732973 10.9733006,14 8,14 C5.02669939,14 2.30766919,13.2732973 0.265290788,11.9356571 C0.100185343,11.8274596 0,11.6413354 0,11.4408939 L0,11.4408939 C0,11.0007407 0.459049241,10.7150093 0.839753544,10.9219958 C2.80498923,11.9896536 5.29740019,12.6044772 8,12.6044772"
                id="Fill-24"
                fill="#0068FF"
              ></path>
              <path
                d="M8,12.6044772 C10.7025998,12.6044772 13.1950108,11.9896536 15.1602465,10.9219958 C15.5409508,10.7150093 16,11.0007407 16,11.4408939 L16,11.4408939 C16,11.6413354 15.8998147,11.8274596 15.7347092,11.9356571 C13.6923308,13.2732973 10.9733006,14 8,14 C5.02669939,14 2.30766919,13.2732973 0.265290788,11.9356571 C0.100185343,11.8274596 0,11.6413354 0,11.4408939 L0,11.4408939 C0,11.0007407 0.459049241,10.7150093 0.839753544,10.9219958 C2.80498923,11.9896536 5.29740019,12.6044772 8,12.6044772 Z"
                id="Stroke-26"
                stroke="#0068FF"
                stroke-width="0.9792"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
