<template>
  <div
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
    class="people"
    :class="{ hoverDisabled: hoverDisabled }"
    :style="{
      borderColor: userRole?.color || '#06326c',
      boxShadow: referrer ? `0 0 0 4px ${userRole?.color || '#06326c'}1a` : '',
    }"
    ref="people"
  >
    <div class="illustration">
      <img
        :src="user.pictureUrl"
        class="profile-picture"
        v-if="user && user.pictureUrl"
        :alt="`${user.firstName} ${user.lastName}`"
        referrerpolicy="no-referrer"
      />
      <div
        v-else-if="user && !user.pictureUrl"
        class="user-placeholder"
        :style="{
          backgroundColor: userRole?.color,
          boxShadow: `0 0 0 1px ${userRole?.color}`,
        }"
      >
        {{ user.firstName[0] }}{{ user.lastName[0] }}
      </div>
      <div class="placeholder" v-else></div>
    </div>
    <Teleport to="#app">
      <div
        class="hover-title"
        v-if="user && hoverDisabled"
        :style="{
          background: userRole?.color || '#06326c',
          top: `${peopleTopPosition}px`,
          left: `${peopleLeftPosition}px`,
          opacity: showTooltip ? '1' : '0',
        }"
        ref="title"
      >
        <span class="name">{{ user.firstName }} {{ user.lastName }} - </span>
        <span v-if="userRole">{{ userRole?.name }}</span>
      </div>
    </Teleport>
    <div class="delete" @click.stop="deleteTeamMember" v-if="user && edition">
      <CrossIcon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import gsap from "gsap";

export default {
  props: {
    userId: {
      type: String,
    },
    role: {
      type: String,
    },
    referrer: {
      type: Boolean,
    },
    hoverDisabled: {
      type: Boolean,
    },
    edition: {
      type: Boolean,
    },
  },
  name: "TeamMember",
  emits: ["deleteTeamMember"],
  data() {
    return {
      peopleTopPosition: "unset",
      peopleLeftPosition: "unset",
      tilteWidth: 0,
      showTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      users: "user/users",
      usersDeleted: "user/usersDeleted",
      roles: "user/businessRoles",
    }),
    user() {
      return (
        this.users.find((user) => user.id === this.userId) ||
        this.usersDeleted.find(
          (userDeleted) => userDeleted.id === this.userId
        ) ||
        null
      );
    },
    userRole() {
      return this.role
        ? this.roles.find((r) => r.name === this.role)
        : this.roles.find((r) => r.name === this.user?.businessRole);
    },
  },
  mounted() {
    this.tilteWidth = this.$refs.title?.clientWidth;
    gsap.ticker.add(this.titlePosition);
  },
  methods: {
    deleteTeamMember() {
      this.$emit("deleteTeamMember", this.user);
    },
    titlePosition() {
      this.peopleLeftPosition =
        this.$refs.people?.getBoundingClientRect().left -
        this.tilteWidth / 2 +
        this.$refs.people?.getBoundingClientRect().width / 2;
      this.peopleTopPosition =
        this.$refs.people?.getBoundingClientRect().top - 25;

      if (this.peopleLeftPosition < 0) {
        this.peopleLeftPosition = 8;
      }

      if (this.peopleLeftPosition + this.tilteWidth > window.innerWidth) {
        this.peopleLeftPosition = window.innerWidth - this.tilteWidth - 8;
      }
    },
  },

  unmounted() {
    gsap.ticker.remove(this.titlePosition);
  },
};
</script>

<style scoped lang="scss">
.people {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $secondary-color;
  background-color: $white-color;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;

  .illustration {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    .placeholder {
      &::after {
        content: "";
        left: 6px;
        top: -2px;
        position: absolute;
        background-color: $secondary-color;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        border: 1px solid $white-color;
      }

      &::before {
        content: "";
        top: 17px;
        left: -3px;
        position: absolute;
        background-color: $secondary-color;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border: 1px solid $white-color;
      }
    }
  }

  &:hover {
    z-index: 5;
    .info {
      transform: scale(1);
    }

    .delete {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &.hoverDisabled {
    .info {
      display: none;
    }
  }
}

.user-placeholder {
  text-transform: uppercase;
  color: $white-color;
  letter-spacing: 1.5px;
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.delete {
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $red-color;
  color: $white-color;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;

  svg {
    width: 24px;
  }
}

.hover-title {
  position: fixed;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
  color: $white-color;
  font-size: 12px;
  transition: 0.3s;
  z-index: 1000;

  span:last-child {
    margin-left: 4px;
  }
}
</style>
