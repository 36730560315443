<template>
  <div class="eye-container" :style="{ opacity: eye.opacity }">
    <svg :viewBox="`0 0 48 ${height}`" xmlns="http://www.w3.org/2000/svg">
      <ellipse
        class="pupil"
        fill="white"
        :cx="24 + eye.x || 24"
        :cy="24 - eye.height / 2 + eye.y || 24 - eye.height / 2"
        rx="10"
        :ry="eye.height"
      />

      <path
        :d="`
          M 0 ${eye.topEdge}
          L 0 -8
          L 48 -8
          L 48 ${eye.topEdge}
          C 48 ${eye.topEdge} 40 ${eye.topCenter} 24 ${eye.topCenter}
          C 0 ${eye.topCenter} 0 ${eye.topEdge} 0 ${eye.topEdge}
        `"
        fill="#031e41"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        :d="`
          M 0 ${eye.bottomEdge}
          L 0 ${height + 8}
          L 48 ${height + 8}
          L 48 ${eye.bottomEdge}
          C 48 ${eye.bottomEdge} 40 ${eye.bottomCenter} 24 ${eye.bottomCenter}
          C 0 ${eye.bottomCenter} 0 ${eye.bottomEdge} 0 ${eye.bottomEdge}
        `"
        fill="#031e41"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        :d="`
          M 0 ${eye.topEdge}
          C 0 ${eye.topEdge} 8 ${eye.topCenter} 24 ${eye.topCenter}
          C 40 ${eye.topCenter} 48 ${eye.topEdge} 48 ${eye.topEdge}
        `"
        stroke="#0068ff"
        fill="none"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        :d="`
          M 0 ${eye.bottomEdge}
          C 0 ${eye.bottomEdge} 8 ${eye.bottomCenter} 24 ${eye.bottomCenter}
          C 40 ${eye.bottomCenter} 48 ${eye.bottomEdge} 48 ${eye.bottomEdge}
        `"
        stroke="#0068ff"
        fill="none"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "EyeLogo",
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height: 40,
      radius: 1000,
      radiusClose: 150,
      amplitude: 2,
      maxAmplitude: 10,
      center: {
        x: 0,
        y: 0,
      },
      eye: {
        x: 0,
        y: 0,
        height: 10,
        topCenter: 0,
        topEdge: 0,
        bottomCenter: 0,
        bottomEdge: 0,
        opacity: 1,
      },
      open: true,
    };
  },
  mounted() {
    const bounding = this.$el.getBoundingClientRect();
    this.center.x = bounding.left + this.$el.offsetWidth / 2;
    this.center.y = bounding.top + this.$el.offsetHeight / 2;

    this.update();
  },
  watch: {
    x() {
      this.update();
    },
    y() {
      this.update();
    },
    open() {
      if (this.open) {
        gsap.to(this.eye, {
          topCenter: 0,
          topEdge: 8,
          bottomCenter: this.height,
          bottomEdge: this.height - 8,
          height: 10,
          duration: 0.6,
        });
      } else {
        gsap.to(this.eye, {
          x: 0,
          y: -4,
          topCenter: this.height * (3 / 4),
          topEdge: this.height / 2,
          bottomCenter: this.height * (3 / 4),
          bottomEdge: this.height / 2,
          height: 4,
          duration: 0.6,
        });
      }
    },
  },
  methods: {
    mag(vec) {
      return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
    },
    update() {
      const mouse = {
        x: this.x - this.center.x,
        y: this.y - this.center.y,
      };

      const mag = this.mag(mouse);

      this.eye.opacity = 200 / mag;

      if (mag > this.radiusClose) {
        this.open = false;
        return;
      }

      this.open = true;

      this.eye.x = Math.min(
        Math.max(
          (mouse.x / (mag * mag * this.amplitude)) * this.radius,
          this.maxAmplitude * -1
        ),
        10
      );

      this.eye.y = Math.min(
        Math.max(
          (mouse.y / (mag * mag * this.amplitude)) * this.radius,
          this.maxAmplitude * -1
        ),
        this.maxAmplitude
      );
    },
  },
};
</script>

<style scoped lang="scss">
.eye-container {
  height: 32px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    overflow: visible;

    .pupil {
      transition: 0.2s;
    }
  }
}
</style>
