<template>
  <div class="view">
    <router-link :to="{ name: 'Timeline' }">
      <MiruIcon class="logo" />
    </router-link>
    <div class="edition content">
      <button
        class="link-font"
        @click="openProjectEdition"
        v-if="weatherStep < usersProject.length"
      >
        {{ $t("editProduct") }}
      </button>
      <div class="projects" ref="scrollContainer">
        <div
          class="project"
          v-for="project in usersProject"
          :key="project.id"
          ref="project"
        >
          <h1 :style="{ color: project.color }" class="title-1">
            {{ project.name }}
          </h1>
          <h2 class="title-2" :style="{ color: project.color }">
            {{ project.clientName }}
          </h2>
          <MinimalTimeline :periods="project.periods" class="timeline" />
          <ProjectWeather
            :project="project"
            class="weather mail"
            :edition="true"
            :editionFromEmail="true"
          />
        </div>
        <div class="loader project" ref="end">
          <h2 class="title-1">
            {{ $t("weather.weatherReportDone") }} <br />
            {{ $t("weather.seeYouSoon") }}
          </h2>
          <div class="illustration">
            <WeatherIcon
              :weatherId="weather.id"
              v-for="weather in weather"
              :key="weather.name"
              color="#f1f7ff"
              ref="weatherIcon"
            />
          </div>
        </div>
      </div>
      <button class="primary-button" @click="next">
        {{
          weatherStep === usersProject.length
            ? $t("weather.seeProducts")
            : $t("next")
        }}
      </button>
      <div class="navigation">
        <div
          class="point"
          v-for="(project, index) in usersProject.length + 1"
          :key="project.id"
          :class="{ active: index === weatherStep }"
          @click="goToStep(index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import gsap from "gsap";
import weather from "@/enums/weather.json";

import ProjectWeather from "@/components/ProjectWeather";
import WeatherIcon from "@/components/WeatherIcon";
import MinimalTimeline from "@/components/project/MinimalTimeline";

export default {
  name: "WeatherEdition",
  components: {
    ProjectWeather,
    WeatherIcon,
    MinimalTimeline,
  },
  data() {
    return {
      weatherStep: 0,
      weather,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      projects: "project/projects",
    }),
    usersProject() {
      return this.projects.filter(
        (project) => project.referrer.id === this.user.id
      );
    },
  },
  async created() {
    await this.$store.dispatch("project/getProjects");
  },
  methods: {
    async next() {
      this.weatherStep += 1;
      if (this.weatherStep === this.usersProject.length) {
        gsap.to(this.$refs.scrollContainer, {
          scrollTo: { x: this.$refs.end },
          duration: 0.68,
        });
      } else if (this.weatherStep > this.usersProject.length) {
        this.$router.push({ name: "Timeline" });
      } else {
        gsap.to(this.$refs.scrollContainer, {
          scrollTo: { x: this.$refs.project[this.weatherStep] },
          duration: 0.68,
        });
      }
    },
    goToStep(step) {
      this.weatherStep = step;
      if (this.weatherStep === this.usersProject.length) {
        gsap.to(this.$refs.scrollContainer, {
          scrollTo: { x: this.$refs.end },
          duration: 0.68,
        });
      } else {
        gsap.to(this.$refs.scrollContainer, {
          scrollTo: { x: this.$refs.project[this.weatherStep] },
          duration: 0.68,
        });
      }
    },
    openProjectEdition() {
      const project = {
        ...this.usersProject[this.weatherStep],
      };
      this.$store.dispatch("project/setProject", project);
      this.$router.push({ name: "Edition", params: { id: project.id } });
    },
  },
};
</script>

<style scoped lang="scss">
.view {
  background-color: $background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.logo {
  position: fixed;
  top: 54px;
  left: 62px;
  width: 108px;
  cursor: pointer;
}

.title-2 {
  margin-bottom: 40px;
}

.timeline {
  margin-bottom: 54px;
}

.link-font {
  align-self: flex-end;
  padding-right: 40px;
}

.edition {
  border-radius: 14px;
  padding: 40px 0 33px;
  height: 678px;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-width: $layout-breakpoint-large;
}

.projects {
  @include hideScrollbar;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transition: 3s;
  height: 100%;
  width: 100%;
  margin-bottom: 24px;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.primary-button {
  width: 234px;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 54px;

  .point {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: rgba($primary-color, 0.3);
    transition: 0.3s;

    &.active {
      background-color: $primary-color;
    }
  }
}

.loader {
  width: 100%;
  flex-shrink: 0;
  justify-content: flex-start;
  padding-top: 40px;

  .title-1 {
    color: $primary-color;
    text-align: center;
  }

  .illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: $layout-breakpoint-small) {
  .view {
    padding: 12px;
  }
  .edition {
    height: unset;
    padding: 24px 0;
  }

  .logo {
    top: 24px;
    left: 24px;
  }

  .link-font {
    align-self: flex-end;
    padding-right: 12px;
  }

  .timeline {
    margin-bottom: 32px;
  }

  .title-1 {
    text-align: center;
    margin-top: 24px;
  }

  .project {
    @include hideScrollbar;
  }

  .navigation {
    margin-top: 32px;
  }
}
</style>
