import { Component } from "../component";
import { Container } from "@pixi/display";
import { Texture } from "@pixi/core";
import { ProjectWeather } from "./projectWeather";
import { BitmapText } from "@pixi/text-bitmap";
import { RoundedRectangle } from "./RoundedRectangle";

import store from "@/store";

export class ProjectHeader extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.project = this.props.project;
    this.projectColor = this.props.projectColor;
    this.offset = 12;

    this.container = new Container();

    if (this.project.type === 2) {
      this.container.x = this.offset;
      this.createProjectWeather();
    }
    this.createProjectTitle();
    this.createProjectTeamMembers();
  }

  createProjectWeather() {
    const { container } = this;
    this.projectWeather = new ProjectWeather({
      project: this.project,
      projectColor: this.projectColor,
    });

    container.addChild(this.projectWeather.container);
    this.addComponent(this.projectWeather);
  }

  createProjectTitle() {
    const { container } = this;

    this.projectTitle = new BitmapText(
      `${this.project.clientName} - ${this.project.name}`,
      {
        fontName: "Montserrat-Bold",
        fontSize: 16,
        align: "center",
        tint: `0x${this.projectColor}`,
      }
    );
    this.projectTitle.x = this.projectWeather
      ? this.projectWeather.container.width + 10
      : 10;
    this.projectTitle.y = 12;

    this.optimizeObject(this.projectTitle);
    container.addChild(this.projectTitle);
  }

  createProjectTeamMembers() {
    this.teamMemberSprites = [];
    const members = [...this.project.members];
    const referrer = this.project.referrer;

    this.makeMember(referrer, 0);

    members
      .filter((_) => _.id !== referrer.id)
      .forEach((member, index) => {
        this.makeMember(member, index, 50);
      });

    if (this.teamMemberSprites.length > 1) {
      this.addMemberSeparator();
    }
  }

  makeMember(member, index, offset = 0) {
    const { container } = this;
    const color = store.getters["user/userHexColor"](member);
    const uColor = color || `0x${this.project.color.split("#")[1]}`;

    const memberSprite = new RoundedRectangle({
      x:
        (this.projectWeather ? this.projectWeather.container.width : 0) +
        this.projectTitle.width +
        20 +
        index * 40 +
        offset,
      y: 6,
      width: 29,
      height: 29,
      uRadius: [1, 1, 1, 1],
      uColor,
      uOpacity: 1.0,
      uAlpha: 0.9,
      borderWidth: 2,
      tDiffuse: Texture.from(member.pictureUrl),
    });

    this.teamMemberSprites.push(memberSprite);

    container.addChild(memberSprite.mesh);
  }

  addMemberSeparator() {
    const { container } = this;

    this.separator = new BitmapText(`-`, {
      fontName: "Montserrat-Bold",
      fontSize: 16,
      align: "center",
      tint: `0x00000`,
    });
    this.separator.x =
      (this.projectWeather ? this.projectWeather.container.width : 0) +
      this.projectTitle.width +
      56;
    this.separator.y = 10;

    this.optimizeObject(this.separator);
    container.addChild(this.separator);
  }

  refresh() {
    super.refresh();
    this.width = Math.ceil(
      (this.projectWeather ? 100 : 0) +
        12 +
        this.projectTitle.width +
        20 +
        this.teamMemberSprites.length * 40 -
        30 -
        10 * (this.teamMemberSprites.length === 1 ? 1 : 0)
    );
  }
}
