<template>
  <div class="remote-controls">
    <div class="column navigation">
      <button class="secondary-button top" @click="scrollTop()">
        <ArrowIcon />
      </button>
      <button class="secondary-button bottom" @click="scrollBottom()">
        <ArrowIcon />
      </button>
      <button class="secondary-button left" @click="scrollLeft()">
        <ArrowIcon />
      </button>
      <button class="secondary-button right" @click="scrollRight()">
        <ArrowIcon />
      </button>
    </div>
    <div class="column right">
      <div class="zoom">
        <button
          class="secondary-button zoom-out"
          @click="zoomOut()"
          :class="{ disabled: calendarFormatIndex === 1 }"
        >
          <ZoomOutIcon />
        </button>
        <button
          class="secondary-button zoom-in"
          @click="zoomIn()"
          :class="{ disabled: calendarFormatIndex === 3 }"
        >
          <ZoomInIcon />
        </button>
      </div>
      <button class="primary-button stats" @click="goToStats">
        {{ $t("activities") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "remoteControls",
  data() {
    return {
      calendarFormatIndex: 2,
    };
  },
  methods: {
    scrollLeft() {
      const argument = {
        method: "scrollLeft",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
    scrollRight() {
      const argument = {
        method: "scrollRight",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
    scrollTop() {
      const argument = {
        method: "scrollTop",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
    scrollBottom() {
      const argument = {
        method: "scrollBottom",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
    zoomIn() {
      this.calendarFormatIndex += 1;

      const argument = {
        method: "zoomIn",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
    zoomOut() {
      this.calendarFormatIndex -= 1;

      const argument = {
        method: "zoomOut",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
    goToStats() {
      const argument = {
        method: "openRemoteStats",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
      this.$router.push({
        name: "RemoteStatsControls",
        query: { roomToken: this.$route.query.roomToken },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.remote-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $background-color;
  padding: 30px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.column {
  width: 150px;
  height: 150px;

  &.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.navigation,
.zoom {
  border-radius: 18px;
  background: $grey-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
  padding: 6px;
}

.primary-button {
  width: 100%;
}

.navigation svg {
  width: 20px;
}

.zoom svg {
  width: 30px;
}

.left {
  svg {
    transform: rotate(180deg);
  }
}

.top {
  svg {
    transform: rotate(-90deg);
  }
}

.bottom {
  svg {
    transform: rotate(90deg);
  }
}
</style>
