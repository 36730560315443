<template>
  <div class="team-container">
    <div class="members" ref="teams">
      <TeamMember
        class="profil"
        :userId="user.id"
        v-for="user in sortedUsers"
        :key="user.id"
      />
    </div>
    <div
      class="hover-container"
      ref="scroller"
      :class="{ selection: filterByMember?.id }"
    >
      <div
        class="info"
        v-for="user in sortedUsers"
        :key="user.id"
        @click="filterProjectsByMember(user)"
        :class="{ selected: filterByMember?.id === user.id }"
      >
        <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
        <span>{{ user.businessRole }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamMember from "./TeamMember.vue";

export default {
  name: "TeamComponent",
  components: {
    TeamMember,
  },
  computed: {
    ...mapGetters({
      users: "user/users",
      projects: "project/projects",
      filterByMember: "project/filterByMember",
    }),
    sortedUsers() {
      return [...this.users].sort((user1, user2) =>
        user1.firstName.localeCompare(user2.firstName)
      );
    },
  },
  mounted() {
    this.$refs.scroller.addEventListener("scroll", this.setScrollPosition);
  },
  methods: {
    setScrollPosition() {
      this.$refs.teams.scrollTop = this.$refs.scroller.scrollTop;
    },
    filterProjectsByMember(member) {
      //TODO scroll on project in pixi
      if (this.filterByMember?.id === member.id) {
        this.$store.dispatch("project/filterProjectByMember", null);
      } else {
        this.$store.dispatch("project/filterProjectByMember", member);
      }
      window.pixiCalendar.updateProjects(this.projects);
    },
  },
  beforeUnmount() {
    this.$refs.scroller.removeEventListener("scroll", this.setScrollPosition);
  },
};
</script>

<style scoped lang="scss">
.team-container {
  margin-bottom: 30px;
  max-height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 60px;
  background-color: $white-color;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  .hover-container {
    @include hideScrollbar;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 8px;
    border-radius: 30px;
    z-index: 0;
    width: 60px;
    transition: 0.6s 0s;

    &:hover,
    &.selection {
      transition: 0.6s 0.3s;
      width: 300px;
    }
  }

  .members {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    border-radius: 30px;
    z-index: 1;
    pointer-events: none;
  }
}

.profil {
  z-index: 1;
  pointer-events: none;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  background-color: $primary-color;
  box-shadow: 1px 2px 5px 0 rgba(0, 104, 255, 0.3);
  border-radius: 44px;
  color: $white-color;
  height: 44px;
  flex-shrink: 0;
  width: max-content;
  margin-left: 8px;
  margin-top: -2px;
  overflow: hidden;
  transform: scale(0.3);
  transform-origin: left center;
  font-size: 12px;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;

  span {
    width: max-content;
    padding: 0 20px 0 52px;

    &.name {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  &:hover,
  &.selected {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
