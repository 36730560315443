import getters from "./getters";
import actions from "./actions";

export let state = {
  modalType: null,
  modalResolve: null,
  modalReject: null,
  modalData: null,
};

const namespaced = true;

export default {
  state,
  getters,
  actions,
  namespaced,
};
