import getters from "./getters.js";
import actions from "./actions.js";

export const state = {
  notes: [],
  loading: false,
};

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
};
