<template>
  <div class="container">
    <p class="section-title uppercase">{{ $t("discussions") }}</p>
    <div class="conversation">
      <Loader v-if="loading" class="loading"></Loader>
      <template v-else>
        <TransitionGroup name="slide">
          <Note
            v-for="note in oldNotes"
            :note="note"
            :isOld="true"
            :key="note.id"
          />
          <template v-if="newNotes.length">
            <div class="newNote-container">
              <h1 class="newNote">{{ $t("newNotes") }}</h1>
            </div>
            <Note v-for="note in newNotes" :note="note" :key="note.id" />
          </template>
        </TransitionGroup>
      </template>
      <div ref="bottom"></div>
    </div>
    <div class="new-message" ref="messageBox">
      <div class="blue-frame" :class="{ 'tv-mode': loggedInTV }">
        <label class="section-title uppercase" for="newnote">{{
          $t("addNote")
        }}</label>
        <div
          class="custom-textarea"
          ref="text"
          id="newnote"
          :placeholder="$t('announce')"
        >
          <textarea
            class="text-input"
            v-model="text"
            maxlength="310"
            :placeholder="$t('announce')"
          />
          <div class="file-interface" ref="interface">
            <div
              class="blue-frame"
              ref="imageBox"
              :class="{ 'tv-mode': loggedInTV }"
            >
              <div class="eraser" @click="deleteFile">
                <AddIcon />
              </div>
              <img class="img-preview" :src="tempUrl" alt="" />
            </div>
            <div class="section" @click="triggerFileInput">
              <span ref="joinPictureText">{{ $t("joinPicture") }}</span>
              <InsertPictureIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="primary-button" @click="sendNote" :disabled="disableButton">
      {{ $t("sendNote") }}
    </button>
    <input
      type="file"
      ref="file"
      @change="handleFile"
      class="file-input"
      accept=".jpg, .jpeg, .png"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import gsap from "gsap";
import Loader from "@/components/Loader.vue";
import Note from "@/components/activities/discussion/Note.vue";

export default {
  name: "DiscussionComponent",
  components: { Loader, Note },
  data() {
    return {
      loading: true,
      text: "",
      file: null,
      tempUrl: null,
      isSendingNote: false,
      lastScrollTop: 0,
    };
  },
  created() {
    this.fetchNotes();
  },
  mounted() {
    const scroller = document.querySelector(".conversation");
    const container = document.querySelector(".container");
    scroller.addEventListener("scroll", () => {
      if (scroller.scrollTop > 0) {
        gsap.to(container, { "--gradient-height": "10%", duration: 0.2 });
      } else {
        gsap.to(container, { "--gradient-height": "0", duration: 0.2 });
      }
    });
  },
  computed: {
    ...mapGetters({
      notes: "notes/notes",
      loggedInTV: "auth/loggedInTV",
    }),
    disableButton() {
      return !this.text && !this.file;
    },
    oldNotes() {
      return this.notes.filter(
        (item) =>
          new Date(item.createdAt) <
          new Date(this.lastMonday).setHours(11, 0, 0)
      );
    },
    newNotes() {
      return this.notes.filter(
        (item) =>
          new Date(item.createdAt) >
          new Date(this.lastMonday).setHours(11, 0, 0)
      );
    },
    lastMonday() {
      let date = new Date();
      return date.setDate(
        date.getDate() -
          (date.getDay() != 1
            ? (date.getDay() + (7 - 1)) % 7
            : date > new Date().setHours(11, 0, 0)
            ? 0
            : 7)
      );
    },
  },
  methods: {
    async fetchNotes() {
      await this.$store.dispatch("notes/fetchNotes");
      this.loading = false;
      this.$nextTick(() => {
        this.$refs.bottom.scrollIntoView();
      });
    },
    async sendNote() {
      if (!this.isSendingNote) {
        this.isSendingNote = true;
        const payload = new FormData();
        payload.append("Content", this.text);
        payload.append("File", this.file);
        await this.$store.dispatch("notes/sendNote", payload);
        this.resetValues();
        this.$refs.bottom.scrollIntoView({ behavior: "smooth" });
      }
    },
    resetValues() {
      this.text = "";
      this.deleteFile();
      this.isSendingNote = false;
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
    handleFile(e) {
      this.file = e.target.files[0];
      this.tempUrl = URL.createObjectURL(this.file);
      this.displayImgPreview();
    },
    deleteFile() {
      this.file = null;
      this.tempUrl = null;
      this.$refs.file.value = "";
      this.resetImgPreview();
    },
    displayImgPreview() {
      const tl = new gsap.timeline();
      tl.to(this.$refs.interface, {
        height: "80px",
        duration: 0,
      });
      tl.to(this.$refs.text, {
        transformOrigin: "bottom",
        height: "167px",
        duration: 0.1,
      });
      tl.to(this.$refs.imageBox, {
        transformOrigin: "bottom left",
        scale: 1,
        opacity: 1,
        duration: 0.1,
      });
      tl.to(this.$refs.joinPictureText, {
        transform: "translatex(10%)",
        opacity: 0,
        duration: 0.1,
      });
    },
    resetImgPreview() {
      const tl = new gsap.timeline();
      tl.to(this.$refs.imageBox, {
        transformOrigin: "bottom left",
        scale: 0,
        opacity: 0,
        duration: 0.1,
      });
      tl.to(this.$refs.text, {
        transformOrigin: "bottom",
        height: "100px",
        duration: 0.1,
      });
      tl.to(this.$refs.interface, {
        height: "0",
      });
      tl.to(this.$refs.joinPictureText, {
        transform: "translatex(-10%)",
        opacity: 1,
        duration: 0.1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 15px;

  --gradient-height: 0px;

  &::before {
    content: "";
    position: absolute;
    height: var(--gradient-height);
    top: 57px;
    left: 16px;
    right: 16px;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      184deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  p {
    flex-shrink: 0;
  }

  .conversation {
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: flex-end;
    @include hideScrollbar;

    .loading {
      height: 50px;
      width: 50px;
      margin: auto;
    }

    .list {
      position: relative;
      padding: 0;
    }

    .newNote-container {
      width: 100%;
      padding: 21px 3px 10px 25px;

      .newNote {
        text-transform: uppercase;
        color: $dark-blue;
        font-family: $text-font;
        font-size: 10px;
        font-weight: bold;
        line-height: 13px;
      }
    }
  }

  .new-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .blue-frame {
      display: flex;
      flex-direction: column;
      gap: 11px;

      .custom-textarea {
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;

        .text-input {
          width: 100%;
          height: calc(100% - 14px);
          resize: none;
          border: none;
          color: $primary-color;
          font-family: $text-font;
          font-size: 14px;
          font-weight: bold;
          line-height: 23px;

          &::placeholder {
            font-weight: $font-regular;
            font-style: italic;
            color: mix($secondary-color, $white-color, 24%);
          }
        }

        .file-interface {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          height: 0;
          cursor: pointer;
          transition: scale 0.2s ease-in-out;
          padding: 5px 5px 5px 10px;

          .blue-frame {
            width: 60px;
            height: 60px;
            padding: 13px 6px;
            opacity: 0;
            scale: 0;
            position: relative;

            .eraser {
              position: absolute;
              top: -6px;
              right: 5px;
              width: 17px;
              height: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #0068ff;
              border-radius: 50%;
              opacity: 0;
              transition: all 200ms;
              pointer-events: none;

              svg {
                color: white;
                height: 7px;
                rotate: 0;
                transition: rotate 200ms;
              }
            }

            &:hover {
              .eraser {
                opacity: 0.55;
                right: -5px;
                pointer-events: auto;
                &:hover {
                  opacity: 1;
                  scale: 1.2;
                }
                svg {
                  rotate: 45deg;
                }
              }
            }

            .img-preview {
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .section {
            display: flex;
            align-items: center;
            position: absolute;
            right: 5px;
            bottom: 0;
            transition: scale 100ms;

            &:hover {
              scale: 1.1;
            }

            span {
              color: $secondary-color;
              font-family: $text-font;
              font-size: 9px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 12px;
              text-align: right;
            }

            svg {
              height: 32px;
            }
          }
        }
      }
    }
  }
}

.file-input {
  display: none;
}
</style>
