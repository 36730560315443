<template>
  <div class="remote">
    <TopBar />
    <router-view></router-view>
  </div>
</template>

<script>
import TopBar from "@/components/remote/TopBar";
import { mapGetters } from "vuex";

export default {
  name: "RemoteControls",
  components: { TopBar },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  async mounted() {
    if (this.$route.query.roomToken) {
      await this.$socket.start({
        log: false,
      });
      await this.$socket.invoke("joinRoom", this.$route.query.roomToken);
      await this.$socket.invoke("sendInstruction", "ACCESS");
      const argumentUser = {
        method: "setUser",
        user: this.user,
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argumentUser));
      const argument = {
        method: "center",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    }
  },
};
</script>

<style scoped lang="scss">
.remote {
  padding: 100px 0 0 0;
}
</style>
