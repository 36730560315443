<template>
  <div class="input" :class="{ error, light }">
    <label class="label" :for="label">{{ label }}</label>
    <textarea
      class="input-container"
      :id="label"
      :value="modelValue"
      :placeholder="placeholder"
      @input="(e) => $emit('update:modelValue', e.target.value)"
      @blur="check()"
    />
    <div class="error-message" v-if="error">
      <ErrorIcon />{{ $t("mandatoryField") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextareaComponent",
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      error: false,
    };
  },
  methods: {
    check() {
      this.error = false;

      if (this.required) {
        this.error = this.error || !this.modelValue;
      }

      return this.error;
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  @include input;

  .input-container {
    padding: 15px;
    height: 190px;
  }
}
</style>
