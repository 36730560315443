<template>
  <div class="view creation">
    <div class="content">
      <button class="link-font" @click="cancelCreation()">
        {{ $t("projects.cancelCreation") }}
      </button>
      <div class="switch-button">
        <label class="label" :class="{ selected: project.type === 1 }">
          {{ $t("propal") }}</label
        >
        <div
          class="switch"
          @click="project.type = project.type === 1 ? 2 : 1"
          :class="{ product: project.type === 2 }"
        >
          <div class="cursor"></div>
        </div>
        <label class="label product" :class="{ selected: project.type === 2 }">
          {{ $t("product") }}</label
        >
      </div>
      <MainInformationForm :checkError="checkError" />
    </div>
    <PlanningForm :checkError="checkError" />
    <div class="content">
      <AssetsForm />
      <button class="primary-button" @click="createProject">
        {{
          project.type === 1
            ? $t("projects.createPropal")
            : $t("projects.createProduct")
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AssetsForm from "@/components/project/AssetsForm.vue";
import PlanningForm from "@/components/project/PlanningForm.vue";
import MainInformationForm from "@/components/project/MainInformationForm";

export default {
  name: "CreationComponent",
  components: {
    AssetsForm,
    MainInformationForm,
    PlanningForm,
  },
  data() {
    return {
      error: false,
      checkError: false,
    };
  },
  computed: {
    ...mapGetters({
      project: "project/currentProject",
    }),
    projectTypeString() {
      return this.project?.type === 1 ? "propale" : "project";
    },
  },
  async created() {
    if (!this.project) {
      await this.$store.dispatch("project/setProject");
    }
  },
  methods: {
    cancelCreation() {
      this.$router.push({ name: "Timeline" });
    },
    async createProject() {
      if (this.checkError) return;
      this.checkError = true;

      this.$nextTick(async () => {
        if (document.querySelector(".error")) {
          document
            .querySelector(".error")
            .scrollIntoView({ behavior: "smooth" });
          this.checkError = false;
          this.submited = false;
          return;
        }

        await this.$store.dispatch("project/createProject");
        await this.$store.dispatch("project/updateProject");
        this.$router.push({ name: "Timeline" });
      });
    },
  },
  unmounted() {
    this.$store.dispatch("project/resetProject");
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .primary-button {
    margin-top: 70px;
  }

  .link-font {
    align-self: flex-start;
    margin-bottom: 48px;
  }
}

.switch-button {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 54px;

  .label {
    font-size: 16px;
    font-weight: 500;
    color: $secondary-color;

    &.product.selected {
      color: $primary-color;
    }
  }

  .switch {
    position: relative;
    display: flex;
    align-items: center;
    width: 64px;
    height: 24px;
    border-radius: 12px;
    background: mix($secondary-color, $white-color, 20%);
    margin: 0 30px;
    $cursor-width: 32px;
    cursor: pointer;

    .cursor {
      position: absolute;
      left: 0;
      width: $cursor-width;
      height: $cursor-width;
      border-radius: 50%;
      background: $secondary-color;
      box-shadow: 1px 2px 5px 0 rgba($secondary-color, 0.3);
      transition: 0.3s ease-in-out;
    }

    &.product {
      background: mix($primary-color, $white-color, 20%);

      .cursor {
        left: calc(100% - #{$cursor-width});
        background: $primary-color;
        box-shadow: 1px 2px 5px 0 rgba($primary-color, 0.3);
      }
    }
  }
}
</style>
