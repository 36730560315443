import { Component } from "../component";
import { Container } from "@pixi/display";
import { BitmapText } from "@pixi/text-bitmap";
import { RoundedRectangle } from "./RoundedRectangle";

export class ProjectWeatherStats extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.progression = this.props.progression;
    this.label = this.props.label;
    this.projectColor = this.props.projectColor;

    this.container = new Container();
    this.container.y = 35;
    this.container.x = 160;

    this.createLabel();
    this.createGraph();
    this.createProgress();
  }

  createGraph() {
    const { container } = this;

    this.graph = new RoundedRectangle({
      x: 96,
      y: 0,
      width: 100,
      height: 11,
      uRadius: [0.5, 0.5, 0.5, 0.5],
      uOpacity: 0.17,
      uColor: `0x${this.projectColor}`,
    });

    container.addChild(this.graph.mesh);
  }

  createProgress() {
    const { container } = this;

    this.progress = new RoundedRectangle({
      x: this.graph.x,
      y: 0,
      width: this.progression,
      height: 11,
      uRadius: [0.5, 0.5, 0.5, 0.5],
      uColor: `0x${this.projectColor}`,
    });

    container.addChild(this.progress.mesh);
  }

  createLabel() {
    const { container } = this;

    this.label = new BitmapText(`${this.label}`, {
      fontName: "Montserrat-Regular",
      fontSize: 24,
      tint: 0x031e41,
      align: "center",
    });

    this.label.scale.set(0.6);
    this.label.anchor.set(0, 0.5);

    container.addChild(this.label);
  }
}
