<template>
  <div class="modal">
    <div class="section">
      <h1 class="title-h1">{{ deleteNoteTitle }}</h1>
      <p class="standard-text centered">{{ deleteNoteMsg }}</p>
    </div>
    <div class="section">
      <button class="primary-button" @click="closeModal">
        {{ $t("cancel") }}
      </button>
      <p class="standard-text centered cancel" @click="deleteNote">
        {{ $t("deleteConfirm") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DeleteNote",
  computed: {
    ...mapGetters({
      modalResolve: "modals/modalResolve",
      modalData: "modals/modalData",
      user: "auth/user",
    }),
    deleteNoteMsg() {
      return this.modalData.note.owner.id === this.user.id
        ? this.$t("deleteYourNoteMsg")
        : this.$t("deleteNoteMsg");
    },
    deleteNoteTitle() {
      return this.modalData.note.owner.id === this.user.id
        ? this.$t("deleteYourNote")
        : this.$t("deleteNote");
    },
  },
  methods: {
    closeModal() {
      document.querySelector(".modal-container").classList.remove("open");
      this.modalResolve(false);
    },
    deleteNote() {
      this.$store.dispatch("notes/deleteNote", this.modalData.note.id);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: 630px;
  height: 404px;
  padding: 80px 141px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .section {
    display: flex;
    flex-direction: column;
  }

  .section:nth-child(1) {
    gap: 12px;
  }
  .section:nth-child(2) {
    gap: 30px;
  }
}
</style>
