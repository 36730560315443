import getters from "./getters";
import actions from "./actions";

export let state = {
  projects: [],
  currentProject: null,
  clients: [],
  globalWeather: 0,
  filterByMember: null,
  defaultProject: {
    name: "",
    clientName: "",
    color: "",
    description: "",
    type: 2,
    price: 0,
    sprintTotalCount: 0,
    referrer: {
      id: null,
      role: "",
    },
    members: [],
    links: [],
    periods: [],
    weather: 1,
    progression: {
      product: 0,
      design: 0,
      technical: 0,
    },
  },
};

state.currentProject = JSON.parse(JSON.stringify(state.defaultProject));

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
};
