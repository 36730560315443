<template>
  <svg width="10px" height="10px" viewBox="0 0 10 10">
    <title>icon_error</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-526.000000, -461.000000)"
        fill="#EC2654"
        fill-rule="nonzero"
      >
        <g id="Modal-Create" transform="translate(280.000000, 92.000000)">
          <g id="Project-name" transform="translate(231.000000, 281.000000)">
            <g id="Group-18" transform="translate(15.000000, 86.000000)">
              <path
                d="M9.86972934,11.8695817 C9.78071839,11.9562826 9.660509,12.0032544 9.53641453,11.9998245 C9.41879897,12.0001143 9.30596402,11.9532098 9.22309861,11.8695817 L5.0066663,7.63167924 L0.776901385,11.8695817 C0.694035982,11.9532098 0.581201025,12.0001143 0.463585466,11.9998245 C0.339490996,12.0032544 0.21928161,11.9562826 0.130270658,11.8695817 C-0.0434235527,11.6938741 -0.0434235527,11.4107727 0.130270658,11.2350651 L4.37336816,7.00050228 L0.146936398,2.76593942 C-0.0279440525,2.59072252 -0.0279440525,2.3066398 0.146936398,2.13142291 C0.321816849,1.95620601 0.605354082,1.95620601 0.780234533,2.13142291 L5.0066663,6.36598576 L9.23309806,2.13142291 C9.34622587,2.01807744 9.51111326,1.97381099 9.66564874,2.01529831 C9.82018421,2.05678563 9.94089016,2.17772379 9.98229781,2.33255657 C10.0237055,2.48738936 9.97952402,2.65259396 9.86639619,2.76593942 L5.63996443,7.00050228 L9.86972934,11.2350651 C10.0434236,11.4107727 10.0434236,11.6938741 9.86972934,11.8695817 Z"
                id="icon_error"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
