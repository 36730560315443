export default {
  async getRiddle({ state }, weekNumber = null) {
    try {
      const response = await this.$axios.$get("Riddles", {
        params: { weekNumber },
      });
      state.riddle = response.riddle;
    } catch (e) {
      console.error(e);
    }
  },
};
