<template>
  <svg viewBox="0 0 35 35">
    <title>cloudy</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-0.000004, 0.000000)">
        <path
          d="M29.1631746,11.7521974 L29.1744548,11.8068466 C32.3206048,12.191477 34.8244088,14.6786943 34.991194,17.8081834 C35.1544201,20.8666523 33.0313392,23.5312676 30.0513099,24.2919763 L29.8871859,24.3317972 C21.7653422,26.7120147 13.5590077,27.5545201 6.88760016,26.637404 C6.80954062,26.6277029 6.73071303,26.6171259 6.6522475,26.605436 C6.65006176,26.6039116 6.64741513,26.603529 6.64476875,26.603146 C3.04049675,26.0632418 0.203130308,23.1759326 0.0103669368,19.561894 C-0.196288024,15.6874079 2.71002376,12.3494104 6.63735335,11.757572 C7.66605372,6.9774216 11.9722973,3.27396787 17.3176085,3.01437244 C23.064669,2.73525515 28.040502,6.53318863 29.1631746,11.7521974 Z"
          id="Combined-Shape"
          fill="#B8BFC9"
        ></path>
        <path
          d="M23.3569544,16 C27.4033303,16 30.683634,19.0331935 30.683634,22.7747499 C30.683634,22.8844098 30.6808162,22.9934612 30.675244,23.1018457 C33.0680854,23.1017491 35.0000044,24.8881338 35.0000044,27.0918537 C35.0000044,28.7795193 33.8669549,30.2224223 32.2658579,30.8056562 L32.2762576,30.8005565 C28.5214067,32.3270485 19.245709,32.4327706 14.2272316,30.9087454 C11.7948664,30.3172893 10.0000044,28.268571 10.0000044,25.832143 C10.0000044,22.9327136 12.5418488,20.5823502 15.6774907,20.5823502 C15.924577,20.5823502 16.1679761,20.5969443 16.406735,20.6252513 C17.3780496,17.9377048 20.1228059,16 23.3569544,16 Z"
          id="Combined-Shape"
          fill="#727A87"
        ></path>
        <rect x="4.41824432e-06" y="0" width="35" height="35"></rect>
      </g>
    </g>
  </svg>
</template>
