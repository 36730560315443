<template>
  <div class="container">
    <div class="user-header">
      <p class="section-title uppercase">{{ $t("MembersOfTheTeam") }}</p>
      <SearchInput class="search-bar" @research="search = $event" />
    </div>
    <div class="users-lists">
      <div class="active-users" v-if="usersSort.length">
        <div class="user-container">
          <span class="column-label member">{{ $t("member") }}</span>
          <span class="column-label role">{{ $t("role") }}</span>
          <span class="column-label status">{{ $t("status") }}</span>
          <span class="column-label product">{{ $t("product") }}</span>
          <span class="column-label referent">{{ $t("referent") }}</span>
          <span class="user-delete"></span>
        </div>
        <div v-for="user in usersSort" :key="user.id" class="user-container">
          <div class="user-info member">
            <div class="profil">
              <TeamMember :userId="user.id" />
              <StarIcon v-if="user.roles.includes('Admin')" />
            </div>
            <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
          </div>
          <div class="user-info role">
            <Select
              class="light-shadow"
              :placeholder="$t('choseABusinessRole')"
              v-model="user.businessRole"
              :options="businessRoles"
              :hasSearch="false"
              @update:modelValue="changeBusinessRole($event, user)"
            />
          </div>
          <div class="user-info status">
            <Select
              class="light-shadow"
              :placeholder="$t('choseAJob')"
              v-model="user.roles[0]"
              :options="roles"
              :hasSearch="false"
              @update:modelValue="changeRole($event, user)"
            />
          </div>
          <div class="user-info product">{{ userMember(user) }}</div>
          <div class="user-info referent">{{ userReferrer(user) }}</div>
          <div class="user-delete" @click="deleteUser(user)"><TrashIcon /></div>
        </div>
      </div>
      <p
        :class="['section-title', 'uppercase', { section: usersSort.length }]"
        v-if="usersDeletedSort.length"
      >
        {{ $t("MembersDeletedOfTheTeam") }}
      </p>
      <div class="deleted-users">
        <div
          v-for="userDeleted in usersDeletedSort"
          :key="userDeleted.id"
          class="user-deleted-container"
        >
          <div class="user-info member-deleted">
            <div class="profil">
              <TeamMember :userId="userDeleted.id" :hoverDisabled="true" />
            </div>
            <span class="name"
              >{{ userDeleted.firstName }} {{ userDeleted.lastName }}</span
            >
          </div>
          <p
            class="user-deleted-role"
            :style="{
              color:
                this.businessRoles.find(
                  (r) => r.name === userDeleted.businessRole
                )?.color || '#000000',
            }"
          >
            {{ userDeleted.businessRole }}
          </p>
          <p class="deleted-date">
            {{ $t("deleteAt:") }}
            <span>{{
              new Date(userDeleted.deletedAt).toLocaleDateString()
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <p class="no-result" v-if="!usersSort.length && !usersDeletedSort.length">
      {{ $t("noUserMatches") }}
    </p>
  </div>
</template>

<script>
import TeamMember from "../TeamMember.vue";
import Select from "@/components/inputs/Select.vue";
import gsap from "gsap";
import SearchInput from "../inputs/SearchInput.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserList",
  components: { TeamMember, Select, SearchInput },

  data() {
    return {
      roles: ["Admin", "User"],
      search: "",
    };
  },

  mounted() {
    const scroller = document.querySelector(".users-lists");
    const container = document.querySelector(".container");
    scroller.addEventListener("scroll", () => {
      if (scroller.scrollTop > 0) {
        gsap.to(container, { "--gradient-height": "10%", duration: 0.2 });
      } else {
        gsap.to(container, { "--gradient-height": "0", duration: 0.2 });
      }
    });
  },

  computed: {
    ...mapGetters({
      users: "user/users",
      usersDeleted: "user/usersDeleted",
      projects: "project/projects",
      businessRoles: "user/businessRoles",
    }),
    usersSort() {
      let users = this.search
        ? [...this.users].filter(
            (user) =>
              this.searchFormat(user.firstName) ||
              this.searchFormat(user.lastName) ||
              this.searchFormat(user.businessRole) ||
              this.searchFormat(user.roles[0])
          )
        : [...this.users];

      return users.sort((a, b) => {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        else return 1;
      });
    },

    usersDeletedSort() {
      let users = this.search
        ? [...this.usersDeleted].filter(
            (user) =>
              this.searchFormat(user.firstName) ||
              this.searchFormat(user.lastName) ||
              this.searchFormat(user.businessRole)
          )
        : [...this.usersDeleted];

      return users.sort((a, b) => {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        else return 1;
      });
    },
  },

  methods: {
    async deleteUser(user) {
      const response = await this.$store.dispatch("modals/setModal", {
        modalType: "ConfirmationModal",
        modalData: {
          header: { ...user, headerType: "user" },
          dangerousAction: true,
          title: this.$t("deleteUserModal.title"),
          text: this.$t("deleteUserModal.text"),
          button: this.$t("deleteUserModal.button"),
        },
      });

      if (response) {
        this.$store.dispatch("user/deleteUser", user.id);
      }
    },
    async changeBusinessRole(newValue, user) {
      const userData = { ...user, businessRole: newValue.name };
      await this.$store.dispatch("user/updateBusinessRole", userData);
    },
    async changeRole(newValue, user) {
      const userData = { ...user, roles: [newValue] };
      await this.$store.dispatch("user/updateRole", userData);
    },
    userReferrer(user) {
      return this.projects.filter((project) => project.referrer.id === user.id)
        .length;
    },
    searchFormat(value) {
      return value
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase()
        .includes(
          this.search
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toUpperCase()
        );
    },
    userMember(user) {
      return this.projects.filter((project) =>
        project.members.some((e) => e.id === user.id)
      ).length;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 30px;

  --gradient-height: 0px;

  &::before {
    content: "";
    position: absolute;
    height: var(--gradient-height);
    top: 104px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .users-lists {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include hideScrollbar;

    .active-users {
      display: flex;
      flex-direction: column;
      gap: 14px;

      .user-container {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-template-rows: 1fr;
        font-weight: bold;
        color: $secondary-color;
        position: relative;

        &:not(:first-of-type) {
          background-color: mix($secondary-color, $white-color, 10%);
          border-radius: 30px;
          height: 64px;

          .user-info:not(:last-child) {
            background-color: #f4f6fa;
          }

          .member {
            border-radius: 30px 0 0 30px;
          }

          .referent {
            border-radius: 0 30px 30px 0;
          }
        }

        .column-label {
          font-size: 12px;
          color: $grey-color;
        }
        .member {
          grid-area: 1 / 1 / 2 / 4;
          padding-left: 14px;
        }

        .role {
          grid-area: 1 / 4 / 2 / 7;
          display: flex;
          align-items: center;
        }

        .status {
          grid-area: 1 / 7 / 2 / 9;
          display: flex;
          align-items: center;
        }

        .product {
          grid-area: 1 / 9 / 2 / 10;
          display: flex;
          align-items: center;
        }

        .referent {
          grid-area: 1 / 10 / 2 / 11;
          display: flex;
          align-items: center;
        }

        .user-delete {
          grid-area: 1 / 11 / 2 / 12;
          align-self: center;
          color: $red-color;
          width: 40px;
          cursor: pointer;
          justify-self: center;
        }
      }
    }
    .deleted-users {
      display: flex;
      flex-direction: column;
      gap: 14px;

      .user-deleted-container {
        background-color: #f4f6fa;
        padding: 0 14px;
        border-radius: 30px;
        height: 64px;
        opacity: 0.5;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-weight: bold;
        color: $secondary-color;
        grid-template-rows: 1fr;

        .member-deleted {
          grid-area: 1 / 1 / 2 / 2;
          align-self: center;
        }

        .user-deleted-role {
          grid-area: 1 / 2 / 2 / 3;
          align-self: center;
        }

        .deleted-date {
          grid-area: 1 / 3 / 2 / 4;
          align-self: center;
          justify-self: center;

          span {
            font-weight: initial;
          }
        }
      }
    }
  }

  .user-info {
    padding-right: 20px;
    display: flex;
    position: relative;
    align-items: center;
  }

  .profil {
    position: relative;

    svg {
      z-index: 5;
      position: absolute;
      top: 55%;
      left: 75%;
    }
  }

  .name {
    margin-left: 14px;
  }

  .section {
    margin-top: 32px;
  }

  .no-result {
    text-align: center;
    color: $secondary-color;
    line-height: 18px;
    margin-top: 24px;
  }
}
</style>
