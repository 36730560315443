<template>
  <div class="weather" ref="weather">
    <div class="weather-picker" v-if="edition">
      <div
        class="icon-button"
        ref="weatherIcon"
        @click="weatherSelected = weather"
        :class="{ selected: weatherSelected === weather }"
        v-for="weather in 5"
        :key="weather"
      >
        <WeatherIcon
          class="icon"
          :color="project.color"
          :weatherId="weather"
          :edition="edition"
        />
      </div>
    </div>
    <WeatherIcon
      class="icon"
      :color="project.color"
      :weatherId="project.weather"
      v-else
    />

    <div
      class="graph"
      :style="{ background: editionFromEmail ? `${project.color}1a` : 'none' }"
    >
      <div
        class="progression"
        v-for="(progression, name) in progressions"
        :key="name"
      >
        <span>{{ $t(name) }}</span>
        <div class="chart" :style="{ background: `${project.color}33` }">
          <div
            class="progress"
            :style="{
              width: `${progression}%`,
            }"
          ></div>
        </div>
        <div class="input-container" v-if="edition">
          <input
            type="number"
            max="100"
            min="0"
            v-model="progressions[name]"
            @blur="saveProjectWeather"
          />
          <div class="percent">{{ progression }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeatherIcon from "@/components/WeatherIcon";
import { mapGetters } from "vuex";

export default {
  name: "ProjectWeather",
  components: {
    WeatherIcon,
  },
  props: {
    project: {
      type: Object,
    },
    edition: {
      type: Boolean,
    },
    editionFromEmail: {
      type: Boolean,
    },
  },
  data() {
    return {
      weatherSelected: 1,
      progressions: null,
    };
  },
  computed: {
    ...mapGetters({ user: "user/user", loggedInTV: "auth/loggedInTV" }),
  },
  watch: {
    progressions: {
      deep: true,
      handler(value) {
        for (const key in value) {
          if (value[key] < 0) {
            value[key] = 0;
          } else if (value[key] > 100) {
            value[key] = 100;
          }
        }
      },
    },
    weatherSelected(weatherSelected) {
      if (weatherSelected !== this.project.weather) {
        this.saveProjectWeather();
      }
    },
  },
  created() {
    this.progressions = { ...this.project.progression };
    this.weatherSelected = this.project.weather;
  },
  mounted() {
    this.$el.style.setProperty("--project-color", this.project.color);
  },
  methods: {
    async saveProjectWeather() {
      if (this.loggedInTV) return;
      const project = {
        ...this.project,
        progression: this.progressions,
        weather: this.weatherSelected,
      };

      await this.$store.dispatch("project/updateProjectWeather", project);
    },
  },
};
</script>

<style scoped lang="scss">
.weather {
  display: flex;
  align-items: center;
  justify-content: center;

  &.mail {
    .icon-button {
      border-color: $light-grey-color;

      &:hover,
      &.selected {
        border-color: var(--project-color);
      }
    }

    .graph {
      padding: 36px 44px;
      border-radius: 14px;
      margin-left: 96px;
    }
  }
}

.weather-picker {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  width: 50%;
  flex-shrink: 0;
}

.icon {
  margin-right: 50px;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 14px;
  border: 2px solid $white-color;
  background: $white-color;
  transition: 0.3s;
  cursor: pointer;

  .icon {
    margin-right: 0;
  }

  &:hover,
  &.selected {
    border-color: var(--project-color);
  }
}

.graph {
  .progression {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    width: 120px;
    font-size: 16px;
  }

  .chart {
    width: 100px;
    height: 10px;
    border-radius: 3px;

    .progress {
      height: 10px;
      transition: 0.3s;
      border-radius: 3px;
      background: var(--project-color);
    }
  }

  .input-container {
    position: relative;
    width: 55px;
    height: 30px;
    margin-left: 8px;
    border-radius: 10px;
    background: $white-color;
    border: 1px solid $light-grey-color;
    overflow: hidden;
    transition: border 0.3s;
    color: var(--project-color);

    &:focus-within {
      border-color: $primary-color;

      .percent {
        opacity: 0;
      }
    }
    input {
      border: none;
      background: none;
      height: 100%;
      width: 100%;
      text-align: center;
      padding: 10px 0;
      font-weight: $font-bold;
      font-family: inherit;

      &:focus {
        outline: none;
      }
    }

    .percent {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: $font-bold;
      background: $white-color;
      flex-shrink: 0;
      opacity: 1;
      pointer-events: none;
    }
  }
}

@media (max-width: $layout-breakpoint-small) {
  .weather {
    flex-direction: column;
    width: 100%;

    &.mail {
      padding: 0 12px;

      .graph {
        margin: 0;
        padding: 24px;
      }
    }

    .weather-picker {
      width: 100%;
      gap: 12px;
      justify-content: space-between;
      margin-bottom: 24px;

      .icon-button {
        width: 54px;
        height: 54px;
      }
    }

    .graph {
      width: 100%;
    }
  }
}
</style>
