<template>
  <div class="container">
    <p class="section-title uppercase">{{ $t("weeklyJoke") }}</p>
    <div class="blue-frame" :class="{ 'tv-mode': loggedInTV }">
      <template v-if="riddle">
        <span>{{ riddle.riddle }} </span>
        <span
          ><b>{{ riddle.answer }}</b></span
        >
      </template>
      <div class="loader" v-else>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
import calendar from "@/helpers/date.js";

export default {
  name: "DailyJoke",
  components: { Loader },
  created() {
    const today = new Date();
    this.$store.dispatch(
      "dailyJoke/getRiddle",
      calendar.getWeekNumber({
        year: today.getFullYear(),
        month: today.getMonth(),
        day: today.getDay(),
      })
    );
  },
  data() {
    return {
      calendar,
    };
  },
  computed: {
    ...mapGetters({
      riddle: "dailyJoke/riddle",
      loggedInTV: "auth/loggedInTV",
    }),
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  gap: 40px;
  align-items: flex-start;
  color: $primary-color;
  font-family: $text-font;
  font-size: 14px;
  line-height: 18px;

  > p {
    flex-shrink: 0;
  }

  .blue-frame {
    padding: 20px 30px;
    width: 100%;

    .loader {
      height: 50px;
      width: 50px;
      margin: auto;
    }

    > span:nth-child(2) {
      margin-left: 4px;
    }
  }
}
</style>
