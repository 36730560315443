<template>
  <svg viewBox="0 0 35 35">
    <title>Thunderstorm</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Thunderstorm">
        <path
          d="M14.9459129,17.0144915 L25.328155,18.7066324 C25.8713148,18.7952589 26.1658938,19.1847159 25.9018214,19.5392216 C25.9018214,19.5392216 22.2908451,22.793934 18.9692045,25.0395533 C18.8974664,25.0879858 18.9518062,25.1806066 19.0585792,25.1978326 L22.9853461,25.8379402 C24.0440193,26.0104497 24.5507142,26.9206807 23.8662232,27.4204839 L13.8867631,34.8396404 C13.2864035,35.2777795 12.1381173,34.7417768 12.4071948,34.1491031 L15.0510176,28.3222267 C15.0753275,28.2690508 15.0202727,28.2068875 14.9368563,28.1934063 L10.4466713,27.4614268 C9.67375887,27.3356022 8.92253471,26.7604041 9.00642777,26.1951921 C9.37179155,23.7363701 11.7784735,19.3539799 13.9701795,17.2356832 C14.1827722,17.0621751 14.6158224,16.9605667 14.9459129,17.0144915"
          id="Fill-1"
          fill="#FFE45C"
        ></path>
        <path
          d="M18.9117867,0.0163834636 C24.0185969,0.297406128 28.0292674,4.13356718 28.4706643,8.86846305 C31.2015637,9.49484076 33.1572852,11.9209892 32.9901904,14.7002109 C32.8242331,17.460293 30.6200475,19.6447576 27.8574419,19.9914138 L27.8411862,19.9934547 C20.5943722,21.3247017 13.4245871,21.3429739 7.6969829,20.0032965 L7.40510591,19.9322934 C4.15099386,19.2166664 1.81347461,16.3407886 2.01172303,13.0433766 C2.21858411,9.60271241 5.11302,6.92096541 8.63107674,6.75863957 C10.0160292,2.63128468 14.1792078,-0.24425514 18.9117867,0.0163834636 Z"
          id="Combined-Shape"
          fill="#B8BFC9"
        ></path>
        <rect id="Rectangle" x="0" y="0" width="35" height="35"></rect>
      </g>
    </g>
  </svg>
</template>
