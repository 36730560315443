<template>
  <svg viewBox="0 0 44 44">
    <title>icon_trash</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-338.000000, -211.000000)">
        <g transform="translate(50.000000, 155.000000)">
          <g transform="translate(24.000000, 56.000000)">
            <g transform="translate(264.000000, 0.000000)">
              <g
                transform="translate(13.000000, 12.000000)"
                fill="currentColor"
                fill-rule="nonzero"
              >
                <path
                  d="M17.2372881,3.17766797 L12.6228814,3.17766797 L12.6228814,2.29710938 C12.6228814,1.03048327 11.5964237,0 10.3347458,0 L7.66525424,0 C6.40357627,0 5.37711864,1.03048327 5.37711864,2.29710938 L5.37711864,3.17766797 L0.762711864,3.17766797 C0.341466102,3.17766797 0,3.52047326 0,3.94337109 C0,4.36626893 0.341466102,4.70907422 0.762711864,4.70907422 L1.55257627,4.70907422 L2.48209322,16.8066859 C2.48236017,16.8102081 2.48266525,16.8137686 2.48297034,16.8173292 C2.55222458,17.5801226 2.90070763,18.2846461 3.46419915,18.8011128 C4.02769068,19.3175796 4.7582161,19.602 5.52119492,19.602 L12.4788051,19.602 C14.0678008,19.602 15.3739449,18.413935 15.5170297,16.8384626 C15.5552797,16.4172876 15.2462288,16.044773 14.8267373,16.006373 C14.4068263,15.967973 14.0361483,16.2782359 13.9978983,16.6993726 C13.926928,17.4811172 13.2738559,18.0705938 12.4788051,18.0705938 L5.52119492,18.0705938 C4.72885169,18.0705938 4.07680932,17.4744556 4.00252119,16.6830631 L3.08253814,4.70907422 L14.9174619,4.70907422 L14.2660297,13.1877815 C14.2336525,13.6094159 14.5478517,13.977566 14.967839,14.0101084 C14.987822,14.0116398 15.0076144,14.0124055 15.0273305,14.0124055 C15.4219195,14.0124055 15.7561017,13.7071196 15.7869534,13.3055466 L16.4474237,4.70907422 L17.2372881,4.70907422 C17.6585339,4.70907422 18,4.36626893 18,3.94337109 C18,3.52047326 17.6585339,3.17766797 17.2372881,3.17766797 Z M11.0974576,3.17766797 L6.90254237,3.17766797 L6.90254237,2.29710938 C6.90254237,1.87490067 7.24469492,1.53140625 7.66525424,1.53140625 L10.3347458,1.53140625 C10.7553051,1.53140625 11.0974576,1.87490067 11.0974576,2.29710938 L11.0974576,3.17766797 Z"
                  id="Shape"
                ></path>
                <path
                  d="M5.53450424,6.24120789 C5.11367797,6.25966133 4.78742797,6.61709155 4.80580932,7.03960654 L5.18716525,15.8043422 C5.20505085,16.2154482 5.54266525,16.5366607 5.94858051,16.5366607 C5.95979237,16.5366607 5.97111864,16.536431 5.98244492,16.535895 C6.40327119,16.5174415 6.72952119,16.1600113 6.71113983,15.7374963 L6.3297839,6.97272237 C6.31140254,6.55028395 5.9546822,6.22221845 5.53450424,6.24120789 Z"
                  id="Path"
                ></path>
                <path
                  d="M11.6702161,6.97276065 L11.2888602,15.7375346 C11.2704788,16.1600113 11.5967288,16.5174798 12.0175551,16.5359333 C12.0288814,16.536431 12.0401695,16.536699 12.0514195,16.536699 C12.4572966,16.536699 12.7949492,16.2154865 12.8128347,15.8043805 L13.1941907,7.03964482 C13.212572,6.61716812 12.886322,6.25969962 12.4654958,6.24124617 C12.0453559,6.22237159 11.6885975,6.55028395 11.6702161,6.97276065 Z"
                  id="Path"
                ></path>
                <path
                  d="M8.23728814,7.00618359 L8.23728814,15.7709193 C8.23728814,16.1938171 8.57875424,16.5366224 9,16.5366224 C9.42124576,16.5366224 9.76271186,16.1938171 9.76271186,15.7709193 L9.76271186,7.00618359 C9.76271186,6.58328576 9.42124576,6.24048047 9,6.24048047 C8.57875424,6.24048047 8.23728814,6.58328576 8.23728814,7.00618359 Z"
                  id="Path"
                ></path>
              </g>
              <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
