<template>
  <svg viewBox="0 0 44 44">
    <title>icon_zoom_in</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="BUTTON/Zoom/Plus" transform="translate(-10.000000, -10.000000)">
        <g id="icon_zoom_in" transform="translate(10.000000, 10.000000)">
          <g id="Group-8" transform="translate(7.000000, 7.000000)">
            <path
              d="M8.28264072,13.0992555 L16.412753,13.0992555 C16.9737308,13.0992555 17.4290171,12.6439692 17.4290171,12.0829914 C17.4290171,11.5220137 16.9737308,11.0667274 16.412753,11.0667274 L8.28264072,11.0667274 C7.72166297,11.0667274 7.26637668,11.5220137 7.26637668,12.0829914 C7.26637668,12.6439692 7.72166297,13.0992555 8.28264072,13.0992555"
              id="Fill-1"
              fill="CurrentColor"
            ></path>
            <path
              d="M13.3639609,16.1480476 L13.3639609,8.01793525 C13.3639609,7.4569575 12.9086746,7.00167121 12.3476969,7.00167121 C11.7867191,7.00167121 11.3314328,7.4569575 11.3314328,8.01793525 L11.3314328,16.1480476 C11.3314328,16.7090253 11.7867191,17.1643116 12.3476969,17.1643116 C12.9086746,17.1643116 13.3639609,16.7090253 13.3639609,16.1480476"
              id="Fill-3"
              fill="CurrentColor"
            ></path>
            <path
              d="M3.51026479,21.2567053 C-1.28548522,16.4609553 -1.15235463,8.80543826 3.81819279,3.83489084 C8.80093538,-1.14785175 16.4686476,-1.29216124 21.2643976,3.5025725 C24.2034332,6.4416081 25.3822995,10.6438599 24.4087185,14.7383877 C24.3121734,15.1367632 24.0154243,15.4457075 23.6282277,15.5544477 C23.2298522,15.663188 22.8243629,15.5605453 22.5337114,15.2698938 C22.2684664,15.0036326 22.1648075,14.6235499 22.2440761,14.254646 C22.9574935,10.9162187 21.9839125,7.51173414 19.6251637,5.15400156 C15.7135634,1.24138501 9.27959574,1.31353976 5.28161301,5.31050623 C1.31513446,9.27800104 1.27245137,15.6814808 5.18506792,19.5940973 C7.55601193,21.964025 10.9483013,22.9498012 14.2674196,22.2424814 C14.6363235,22.1642291 15.0174225,22.2790669 15.2836837,22.5443118 C15.5682376,22.8288657 15.6708803,23.2343551 15.5682376,23.626633 C15.4533998,24.0199272 15.1505531,24.3105787 14.74608,24.4010262 C10.6515522,25.3746072 6.45539797,24.2018385 3.51026479,21.2567053"
              id="Fill-5"
              fill="CurrentColor"
            ></path>
            <path
              d="M3.51026479,21.2567053 C-1.28548522,16.4609553 -1.15235463,8.80543826 3.81819279,3.83489084 C8.80093538,-1.14785175 16.4686476,-1.29216124 21.2643976,3.5025725 C24.2034332,6.4416081 25.3822995,10.6438599 24.4087185,14.7383877 C24.3121734,15.1367632 24.0154243,15.4457075 23.6282277,15.5544477 C23.2298522,15.663188 22.8243629,15.5605453 22.5337114,15.2698938 C22.2684664,15.0036326 22.1648075,14.6235499 22.2440761,14.254646 C22.9574935,10.9162187 21.9839125,7.51173414 19.6251637,5.15400156 C15.7135634,1.24138501 9.27959574,1.31353976 5.28161301,5.31050623 C1.31513446,9.27800104 1.27245137,15.6814808 5.18506792,19.5940973 C7.55601193,21.964025 10.9483013,22.9498012 14.2674196,22.2424814 C14.6363235,22.1642291 15.0174225,22.2790669 15.2836837,22.5443118 C15.5682376,22.8288657 15.6708803,23.2343551 15.5682376,23.626633 C15.4533998,24.0199272 15.1505531,24.3105787 14.74608,24.4010262 C10.6515522,25.3746072 6.45539797,24.2018385 3.51026479,21.2567053 Z"
              id="Stroke-7"
              stroke="CurrentColor"
              stroke-width="0.5"
            ></path>
            <path
              d="M27.506088,29.5770622 L18.9410147,21.0119889 C18.3678418,20.438816 18.3678418,19.5058856 18.9399985,18.9327127 C19.5050413,18.3686861 20.4379716,18.3686861 21.0111446,18.9408428 L29.5762179,27.5069324 C30.1412607,28.0709589 30.1412607,29.0038893 29.5762179,29.5689321 C29.0040612,30.142105 28.0711309,30.142105 27.506088,29.5770622"
              id="Fill-9"
              fill="CurrentColor"
            ></path>
            <path
              d="M27.506088,29.5770622 L18.9410147,21.0119889 C18.3678418,20.438816 18.3678418,19.5058856 18.9399985,18.9327127 C19.5050413,18.3686861 20.4379716,18.3686861 21.0111446,18.9408428 L29.5762179,27.5069324 C30.1412607,28.0709589 30.1412607,29.0038893 29.5762179,29.5689321 C29.0040612,30.142105 28.0711309,30.142105 27.506088,29.5770622 Z"
              id="Stroke-11"
              stroke="CurrentColor"
              stroke-width="0.25"
            ></path>
          </g>
          <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
