export default {
  setModal({ state }, data) {
    return new Promise((resolve, reject) => {
      const onResolve = (value) => {
        state.modalType = null;
        state.modalResolve = null;
        state.modalReject = null;
        state.modalData = null;
        return resolve(value);
      };
      const onReject = (value) => {
        state.modalType = null;
        state.modalResolve = null;
        state.modalReject = null;
        state.modalData = null;
        return reject(value);
      };
      state.modalType = data.modalType;
      state.modalResolve = onResolve;
      state.modalReject = onReject;
      state.modalData = data.modalData;
    });
  },
};
