<template>
  <svg
    viewBox="0 0 44 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon_activity</title>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="CTA/Nav" transform="translate(-30.000000, -5.000000)">
        <g id="icon_activity" transform="translate(30.000000, 5.000000)">
          <g
            id="Group"
            transform="translate(9.000000, 10.000000)"
            fill="CurrentColor"
            fill-rule="nonzero"
          >
            <g id="ICONS/Activity">
              <path
                d="M1.17757468,0 C1.91189007,0 2.47056143,0.506550815 2.47056143,1.16047833 L2.47056143,23.5439466 L24.7353762,23.5439466 C25.2962904,23.5439466 26,24.0217773 26,24.7518946 C26,25.4820119 25.2962904,26 24.7353762,26 L1.015625,26 C0.454710801,26 0,25.5452892 0,24.984375 L0,1.16047833 C0,0.59956413 0.443259301,0 1.17757468,0 Z M24.03375,5.04193077 C24.340163,5.04193077 24.6334245,5.16643397 24.84625,5.386875 C25.3002917,5.87127947 25.3002917,6.62497053 24.84625,7.109375 L19.97125,12.20375 C19.7600092,12.4280352 19.4668227,12.5570373 19.15875,12.56125 L13.715,12.56125 L7.093125,19.5 C6.8782691,19.7297565 6.57891697,19.8618236 6.264375,19.865625 C5.95630231,19.8614123 5.66311579,19.7324102 5.451875,19.508125 C4.99783333,19.0237205 4.99783333,18.2700295 5.451875,17.785625 L12.406875,10.473125 C12.6200244,10.2526043 12.9126943,10.1267562 13.219375,10.12375 L18.6875,10.12375 L23.22125,5.386875 C23.4340755,5.16643397 23.727337,5.04193077 24.03375,5.04193077 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
          <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
