<template>
  <div class="user-container">
    <div class="user-info">
      <div class="profil">
        <TeamMember :userId="user.id" :hoverDisabled="true" />
        <StarIcon v-if="user.roles.includes('Admin')" />
      </div>
      <span class="name"> {{ user.firstName }} {{ user.lastName }} </span>
    </div>
    <span :style="roleColor">
      {{ user.businessRole || `-` }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamMember from "../TeamMember.vue";

export default {
  name: "UserCard",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    TeamMember,
  },
  computed: {
    ...mapGetters({
      businessRoles: "user/businessRoles",
    }),

    roleColor() {
      return {
        color: `${
          this.businessRoles.find(
            (item) => item.name === this.user.businessRole
          )?.color || "#c1c6cA"
        }`,
        fontWeight: "bold",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.user-container {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 12px 30px 12px 14px;
  border-radius: 30px;
  background-color: #f4f6fa;

  .user-info {
    display: flex;
    position: relative;
    align-items: center;
    gap: 16px;

    .profil {
      position: relative;

      svg {
        position: absolute;
        top: 55%;
        left: 75%;
      }
    }

    .name {
      font-weight: bold;
      color: $secondary-color;
    }
  }
}
</style>
