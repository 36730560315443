import { utils } from "pixi.js";
import { Component } from "../component";
import { Geometry, Shader } from "@pixi/core";
import { Mesh } from "@pixi/mesh";
import roundedRectangleVertShader from "./../shaders/roundedRectangle.vert.glsl";
import roundedRectangleFragShader from "./../shaders/roundedRectangle.frag.glsl";

export class RoundedRectangle extends Component {
  constructor(props) {
    super(props);
    const {
      width,
      height,
      x = 0,
      y,
      uRadius = [1, 1, 1, 1],
      uColor = 0xffffff,
      uAlpha = 1.0,
      uOpacity = 1.0,
      borderWidth = 4.0,
      tDiffuse = null,
    } = props;

    this.x = x;
    this.width = width;
    this.uOpacity = uOpacity;

    this.uniforms = {
      uResolution: [width, height],
      uRadius,
      uColor: utils.hex2rgb(uColor),
      uAlpha,
      uBorderWidth: (borderWidth * 2.0) / height,
      uOpacity,
      tDiffuse,
    };
    this.shader = Shader.from(
      roundedRectangleVertShader,
      roundedRectangleFragShader,
      this.uniforms
    );

    this.geometry = new Geometry()
      .addAttribute(
        "aVertexPosition",
        [x, y, x + width, y, x + width, y + height, x, y + height],
        2
      )
      .addAttribute("aUvs", [0, 1, 1, 1, 1, 0, 0, 0], 2)
      .addIndex([0, 1, 2, 0, 2, 3]);
    this.mesh = new Mesh(this.geometry, this.shader);
    this.mesh.zIndex = 0;
  }

  polish(radius) {
    this.uniforms.uRadius = radius;
  }

  refresh(width = this.width, x = this.x) {
    this.geometry.buffers[0].data[0] = x;
    this.geometry.buffers[0].data[2] = x + width;
    this.geometry.buffers[0].data[4] = x + width;
    this.geometry.buffers[0].data[6] = x;
    this.geometry.buffers[0].update();
    this.uniforms.uResolution[0] = width;
    this.width = width;
    this.x = x;
  }
}
