import * as PIXI from "pixi.js";
import { BatchRenderer } from "@pixi/core";
import { Application } from "@pixi/app";
import { Ticker } from "@pixi/ticker";
import { InteractionManager } from "@pixi/interaction";
import { BitmapFontLoader } from "@pixi/text-bitmap";
import { Calendar } from "./calendar";
import Device from "@/helpers/device";
import gsap from "gsap";

PIXI.extensions.add(BatchRenderer);
PIXI.extensions.add(InteractionManager);
PIXI.Loader.registerPlugin(BitmapFontLoader);

export default class Pixi {
  constructor({ $el, projects, tvMode }) {
    const { width, height, pixelRatio } = Device;
    this.$el = $el;

    this.app = new Application({
      width: width,
      height: height,
      backgroundColor: tvMode ? 0xe6efff : 0xf1f7ff,
      resolution: pixelRatio,
      autoDensity: true,
      antialias: false,
    });
    this.scene = this.app.stage;
    this.renderer = this.app.renderer;
    this.$el.appendChild(this.app.view);
    this.ticker = Ticker.shared;
    this.ticker.autoStart = false;
    this.ticker.stop();
    this.app.ticker.autoStart = false;
    this.app.ticker.stop();

    this.update = this.render.bind(this);
    this.resize = this.resize.bind(this);

    this.projects = projects;

    this.loader = new PIXI.Loader();

    this.loader
      .add("Montserrat", "/assets/fonts/MontserratRegular.fnt")
      .add("MontserratSemiBold", "/assets/fonts/MontserratSemiBold.fnt")
      .add("MontserratBold", "/assets/fonts/MontserratBold.fnt")
      .load(this.init.bind(this));
  }

  init() {
    const { scene } = this;
    this.calendar = new Calendar({
      projects: this.projects,
    });
    scene.addChild(this.calendar.container);
    // const text = new BitmapText('bitmap fonts are supported!\nWoo yay!', {
    //   fontName: "Montserrat-Regular", fontSize: 22, align: 'left', tint: 0x000000
    // });
    // this.app.stage.addChild(text);
    this.setupRender();
  }

  drawDays() {}

  setupRender() {
    // const { ticker } = this;
    // ticker.add(this.update);
    gsap.ticker.add(this.update);
    window.addEventListener("resize", this.resize);
  }

  render() {
    const { scene, renderer } = this;
    this.calendar.update();
    renderer.render(scene);
  }

  resize() {
    const { renderer } = this;
    const { pixelRatio } = Device;
    const { innerHeight, innerWidth } = window;
    const w = innerWidth * pixelRatio;
    const h = innerHeight * pixelRatio;
    Device.width = innerWidth;
    Device.height = innerHeight;

    renderer.view.style.width = `${w}px`;
    renderer.view.style.height = `${h}px`;
    renderer.resize(w, h);

    this.calendar.resize();
  }

  dispose() {
    gsap.ticker.remove(this.update);
    window.removeEventListener("resize", this.resize);
    const { app } = this;
    app.destroy();
    if (this.calendar) this.calendar.dispose();
  }
}
