<template>
  <div class="user-menu" :class="{ qrcode: qrCodeUrl && !tvUser.id }">
    <div class="code">
      <img :src="qrCodeUrl" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserMenu",
  computed: {
    ...mapGetters({
      qrCodeUrl: "auth/qrCodeUrl",
      tvUser: "auth/tvUser",
    }),
  },
};
</script>

<style scoped lang="scss">
.user-menu {
  position: relative;
  background-color: $white-color;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 68px;
  width: 68px;

  &.qrcode {
    width: 100px;
    height: 100px;
  }

  .code {
    object-fit: contain;
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      width: 100%;
      image-rendering: pixelated;
    }
  }
}
</style>
