<template>
  <svg
    viewBox="0 0 44 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon_cross</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1200.000000, -287.000000)">
        <g id="Project-2-Open" transform="translate(-29.000000, 264.000000)">
          <g id="modal" transform="translate(687.000000, 0.000000)">
            <g id="icon_cross" transform="translate(542.000000, 23.000000)">
              <line
                x1="25.8811183"
                y1="18.1189165"
                x2="31"
                y2="13"
                id="Stroke-1"
                stroke="CurrentColor"
                stroke-width="3"
                stroke-linecap="round"
              ></line>
              <line
                x1="13"
                y1="31"
                x2="18.1805532"
                y2="25.8194376"
                id="Stroke-3"
                stroke="CurrentColor"
                stroke-width="3"
                stroke-linecap="round"
              ></line>
              <line
                x1="13.1069752"
                y1="13.321237"
                x2="30.8930686"
                y2="30.6788063"
                id="Stroke-5"
                stroke="CurrentColor"
                stroke-width="3"
                stroke-linecap="round"
              ></line>
              <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
