import { Component } from "../component";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Texture } from "@pixi/core";
import { ProjectSprint } from "./projectSprint";
import { PropalSprint } from "./propalSprint";
import { ProjectHeader } from "./projectHeader";
import { RoundedRectangle } from "./RoundedRectangle";
import projectTypes from "@/enums/projectTypes";

import store from "./../../store/index";
import Data from "./data";

export class Project extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.project = this.props.project;
    this.positionY = this.props.positionY;
    this.projectIndex = this.props.index;
    this.projectColor = this.project.color?.slice(1, this.project.color.length);
    this.projectPeriods = this.project.periods.filter((period) =>
      projectTypes[1].periods.find((p) => p.name === period.type)
    );
    this.propalPeriods = this.project.periods.filter((period) =>
      projectTypes[0].periods.find((p) => p.name === period.type)
    );
    if (this.project.periods.length) {
      this.startDate = new Date(this.project.periods[0].startDate).getTime();
      this.endDate = new Date(
        this.project.periods[this.project.periods.length - 1].endDate
      ).getTime();
    } else {
      this.startDate = new Date().getTime();
      this.endDate = new Date().getTime();
    }

    this.container = new Container();
    this.container.y = this.positionY;
    this.container.sortableChildren = true;

    if (this.project.type === 1) {
      this.container.height = 42;
      this.createPropal();
      this.createPropalSprints();
    } else {
      this.container.height = 110;
      if (this.propalPeriods.length) {
        this.createPropal();
        this.createPropalSprints();
      }
      this.createProjectBackground();
      this.createProjectSprints();
      this.createProjectProgress();
      this.createProjectHeader();
    }
  }

  createPropal() {
    const { container } = this;

    this.propalSprite = new Sprite(Texture.WHITE);
    this.propalSprite.y = 31;
    if (this.project.type === 2) {
      this.propalSprite.y = 64;
    }
    this.propalSprite.height = 6;
    this.propalSprite.tint = `0x06326c`;
    this.propalSprite.alpha = 0.18;

    this.propalSprite.interactive = true;
    this.propalSprite.buttonMode = true;
    this.onProject = this.onProject.bind(this);
    this.propalSprite.on("mousedown", this.onProject);

    container.addChild(this.propalSprite);
  }

  createProjectBackground() {
    const { container } = this;

    this.onProject = this.onProject.bind(this);

    this.quad = new RoundedRectangle({
      x: 0,
      y: 42,
      width: 1,
      height: 48,
      uRadius: [0.5, 0.5, 0.5, 0.5],
    });
    this.quad.mesh.on("mousedown", this.onProject);
    this.quad.mesh.interactive = true;
    this.quad.mesh.buttonMode = true;
    container.addChild(this.quad.mesh);
  }

  refreshProjectPosition() {
    const { dayWidth, dayInMs, firstDate } = Data;
    this.leftPosition =
      ((this.startDate - firstDate) / dayInMs) * dayWidth + dayWidth;
    this.container.x = this.leftPosition;

    if (this.project.type === 1) {
      const propalDurationInDays = (this.endDate - this.startDate) / dayInMs;
      this.propalSprite.width = propalDurationInDays * dayWidth + dayWidth;
    } else if (this.project.type === 2 && this.projectPeriods.length) {
      const projectLeftPosition =
        (new Date(this.projectPeriods[0].startDate).getTime() -
          this.startDate) /
        dayInMs;
      const duration =
        (new Date(
          this.projectPeriods[this.projectPeriods.length - 1].endDate
        ).getTime() -
          new Date(this.projectPeriods[0].startDate).getTime()) /
        dayInMs;
      const width = duration * dayWidth + dayWidth;
      const x = projectLeftPosition * dayWidth;

      this.quad.refresh(width, x);

      if (this.propalPeriods.length) {
        const propalDurationInDays =
          (new Date(
            this.propalPeriods[this.propalPeriods.length - 1].endDate
          ).getTime() -
            new Date(this.propalPeriods[0].startDate).getTime()) /
          dayInMs;
        this.propalSprite.width = propalDurationInDays * dayWidth + dayWidth;
        this.projectHeader.container.x = x + 12;
      }
    }
  }

  createProjectProgress() {
    const { container } = this;

    this.progressQuad = new RoundedRectangle({
      x: 0,
      y: 42,
      width: 1,
      height: 48,
      uRadius: [0.5, 0.5, 0.5, 0.5],
      uAlpha: 0.5,
      uColor: `0x${this.projectColor}`,
    });
    container.addChild(this.progressQuad.mesh);
  }

  refreshProjectProgressPosition() {
    if (this.projectPeriods.length) {
      const { dayWidth, dayInMs } = Data;
      const progress =
        (new Date().getTime() -
          new Date(this.projectPeriods[0].startDate).getTime()) /
        dayInMs;

      let progressWidth = Math.max(48, progress * dayWidth);
      if (progress < 0) {
        progressWidth = 0;
      } else if (new Date().getTime() > this.endDate) {
        progressWidth = this.quad.uniforms.uResolution[0];
      }
      const progressLeftPosition =
        (new Date(this.projectPeriods[0].startDate).getTime() -
          this.startDate) /
        dayInMs;
      const x = progressLeftPosition * dayWidth;

      this.progressQuad.refresh(progressWidth, x);
    }
  }

  createProjectHeader() {
    const { container } = this;

    this.projectHeader = new ProjectHeader({
      project: this.project,
      projectColor: this.projectColor,
    });
    this.projectHeader.container.zIndex = 2;

    container.addChild(this.projectHeader.container);
    this.addComponent(this.projectHeader);
  }

  createProjectSprints() {
    const { container } = this;

    this.projectPeriods.forEach((period, index) => {
      const projectSprint = new ProjectSprint({
        sprint: period,
        project: this.project,
        sprintIndex: index,
        projectStart: this.startDate,
        projectColor: this.projectColor,
      });
      const { length } = this.project.periods;
      projectSprint.container.zIndex = (length - index) / length;

      this.addComponent(projectSprint);
      container.addChild(projectSprint.container);
    });
  }

  createPropalSprints() {
    const { container } = this;

    this.propalPeriods.forEach((period, index) => {
      const propalSprint = new PropalSprint({
        sprint: period,
        propal: this.project,
        sprintIndex: index,
        projectStart: this.startDate,
      });
      propalSprint.container.zIndex = 2;
      if (this.project.type === 2) {
        propalSprint.container.y = 34;
      }
      this.addComponent(propalSprint);
      container.addChild(propalSprint.container);
    });
  }

  onProject() {
    store.dispatch("modals/setModal", {
      modalType: "ProjectPreview",
      modalData: {
        projectId: this.project.id,
      },
    });
  }

  updateProgress() {
    this.refreshOpacity();
  }

  refreshOpacity() {
    const projectPositionY = window.pixiCalendar.proxy.y + this.container.y;
    const progress = 1 - 160 / projectPositionY;
    let newAlpha = 1;
    if (progress <= 0 || projectPositionY <= 0) {
      newAlpha = 0;
    } else if (projectPositionY < 210) {
      newAlpha = Number(progress.toFixed(2));
    }

    this.container.alpha = newAlpha;
    if (this.project.type === 2) {
      this.quad.shader.uniformGroup.uniforms.uOpacity = newAlpha;
      this.progressQuad.shader.uniformGroup.uniforms.uOpacity = newAlpha;
      if (this.projectHeader.projectWeather.projectWeather) {
        this.projectHeader.projectWeather.projectWeather.uniforms.uOpacity =
          newAlpha;
      }
    }
    if (this.projectHeader) {
      let headerOffset = 0;
      if (this.quad.width < this.projectHeader.width) {
        headerOffset = this.quad.x + 12;
      } else {
        const projectPositionX =
          window.pixiCalendar.proxy.x + (this.container.x + this.quad.x - 120);
        headerOffset = Math.max(
          Math.min(0, projectPositionX),
          -(this.quad.width - this.projectHeader.width)
        );
        headerOffset = this.quad.x - headerOffset + 12;
      }
      this.projectHeader.container.x = headerOffset;
    }

    this.components.forEach((component) => {
      component.container.children.forEach((child) => {
        if (child.shader) {
          child.shader.uniformGroup.uniforms.uOpacity = newAlpha;
        }
        child.children.forEach((children) => {
          if (children.shader) {
            children.shader.uniformGroup.uniforms.uOpacity = newAlpha;
          }
        });
      });
    });
  }
  refresh() {
    super.refresh();
    this.refreshProjectPosition();
    if (this.project.type === 2) {
      this.refreshProjectProgressPosition();
    }
  }
}
