import getters from "./getters";
import actions from "./actions";

export let state = {
  riddle: null,
};

const namespaced = true;

export default {
  state,
  getters,
  actions,
  namespaced,
};
