import { cloneDeep } from "lodash";

export default {
  setProject({ state }, project) {
    state.currentProject =
      JSON.parse(JSON.stringify(project)) ||
      JSON.parse(JSON.stringify(state.defaultProject));
  },
  resetProject({ state }) {
    state.currentProject = JSON.parse(JSON.stringify(state.defaultProject));
  },
  async createProject({ state }) {
    const projectClone = cloneDeep(state.currentProject);

    projectClone.links = projectClone.links.filter(
      (link) => link.name && link.url
    );

    try {
      const { project } = await this.$axios.$post("/Projects", projectClone);
      state.currentProject = {
        ...state.currentProject,
        links: projectClone.links,
        id: project.id,
      };
      state.projects.push(project);
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateProject({ state }) {
    const projectClone = cloneDeep(state.currentProject);

    projectClone.links = projectClone.links.filter(
      (link) => link.name && link.url
    );

    projectClone.periods = projectClone.periods.map((period) => ({
      ...period,
      name: period.name || period.type,
      events: period.events
        .filter((event) => event.type)
        .map((event) => ({ ...event, name: event.name || event.type })),
    }));

    try {
      const { project } = await this.$axios.$patch(
        `/Projects/${state.currentProject.id}`,
        projectClone
      );
      const index = state.projects.findIndex((p) => p.id === project.id);
      state.projects.splice(index, 1, project);
      if (window.pixiCalendar) {
        window.pixiCalendar.updateProjects(state.projects);
      }
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateProjectWeather({ state, dispatch }, updatedProject) {
    try {
      const { project } = await this.$axios.$patch(
        `/Projects/${updatedProject.id}`,
        updatedProject
      );
      const index = state.projects.findIndex((p) => p.id === project.id);
      state.projects.splice(index, 1, project);
      dispatch("getGlobalWeather");
      if (window.pixiCalendar) {
        window.pixiCalendar.updateProjects(state.projects);
      }
    } catch (err) {
      throw new Error(err);
    }
  },
  async deleteProject({ state }, projectId) {
    await this.$axios.$delete(`/Projects/${projectId}`);
    state.projects = state.projects.filter(
      (project) => project.id !== projectId
    );
  },
  async getClients({ state }) {
    const { clients } = await this.$axios.$get(`/Clients`);
    state.clients = clients;
  },
  async getProjects({ state }) {
    const { projects } = await this.$axios.$get("/Projects");
    state.projects = projects;
  },
  async getProject(_, { projectId }) {
    const { project } = await this.$axios.$get(`/Projects/${projectId}`);
    return project;
  },
  async searchProjects(_, search) {
    const result = await this.$axios.$get(`/Projects?search=${search}`);
    return result.projects;
  },
  async getGlobalWeather({ state }) {
    const { globalWeather } = await this.$axios.$get("/Projects/weather");
    state.globalWeather = globalWeather;
  },
  filterProjectByMember({ state }, value) {
    state.filterByMember = value;
  },
  resetProjects({ state }) {
    state.projects = [];
  },
};
