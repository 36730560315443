<template>
  <div class="timeline view">
    <div class="pixi-calendar" ref="calendar" />
    <div class="left-panel" v-if="!TVMode">
      <Team />
      <SearchProject />
    </div>
    <div class="controls" v-if="!TVMode">
      <button class="zoom secondary-button" @click="focusToday()">
        <CenterIcon />
      </button>
      <button
        class="zoom zoom-out secondary-button"
        :class="{ disabled: zoomLevel <= 1 }"
        @click="zoomOut()"
      >
        <ZoomOutIcon />
      </button>
      <button
        class="zoom zoom-in secondary-button"
        :class="{ disabled: zoomLevel >= 3 }"
        @click="zoomIn()"
      >
        <ZoomInIcon />
      </button>
    </div>
    <router-link
      class="primary-button"
      :to="{ name: 'Creation' }"
      v-if="!TVMode"
    >
      {{ $t("create") }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getTimeBeforeProjectEnd } from "@/helpers/date.js";

import Calendar from "@/pixi/index.js";
import Team from "@/components/Team.vue";
import SearchProject from "@/components/project/SearchProject.vue";

export default {
  name: "TimelineComponent",
  components: {
    Team,
    SearchProject,
  },
  data() {
    return {
      zoomLevel: 2,
    };
  },
  computed: {
    ...mapGetters({
      projects: "project/projects",
    }),
    TVMode() {
      return this.$route.meta.tv;
    },
    onGoingProjects() {
      return this.projects
        .filter(
          (project) =>
            project.periods.length && getTimeBeforeProjectEnd(project) >= 0
        )
        .sort(
          (project1, project2) =>
            getTimeBeforeProjectEnd(project2) -
              getTimeBeforeProjectEnd(project1) && project2.type - project1.type
        );
    },
    pastProjects() {
      return this.projects
        .filter(
          (project) =>
            project.periods.length && getTimeBeforeProjectEnd(project) < 0
        )
        .sort(
          (project1, project2) =>
            getTimeBeforeProjectEnd(project2) -
              getTimeBeforeProjectEnd(project1) && project2.type - project1.type
        );
    },
    allProjects() {
      return this.onGoingProjects.concat(this.pastProjects);
    },
  },
  async mounted() {
    this.initCalendar();
  },
  methods: {
    initCalendar() {
      this.pixi = new Calendar({
        $el: this.$refs.calendar,
        projects: this.allProjects,
      });
    },
    zoomIn() {
      this.zoomLevel += 1;
      this.pixi.calendar.zoomIn(this.zoomLevel);
    },
    zoomOut() {
      this.zoomLevel -= 1;
      this.pixi.calendar.zoomOut(this.zoomLevel);
    },
    focusToday() {
      this.pixi.calendar.centerCaldendar();
    },
  },
  beforeUnmount() {
    if (this.pixi) this.pixi.dispose();
  },
};
</script>

<style scoped lang="scss">
.view.timeline {
  padding: 0;
  overflow: hidden;

  .pixi-calendar {
    width: 100vw;
    height: 100vh;

    &:deep(canvas) {
      height: 100%;
      height: 100%;
    }
  }
}

.primary-button {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.left-panel {
  position: fixed;
  z-index: 1;
  left: 50px;
  top: 140px;
}

.controls {
  position: fixed;
  right: 50px;
  bottom: 40px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  z-index: 1;
}
</style>
