import { createStore } from "vuex";
import modals from "./modals";
import project from "./project";
import user from "./user";
import auth from "./auth";
import notes from "./notes";
import financials from "./financials";
import dailyJoke from "./dailyJoke";

export default createStore({
  state: {},
  getters: {},
  actions: {},
  modules: {
    modals,
    project,
    user,
    auth,
    notes,
    financials,
    dailyJoke,
  },
});
