<template>
  <div class="graph"></div>
</template>

<script>
import * as d3 from "d3";

const colors = ["#B8255F", "#884EFF", "#0068FF", "#EB96EB"].reverse();

export default {
  name: "GraphComponent",
  props: {
    data: {
      type: Array,
    },
    idx: {
      type: Number,
    },
  },
  data() {
    return {
      currentIdx: 3 - this.idx,
    };
  },
  watch: {
    idx(newVal) {
      this.animateCurves(3 - newVal);
    },
  },
  mounted() {
    this.width = this.$el.offsetWidth;
    this.height = this.$el.offsetHeight;
    this.marginBottom = 30;
    this.marginLeft = 40;
    this.startDate = new Date(new Date().getFullYear(), 5, 1);
    const { data, startDate } = this;
    let endDate = new Date(startDate).setMonth(startDate.getMonth() + 12);

    this.values = d3.map(data, (_) => {
      const currentAbsYear = new Date(_.year, 0, 1);
      const currentYear = new Date(
        new Date(currentAbsYear).setMonth(currentAbsYear.getMonth() - 6)
      );
      return _.months
        .filter((month, i) => {
          const current = new Date(
            new Date(currentYear).setMonth(startDate.getMonth() + i + 2)
          );
          const today = new Date();
          return current < today;
        })
        .map((month) => month[0]);
    });
    const { width, height, marginLeft, marginBottom } = this;
    const { values } = this;

    this.svg = d3
      .select(this.$el)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g");

    this.x = d3
      .scaleTime()
      .domain([startDate, endDate])
      .range([marginLeft, width]);

    const computedValues = [].concat(...values);
    this.y = d3
      .scaleLinear()
      .domain([
        d3.min(computedValues.filter((_) => _ !== 0)),
        d3.max(computedValues) + 25000,
      ])
      .range([height - marginBottom, 0]);
    this.svg.append("g").attr("class", "grid");
    this.setContext();
    this.setCurves();
    this.setTooltip();

    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleMouseenter(e) {
      const circle = e.target;
      const x =
        e.target.getBoundingClientRect().x + 144 < window.innerWidth
          ? parseFloat(circle.getAttribute("cx")) + 18
          : parseFloat(circle.getAttribute("cx")) - 162;
      const y = parseFloat(circle.getAttribute("cy"));
      this.svg
        .select(`circle[data-index="${circle.dataset.index}"]`)
        .transition()
        .duration(200)
        .attr("opacity", 1);

      this.tooltip
        .attr("transform", `translate(${x} ${y - 43})`)
        .transition()
        .duration(200)
        .attr("opacity", 1);
      this.tooltip.raise();
      this.tooltip.select(".title").text(circle.dataset.month);
      const data = this.getThreeLastYearByMonth(
        parseInt(circle.dataset.monthIndex)
      );
      this.tooltip
        .selectAll("text.years")
        .data(data)
        .join("text")
        .attr("class", "years")
        .attr("x", 25)
        .attr("y", (d, i) => i * 20 + 36)
        .attr("fill", "#ffffff")
        .attr("font-size", "12")
        .attr("font-weight", "bold")
        .text((d) => `${d.year} : ${this.numberFormat(d.value, "€")}`);

      this.tooltip
        .selectAll("circle")
        .data(data)
        .join("circle")
        .style("cursor", "pointer")
        .attr("r", 5.5)
        .attr("fill", ({ color }) => color)
        .attr("cx", 19 - 5.5)
        .attr("cy", (d, i) => i * 20 + 36 - 4);
    },
    handleMouseout(e) {
      const circle = e.target;
      this.svg
        .select(`circle[data-index="${circle.dataset.index}"]`)
        .transition()
        .duration(200)
        .attr("opacity", 0);

      this.tooltip.attr("opacity", 0.0);
    },
    setContext() {
      const { x, y, height, marginLeft, marginBottom } = this;
      const xTicks = x.ticks();
      const yTicks = y.ticks();
      const dotsY = yTicks.reduce((acc, cur, idx) => {
        const next = yTicks[idx + 1];
        acc.push(cur);
        if (next) {
          acc.push((next + cur) * 0.5);
        }
        return acc;
      }, []);
      const dots = [].concat(
        ...xTicks.map((date, index) => {
          const dates = [];
          if (index !== xTicks.length - 1) {
            for (let i = 1; i <= 4; i++) {
              dates.push(new Date(new Date(date).setDate(6.5 * i)));
            }
          } else {
            dates.push(date);
          }
          return dates;
        })
      );
      this.svg
        .select("g.grid")
        .call((g) =>
          g
            .selectAll("text.y-axis")
            .data(yTicks)
            .join("text")
            .attr("class", "y-axis")
            .attr("x", marginLeft)
            .attr("y", (d) => y(d))
            .attr("fill", "#06326C")
            .attr("font-size", "12")
            .attr("font-weight", "bold")
            .attr("opacity", "0.3")
            .attr("text-anchor", "end")
            .attr("alignment-baseline", "middle")
            .text((d) => this.numberFormat(d, "€"))
        )
        .call((g) =>
          g
            .selectAll("text.x-axis")
            .data(xTicks.slice(1, xTicks.length))
            .join("text")
            .attr("class", "x-axis")
            .attr("x", (d) => x(d))
            .attr("y", height - marginBottom * 0.5)
            .attr("fill", "#06326C")
            .attr("font-size", "12")
            .attr("font-weight", "500")
            .attr("opacity", "0.3")
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .text((d) => d.toLocaleString("fr-FR", { month: "short" }))
        )
        .call((g) =>
          g
            .selectAll("line.x-line")
            .data(xTicks.slice(1, xTicks.length - 1))
            .join("line")
            .attr("class", "x-line")
            .attr("stroke-width", 1)
            .attr("stroke", "#06326C")
            .attr("stroke-opacity", 0.15)
            .attr("x1", (d) => x(d))
            .attr("x2", (d) => x(d))
            .attr("y1", 0)
            .attr("y2", height - marginBottom)
        );
      dotsY.forEach((_, i) => {
        this.svg.select("g.grid").call((g) =>
          g
            .selectAll(`circle.circle-${i}`)
            .data(dots)
            .join("circle")
            .attr("class", `circle-${i}`)
            .attr("r", 1)
            .attr("fill", "rgba(0,104,255,0.2)")
            .attr("cx", (d) => x(d))
            .attr("cy", () => y(_))
        );
      });
    },
    getThreeLastYearByMonth(month) {
      const arr = this.values.reduce((acc, cur, idx) => {
        if (cur[month]) {
          acc.push({
            year: this.data[idx].year,
            idx,
            color: colors[idx],
            value: cur[month],
          });
        }
        return acc;
      }, []);
      return arr.slice(Math.max(arr.length - 3, 0)).reverse();
    },
    setCurves() {
      const { data, x, y, values, startDate } = this;
      const YEARs = d3.map(data, (_) => _.year);
      this.svg
        .selectAll("path")
        .data(values)
        .join("path")
        .attr("class", `curve-year`)
        .attr("fill", "none")
        .attr("stroke", (d, i) => colors[i])
        .attr("stroke-opacity", 0.75)
        .attr("stroke-width", (d, i) => (this.currentIdx === i ? 8 : 4))
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveCatmullRom.alpha(0.5))
            .x(function (d, i) {
              return x(
                new Date(startDate).setMonth(startDate.getMonth() + i + 1)
              );
            })
            .y(function (d) {
              return y(d);
            })
        );

      YEARs.forEach((year, index) => {
        const isCurrentYear = this.currentIdx === index;

        this.svg
          .selectAll(`circle.marker-${index}`)
          .data(values[index])
          .join("circle")
          .attr("class", `marker-hover marker-${index}`)
          .attr("r", isCurrentYear ? 12 : 8.5)
          .attr("fill", "rgba(0,104,255,0.2)")
          .attr("opacity", 0)
          .attr("data-idx", () => {
            return `${index}`;
          })
          .attr("data-index", function (d, i) {
            return `${index}-${i}`;
          })
          .attr("cx", function (d, i) {
            return x(
              new Date(startDate).setMonth(startDate.getMonth() + i + 1)
            );
          })
          .attr("cy", (d) => y(d))
          .call((selection) => {
            if (this.currentIdx === index) {
              selection.raise();
            }
          });

        this.svg
          .selectAll(`circle.marker-main-${index}`)
          .data(values[index])
          .join("circle")
          .attr("class", `marker-main marker-main-${index}`)
          .style("cursor", "pointer")
          .attr("r", isCurrentYear ? 7 : 5)
          .attr("fill", colors[index])
          .attr("data-idx", () => {
            return `${index}`;
          })
          .attr("data-index", function (d, i) {
            return `${index}-${i}`;
          })
          .attr("data-month-index", function (d, i) {
            return `${i}`;
          })
          .attr("data-month", function (d, i) {
            const date = new Date(
              new Date(startDate).setMonth(startDate.getMonth() + i + 1)
            );
            const month = date.toLocaleString("fr-FR", { month: "long" });
            return month.charAt(0).toUpperCase() + month.slice(1);
          })
          .attr("cx", function (d, i) {
            return x(
              new Date(startDate).setMonth(startDate.getMonth() + i + 1)
            );
          })
          .attr("cy", (d) => y(d))
          .call((selection) => {
            if (this.currentIdx === index) {
              selection.raise();
            }
          })
          .on("mouseenter", this.handleMouseenter)
          .on("mouseout", this.handleMouseout);
      });
    },
    animateCurves(newVal) {
      this.svg
        .selectAll("path.curve-year")
        .transition()
        .ease(d3.easeLinear)
        .attr("stroke-width", (d, i) => (newVal === i ? 8 : 4));
      this.svg
        .selectAll("circle.marker-main")
        .call((selection) => {
          selection
            .filter((d, i, e) => {
              return Number(e[i].dataset.idx) === newVal;
            })
            .raise();
        })
        .transition()
        .ease(d3.easeLinear)
        .duration(628)
        .attr("r", (d, i, e) => {
          const el = e[i];
          const idx = Number(el.dataset.index.split("-")[0]);
          return newVal === idx ? 7 : 5;
        });
      this.svg
        .selectAll("circle.marker-hover")
        .transition()
        .ease(d3.easeLinear)
        .duration(628)
        .attr("r", (d, i, e) => {
          const el = e[i];
          const idx = Number(el.dataset.index.split("-")[0]);
          return newVal === idx ? 12 : 8.5;
        });
    },
    setTooltip() {
      this.tooltip = this.svg
        .append("g")
        .style("pointer-events", "none")
        .attr("opacity", 0.0);

      this.tooltip
        .append("rect")
        .attr("fill", "#262C2F")
        .attr("x", 0)
        .attr("width", 144)
        .attr("height", 87)
        .attr("opacity", 0.8)
        .attr("rx", 8);

      this.tooltip
        .append("text")
        .attr("class", "title")
        .attr("x", "8")
        .attr("y", "17")
        .attr("fill", "#ffffff")
        .attr("font-size", "14")
        .attr("font-weight", "700");
    },
    numberFormat(value, currency) {
      let abs = Math.abs(Number(value));
      abs =
        (isNaN(abs)
          ? "0 "
          : abs >= 1.0e9
          ? this.roundNumber(value, 1.0e9) + " B"
          : abs >= 1.0e6
          ? this.roundNumber(value, 1.0e6) + " M"
          : abs >= 1.0e3
          ? this.roundNumber(value, 1.0e3) + " K"
          : this.roundNumber(abs, 1) + " ") + (currency ? currency : "");
      return abs;
    },
    roundNumber(value, factor) {
      return this.numberWithSpaces(
        Math.round((Number(value) / factor) * 10) / 10
      );
    },
    numberWithSpaces(value) {
      var parts = value.toString().split(".");
      parts[0] = parts[0].replace(
        /\B(?=(\d{3})+(?!\d))/g,
        this.thousandSeparator
      );
      return parts.join(this.decimalSeparator);
    },
    handleResize() {
      const { marginLeft, marginBottom } = this;
      this.width = this.$el.offsetWidth;
      this.height = this.$el.offsetHeight;
      this.x.range([marginLeft, this.width]);
      this.y.range([this.height - marginBottom, 0]);

      this.setContext();
      this.setCurves();
    },
  },
};
</script>

<style lang="scss" scoped>
.graph {
  width: 100%;
  position: relative;
  flex: 1;
  &::v-deep(svg) {
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
