<template>
  <div class="global-weather" v-if="globalWeather" id="global-weather">
    <WeatherIcon :weatherId="globalWeather" />
    <p>{{ $t(`weather._${globalWeather}`) }}</p>
  </div>
</template>

<script>
import WeatherIcon from "@/components/WeatherIcon";
import { mapGetters } from "vuex";

export default {
  name: "GlobalWeather",
  components: {
    WeatherIcon,
  },
  computed: {
    ...mapGetters({
      globalWeather: "project/globalWeather",
    }),
  },
};
</script>

<style scoped lang="scss">
.global-weather {
  position: relative;
  z-index: 1;
  background-color: $white-color;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 68px;
  width: 440px;
  transition: 0.3s;
  overflow: hidden;

  &.reduced {
    opacity: 0;
  }
}

:deep(.weather-icon) {
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  svg {
    width: 32px;
  }
}

p {
  margin-left: 20px;
  font-size: 16px;
  height: 18px;
  overflow: hidden;
}
</style>
