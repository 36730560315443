<template>
  <div class="pixi-calendar" ref="calendar" />
</template>

<script>
import { mapGetters } from "vuex";
import { getTimeBeforeProjectEnd } from "@/helpers/date.js";

import QRCodeStyling from "qr-code-styling";
import Calendar from "@/pixi/index.js";

import qrOption from "@/assets/qrCodeOptions.json";

export default {
  name: "TimelineTV",
  data() {
    return {
      roomId: null,
      zoomLevel: 2,
    };
  },
  computed: {
    ...mapGetters({
      accessToken: "auth/accessToken",
      projects: "project/projects",
      modalResolve: "modals/modalResolve",
      modalType: "modals/modalType",
      loggedInTV: "auth/loggedInTV",
    }),
    onGoingProjects() {
      return this.projects
        .filter(
          (project) =>
            project.periods.length && getTimeBeforeProjectEnd(project) >= 0
        )
        .sort(
          (project1, project2) =>
            getTimeBeforeProjectEnd(project2) -
              getTimeBeforeProjectEnd(project1) && project2.type - project1.type
        );
    },
    pastProjects() {
      return this.projects
        .filter(
          (project) =>
            project.periods.length && getTimeBeforeProjectEnd(project) < 0
        )
        .sort(
          (project1, project2) =>
            getTimeBeforeProjectEnd(project2) -
              getTimeBeforeProjectEnd(project1) && project2.type - project1.type
        );
    },
    allProjects() {
      return this.onGoingProjects.concat(this.pastProjects);
    },
  },
  async created() {
    if (!this.loggedInTV) {
      const img = await this.generateQrcode();
      const qrCodeUrl = URL.createObjectURL(img);
      await this.$store.dispatch("auth/loginAsTv", qrCodeUrl);
    }
  },
  async mounted() {
    await this.$socket.start({
      log: false,
    });
    if (this.roomId) {
      this.$socket.invoke("joinRoom", this.roomId);
    }
    const tvAccessToken = localStorage.getItem("tvAccessToken");
    if (tvAccessToken) {
      this.$axios.setHeader("Authorization", `Bearer ${tvAccessToken}`);
    }

    this.initCalendar();

    document.addEventListener("keydown", (code) => {
      if (code.ctrlKey && code.key === "i") {
        this.leaveRoom();
      }
    });
  },
  methods: {
    async initCalendar() {
      this.pixi = new Calendar({
        $el: this.$refs.calendar,
        projects: this.allProjects,
        tvMode: true,
      });
    },
    async fetchProjects() {
      await this.$store.dispatch("project/getProjects");
      await this.$store.dispatch("user/getUsers");
      await this.$store.dispatch("project/getClients");
      await this.$store.dispatch("project/getGlobalWeather");
      window.pixiCalendar.updateProjects(this.allProjects);
      this.recenter();
    },
    generateQrcode() {
      this.roomId = `TV${Math.round(Math.random() * 100)}`;
      console.log("roomToken", this.roomId);
      const url = `${window.location.origin}${
        this.$router.resolve({ name: "RemoteTimeLineControls" }).href
      }?roomToken=${encodeURIComponent(this.roomId)}`;
      const qrCode = new QRCodeStyling(Object.assign(qrOption, { data: url }));
      return qrCode.getRawData("png");
    },
    zoomIn() {
      this.zoomLevel += 1;
      this.pixi.calendar.zoomIn(this.zoomLevel);
    },
    zoomOut() {
      this.zoomLevel -= 1;
      this.pixi.calendar.zoomOut(this.zoomLevel);
    },
    async openProject(project) {
      await this.closeModal();
      this.$store.dispatch("modals/setModal", {
        modalType: "ProjectPreview",
        modalData: {
          projectId: project.id,
        },
      });
    },
    openRemoteTimeline() {
      this.$router.push({
        name: "TV",
      });
    },
    openRemoteStats() {
      this.$router.push({
        name: "Activities",
      });
    },
    closeModal() {
      if (this.modalType) {
        this.modalResolve(false);
      }
    },
    growPicture(noteId) {
      document.querySelector(`[data-id="${noteId}"]`).click();
    },
    shrinkImage(noteId) {
      document.querySelector(`[viewer-id="${noteId}"]`).click();
    },
    scroll(x, y) {
      this.pixi.calendar.scroll(x, y);
    },
    recenter() {
      this.pixi.calendar.centerCaldendar();
    },
    async leaveRoom() {
      this.$store.dispatch("auth/setTVUser", { user: {} });
      const img = await this.generateQrcode();
      const qrCodeUrl = URL.createObjectURL(img);
      this.$socket.invoke("joinRoom", this.roomId);
      await this.$store.dispatch("auth/loginAsTv", qrCodeUrl);
    },
    setUser(user) {
      this.$store.dispatch("auth/setTVUser", { user });
    },
  },
  sockets: {
    async receiveInstruction(data) {
      if (!data.includes("method")) {
        localStorage.setItem("tvAccessToken", data);
        this.$axios.setHeader("Authorization", `Bearer ${data}`);
        this.fetchProjects();
      }
      if (data.includes("method")) {
        const arg = JSON.parse(data);
        switch (arg.method) {
          case "setUser":
            this.setUser(arg.user);
            break;
          case "openProject":
            if (arg.project) {
              this.openProject(arg.project);
            } else {
              this.closeModal();
            }
            break;
          case "openRemoteTimeline":
            this.openRemoteTimeline();
            break;
          case "openRemoteStats":
            this.openRemoteStats();
            break;
          case "zoomIn":
            this.closeModal();
            this.zoomIn();
            break;
          case "zoomOut":
            this.closeModal();
            this.zoomOut();
            break;
          case "scrollLeft":
            this.scroll(100, 0);
            break;
          case "scrollRight":
            this.scroll(-100, 0);
            break;
          case "scrollTop":
            this.scroll(0, 100);
            break;
          case "scrollBottom":
            this.scroll(0, -100);
            break;
          case "center":
            this.recenter();
            break;
          case "growPicture":
            this.growPicture(arg.noteId);
            break;
          case "shrinkImage":
            this.shrinkImage(arg.noteId);
            break;
          case "leaveRoom":
            this.leaveRoom();
            break;
        }
      }
    },
  },
};
</script>

<style></style>
