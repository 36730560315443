import { Component } from "../component";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { RoundedRectangle } from "./RoundedRectangle";

export class WeatherIcon extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.weather = this.props.weather;
    this.projectColor = this.props.projectColor;

    this.container = new Container();

    this.createWeatherCircle();
    this.createWeatherIcon();
  }

  createWeatherCircle() {
    const { container } = this;

    this.projectCircle = new RoundedRectangle({
      x: 6,
      y: 6,
      width: 38,
      height: 38,
      uRadius: [1, 1, 1, 1],
      uColor: `0x${this.projectColor}`,
      uOpacity: 0.05,
    });

    container.addChild(this.projectCircle.mesh);
  }

  createWeatherIcon() {
    const { container } = this;

    const weathers = [
      "/assets/weather/Sunny.svg",
      "/assets/weather/PartlyCloudy.svg",
      "/assets/weather/Cloudy.svg",
      "/assets/weather/Rainy.svg",
      "/assets/weather/Stormy.svg",
    ];
    this.iconSprite = Sprite.from(weathers[this.weather - 1]);
    this.iconSprite.width = 25;
    this.iconSprite.height = 25;
    this.iconSprite.anchor.set(-0.5, -0.5);

    container.addChild(this.iconSprite);
  }
}
