<template>
  <svg viewBox="0 0 16 16">
    <title>arrow</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M4.288,7.28143713 L11.296,0.28742515 C11.704,-0.0958083832 12.328,-0.0958083832 12.712,0.28742515 C13.096,0.670658683 13.096,1.29341317 12.712,1.7005988 L6.4,8 L12.712,14.2994012 C13.096,14.6826347 13.096,15.3293413 12.712,15.7125749 C12.328,16.0958084 11.704,16.0958084 11.296,15.7125749 L4.288,8.69461078 C3.904,8.31137725 3.904,7.68862275 4.288,7.28143713 Z"
        id="Path"
        fill="CurrentColor"
        transform="translate(8.500000, 8.000000) scale(-1, 1) translate(-8.500000, -8.000000)"
      ></path>
    </g>
  </svg>
</template>
