export default {
  loggedIn: ({ loggedIn }) => loggedIn,
  user: ({ user }) => ({ ...user }),
  tvUser: ({ tvUser }) => ({ ...tvUser }),
  role: ({ user }) => user?.roles,
  currentRole: ({ currentRole }) => currentRole,
  accessToken: ({ accessToken }) => accessToken,
  qrCodeUrl: ({ qrCodeUrl }) => qrCodeUrl,
  loggedInTV: ({ loggedInTV }) => loggedInTV,
};
