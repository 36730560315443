<template>
  <div
    class="note-container"
    ref="note"
    @mouseenter="showDeleteButton"
    @mouseleave="canBeDeleted = false"
  >
    <div class="eraser" :class="{ show: canBeDeleted }" @click="setDeleteModal">
      <AddIcon />
    </div>
    <div class="header">
      <span>{{ note.owner.firstName }}</span>
      <span>{{ new Date(note?.createdAt).toLocaleDateString() }}</span>
    </div>
    <div class="note blue-frame" :class="{ old: isOld, 'tv-mode': loggedInTV }">
      <div class="user">
        <div
          class="profile-pic"
          :style="roleColor"
          referrerpolicy="no-referrer"
        >
          <img
            v-if="note.owner.pictureUrl"
            :src="note.owner.pictureUrl"
            :alt="note.owner.lastName"
          />
        </div>
      </div>
      <div class="content-container">
        <div class="img-container" v-if="note.pictureUrl">
          <img
            class="note-image"
            :src="note.pictureUrl"
            alt=""
            :data-id="note.id"
            @click="growImage"
          />
        </div>
        <div class="content">
          <span>{{ note.content }}</span>
        </div>
      </div>
    </div>
    <div class="image-viewer" :viewer-id="note.id" @click="shrinkImage">
      <div class="target" :target-id="note.id" @click="shrinkImage"></div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Flip from "gsap/Flip";
import { mapGetters } from "vuex";

export default {
  name: "NoteComponent",
  props: {
    note: {
      type: Object,
      required: true,
    },
    isOld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canBeDeleted: false,
      noteId: this.note.id,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      businessRoles: "user/businessRoles",
      loggedInTV: "auth/loggedInTV",
    }),
    roleColor() {
      return {
        border: ` 2px solid ${
          this.businessRoles.find(
            (item) => item.name === this.note.owner.roles[0]
          )?.color || "#c1c6cA"
        }`,
      };
    },
    isAdmin() {
      return this.user.roles.includes("Admin");
    },
  },
  methods: {
    setDeleteModal() {
      this.$store.dispatch("modals/setModal", {
        modalType: "DeleteNote",
        modalData: {
          note: this.note,
        },
      });
    },
    showDeleteButton() {
      if (this.user.id == this.note.owner.id || this.isAdmin)
        this.canBeDeleted = true;
    },
    growImage(e) {
      const image = e.target;
      const viewer = document.querySelector(`[viewer-id="${this.note.id}"]`);
      const target = document.querySelector(`[target-id="${this.note.id}"]`);
      const naturalHeight = e.target.naturalHeight;
      const naturalWidth = e.target.naturalWidth;
      const shouldBeScaled =
        naturalHeight > window.innerHeight * 0.9 ||
        naturalWidth > window.innerWidth * 0.9;
      const tl = new gsap.timeline();
      tl.to(viewer, {
        height: "100vh",
        duration: 0,
      });
      tl.to(target, {
        height: shouldBeScaled ? `${naturalHeight * 0.6}` : `${naturalHeight}`,
        width: shouldBeScaled ? `${naturalWidth * 0.6}` : `${naturalWidth}`,
        duration: 0,
      });

      const bufferImage = document.createElement("img");
      bufferImage.setAttribute("src", this.note.pictureUrl);
      bufferImage.setAttribute("class", "buffer");
      bufferImage.style.position = "fixed";
      bufferImage.style.zIndex = 1;
      document.body.appendChild(bufferImage);
      const replaceImage = Flip.fit(bufferImage, image);
      const placeImage = Flip.fit(bufferImage, target, {
        duration: 0.2,
      });
      tl.add(replaceImage);
      tl.to(image, {
        opacity: 0,
        duration: 0,
      });
      tl.add(placeImage);
      bufferImage.addEventListener("click", this.shrinkImage);
    },
    shrinkImage(e) {
      e.preventDefault();
      const tl = new gsap.timeline();
      const viewer = document.querySelector(`[viewer-id="${this.note.id}"]`);
      const imgOrigin = document.querySelector(`[data-id="${this.noteId}"]`);
      tl.to(viewer, {
        height: 0,
        duration: 0,
      });

      const backAnim = Flip.fit(".buffer", imgOrigin, {
        duration: 0.2,
      });
      tl.add(backAnim);
      tl.to(imgOrigin, {
        opacity: 1,
        duration: 0,
        onComplete: () => {
          const bufferImage = document.querySelector(".buffer");
          bufferImage.remove();
        },
      });
    },
  },
};
</script>

<style lang="scss">
.note-container {
  position: relative;
  padding: 4px 3px 0 25px;
  width: 100%;

  .eraser {
    position: absolute;
    top: 13px;
    right: 9px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0068ff;
    border-radius: 50%;
    transition: all 200ms;
    pointer-events: none;
    cursor: pointer;
    opacity: 0;

    &.show {
      opacity: 0.55;
      right: 2px;
      pointer-events: auto;
      &:hover {
        opacity: 1;
        scale: 1.2;
      }
      svg {
        rotate: 45deg;
      }
    }

    svg {
      color: white;
      height: 7px;
      rotate: 0deg;
      transition: rotate 200ms;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 4px 16px;
    opacity: 0.6;
    color: $secondary-color;
    font-family: $text-font;
    font-size: var(--small-size);
    line-height: 13px;
  }

  .note {
    display: flex;
    gap: 22px;

    &.old {
      background-color: #eff1f3;
    }

    .user {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .profile-pic {
        width: 48px;
        height: 48px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          object-fit: contain;
          z-index: 90;
        }
      }

      > svg {
        margin-top: 20px;
        color: red;
        width: 30px;
        height: 30px;
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      gap: 18px;
      .img-container {
        width: 100%;

        img {
          width: 100%;
          object-fit: contain;
          cursor: pointer;
        }
      }
      .content {
        display: flex;
        align-items: center;
        span {
          word-break: break-word;
          font-size: var(--big-size);
          color: $secondary-color;
          font-weight: bold;
          line-height: 23px;
        }
      }
    }
  }

  .image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    opacity: 0.1;
    cursor: pointer;
  }
}
</style>
