<template>
  <svg width="15px" height="14px" viewBox="0 0 15 14">
    <title>icon_star</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-497.000000, -280.000000)"
        fill="#00EC7B"
        fill-rule="nonzero"
      >
        <g transform="translate(433.000000, 155.000000)">
          <path
            d="M78.0968996,130.016674 C78.0095751,129.761749 77.7839439,129.576295 77.5127071,129.536506 L73.5092707,128.963674 L71.7223291,125.408747 C71.6063626,125.159744 71.3528716,125 71.0737037,125 C70.7945358,125 70.5410448,125.159744 70.4250783,125.408747 L68.6295456,128.963674 L64.6261092,129.536506 C64.3548725,129.576295 64.1292412,129.761749 64.0419168,130.016674 C63.9451247,130.286385 64.0191658,130.58669 64.2309202,130.783258 L67.1347003,133.54633 L66.447415,137.455065 C66.3941736,137.720046 66.5053307,137.990883 66.7309202,138.145833 C66.9548581,138.304945 67.2518337,138.324576 67.495525,138.196377 L71.1037724,136.309401 L74.6776556,138.154257 C74.9213469,138.282456 75.2183225,138.262825 75.4422604,138.103713 C75.66785,137.948763 75.779007,137.677926 75.7257656,137.412945 L75.0556625,133.50421 L77.9078961,130.783258 C78.1196506,130.58669 78.1936916,130.286385 78.0968996,130.016674 Z"
            id="icon_star"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
