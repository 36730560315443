<template>
  <div class="gestion">
    <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="section-title tab"
        :class="{ active: tab.value === current }"
        @click="current = tab.value"
      >
        {{ tab.name }}
      </div>
    </div>
    <keep-alive>
      <component v-bind:is="current"></component>
    </keep-alive>
  </div>
</template>

<script>
import ProductsList from "../dashboard/ProductsList.vue";
import JobsList from "../dashboard/JobsList.vue";

export default {
  components: { ProductsList, JobsList },

  name: "ProductsJobsManage",

  data() {
    return {
      current: "ProductsList",
      tabs: [
        { name: this.$t("productsAndPropals"), value: "ProductsList" },
        { name: this.$t("jobs"), value: "JobsList" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.gestion {
  .tabs {
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    border-radius: 14px 14px 0 0;

    .tab {
      text-transform: uppercase;
      border-radius: 14px 14px 0 0;
      padding: 20px;
      cursor: pointer;
      text-align: center;

      &:nth-child(1) {
        width: 67%;
      }

      &:nth-child(2) {
        width: 33%;
      }

      &.active {
        background-color: white;
      }
    }
  }
}
</style>
