<template>
  <div class="details">
    <h3 class="section-title">CHIFFRE D’AFFAIRES</h3>
    <div
      :key="turnover.year"
      v-for="(turnover, i) in turnOvers"
      :class="[
        `card blue-frame card-${i}`,
        {
          selected: idx === i,
          'tv-mode': loggedInTV,
        },
      ]"
      @click="$emit('handleIdx', i)"
      :style="{
        '--color': turnover.color,
      }"
    >
      <span class="year"> {{ turnover.year }}</span>
      <div class="turnover">
        {{ turnover.value }}€
        <span
          :class="[
            'rate',
            {
              success: turnover.evolutionRate > 0,
              danger: turnover.evolutionRate < 0,
            },
          ]"
          >{{ turnover.evolutionRate > 0 ? "+" : ""
          }}{{ turnover.evolutionRate }}%</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const colors = ["#B8255F", "#884EFF", "#0068FF", "#EB96EB"].reverse();
export default {
  name: "TurnOverDetails",
  props: {
    data: {
      type: Array,
    },
    idx: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      loggedInTV: "auth/loggedInTV",
    }),
    turnOvers() {
      const data = [];
      for (let i = 1; i < 5; i++) {
        const { ca, year } = this.data[i];
        const { ca: previousCa } = this.data[i - 1];
        const value = Math.round(ca[0])
          .toString()
          .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");

        let currentCa = ca[0];
        let currentPreviousCa = previousCa[0];

        if (i === 4) {
          const startDate = new Date(new Date().getFullYear(), 5, 1);
          const currentAbsYear = new Date(this.data[i].year, 0, 1);
          const currentYear = new Date(
            new Date(currentAbsYear).setMonth(currentAbsYear.getMonth() - 6)
          );
          const currentYearCas = this.data[i].months
            .filter((month, i) => {
              const current = new Date(
                new Date(currentYear).setMonth(startDate.getMonth() + i + 2)
              );
              const today = new Date();
              return current < today;
            })
            .map((_) => _[0]);
          const previousYearCas = this.data[i - 1].months
            .slice(0, currentYearCas.length)
            .map((_) => _[0]);

          currentCa = currentYearCas.reduce((acc, cur) => acc + cur, 0);
          currentPreviousCa = previousYearCas.reduce(
            (acc, cur) => acc + cur,
            0
          );
        }

        let evolutionRate = Math.round(
          ((currentCa - currentPreviousCa) / currentPreviousCa) * 100
        );

        data.push({
          value,
          year,
          evolutionRate,
          color: colors[i - 1],
        });
      }
      return data.reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 24px;
  }
  .card {
    cursor: pointer;
    position: relative;
    & + .card {
      margin-top: 10px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      border: 4px solid rgba(0, 104, 255, 0.15);
      border-radius: 14px;
      opacity: 0;
      transition: opacity 0.2s linear;
    }
    &:hover {
      &:after {
        opacity: 0.5;
      }
    }
    &.selected {
      &:after {
        opacity: 1;
      }
    }
  }
  .year {
    color: var(--color);
    font-weight: 700;
    font-family: $title-font;
    font-size: var(--small-size);
    display: block;
    &:before {
      display: inline-block;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color);
      margin-right: 0.8em;
    }
  }
  .turnover {
    font-size: 12px;
    font-family: $title-font;
    font-weight: 700;
    color: #06326c;
    margin-top: 0.5em;
  }
  .rate {
    font-family: $text-font;
    font-weight: 700;
    font-size: var(--small-size);
    margin-left: 0.5em;
  }
  .success {
    color: #00ec7b;
  }
  .danger {
    color: #e02020;
  }
  .card-0 {
    .turnover {
      font-size: 20px;
      @media (max-width: $layout-breakpoint-tv) {
        font-size: 12px;
      }
    }
  }
  .card-1 {
    .turnover {
      font-size: 16px;
      @media (max-width: $layout-breakpoint-tv) {
        font-size: 12px;
      }
    }
  }
}
</style>
