<template>
  <div class="products">
    <SearchInput class="search" @research="search = $event" />
    <div class="products-list">
      <ProductCard
        v-for="(project, index) in projectsSort"
        :key="index"
        :project="project"
        :deletable="true"
      />
    </div>
    <p class="no-result" v-if="!projectsSort.length">
      {{ $t("noProductsMatches") }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductCard from "../dashboard/ProductCard.vue";
import gsap from "gsap";
import SearchInput from "../inputs/SearchInput.vue";

export default {
  components: { ProductCard, SearchInput },

  name: "ProductsList",

  data() {
    return {
      search: "",
    };
  },

  mounted() {
    const scroller = document.querySelector(".products-list");
    const container = document.querySelector(".products");
    scroller.addEventListener("scroll", () => {
      if (scroller.scrollTop > 0) {
        gsap.to(container, { "--gradient-height": "10%", duration: 0.2 });
      } else {
        gsap.to(container, { "--gradient-height": "0", duration: 0.2 });
      }
    });
  },

  computed: {
    ...mapGetters({
      projects: "project/projects",
    }),

    projectsSort() {
      let projects = this.search
        ? [...this.projects].filter(
            (project) =>
              this.searchFormat(project.name) ||
              this.searchFormat(project.clientName)
          )
        : [...this.projects];

      return projects.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else return 1;
      });
    },
  },

  methods: {
    searchFormat(value) {
      return value
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase()
        .includes(
          this.search
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toUpperCase()
        );
    },
  },
};
</script>

<style scoped lang="scss">
.products {
  padding: 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: calc(100% - 58px);

  --gradient-height: 0px;

  &::before {
    content: "";
    position: absolute;
    height: var(--gradient-height);
    top: 104px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .search {
    margin-bottom: 14px;
  }

  .products-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
    overflow: auto;
    @include hideScrollbar;
  }

  .no-result {
    text-align: center;
    color: $secondary-color;
    line-height: 18px;
  }
}
</style>
