<template>
  <div class="remote-projects" v-clickout="() => (selectedProjectId = null)">
    <div class="title">{{ title }}</div>
    <div class="projects">
      <div
        class="project"
        v-for="project in projects"
        :key="project.id"
        :class="{ selected: selectedProjectId === project.id }"
        :style="{
          color:
            selectedProjectId === project.id
              ? '#fff'
              : project.type === 1
              ? 'inherit'
              : project.color,
          borderColor: project.type === 1 ? project.color : `transparent`,
          backgroundColor:
            selectedProjectId === project.id
              ? project.color
              : `${project.color}1a`,
        }"
        @click="openProject(project)"
      >
        <span>
          <b>{{ project.name }}</b
          >, {{ project.clientName }}
        </span>

        <div class="eye">
          <EyeIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoteProjects",
  props: {
    title: {
      type: String,
    },
    projects: {
      type: Array,
    },
  },
  data() {
    return {
      selectedProjectId: null,
    };
  },
  methods: {
    openProject(project) {
      this.selectedProjectId =
        this.selectedProjectId === project.id ? null : project.id;
      const argument = {
        method: "openProject",
        project: this.selectedProjectId ? project : null,
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
  },
};
</script>

<style scoped lang="scss">
.remote-projects {
  margin: 20px 0;
}

.project {
  height: 56px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  background-color: rgba($secondary-color, 0.1);

  span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .eye {
    display: none;
    align-items: center;
    justify-content: center;
    background: $white-color;
    width: 32px;
    height: 32px;
    border-radius: 8px;

    svg {
      color: $secondary-color;
      width: 16px;
    }
  }

  &.selected {
    .eye {
      display: flex;
    }
  }
}

.title {
  background: $light-grey-color;
  padding: 4px 30px;
  font-size: 14px;
  margin-bottom: 20px;
}

.projects {
  padding: 0 30px;
}
</style>
