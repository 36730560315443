<template>
  <div class="user-profile" :style="{ backgroundImage: `url(${pictureUrl})` }">
    <img :src="pictureUrl" referrerpolicy="no-referrer" />
  </div>
</template>

<script>
import Logo from "@/assets/profil_picture.png";

import { mapGetters } from "vuex";
export default {
  name: "UserProfile",
  computed: {
    ...mapGetters({ user: "auth/user", tvUser: "auth/tvUser" }),
    pictureUrl() {
      return this.tvUser.pictureUrl || this.user.pictureUrl || Logo;
    },
  },
};
</script>

<style scoped lang="scss">
.user-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $secondary-color;
  box-shadow: 0px 0px 0px 4px rgba($secondary-color, 0.25);
  flex-shrink: 0;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $layout-breakpoint-small) {
  .user-profile {
    width: 34px;
    height: 34px;
  }
}
</style>
