<template>
  <div class="login-background">
    <div class="line" v-for="i in numberY" :key="i">
      <EyeLogo :x="x" :y="y" v-for="j in numberX" :key="j" />
    </div>
  </div>
</template>

<script>
import EyeLogo from "@/components/EyeLogo.vue";

export default {
  name: "LoginBackground",
  components: {
    EyeLogo,
  },
  data() {
    return {
      x: -1920,
      y: -1080,
      numberX: 0,
      numberY: 0,
    };
  },
  mounted() {
    this.numberX = Math.ceil(window.innerWidth / 104);
    this.numberY = Math.ceil(window.innerHeight / 64);

    window.addEventListener("mousemove", this.mousemove);
  },
  unmounted() {
    window.removeEventListener("mousemove", this.mousemove);
  },
  methods: {
    mousemove(event) {
      this.x = event.clientX;
      this.y = event.clientY;
    },
  },
};
</script>

<style scoped lang="scss">
.login-background {
  .line {
    display: flex;
    align-items: center;
    width: calc(100% + 112px);
    overflow: hidden;

    &:nth-child(2n + 1) {
      margin-left: -56px;
    }

    &:nth-child(2n) {
      margin-right: -56px;
    }
  }

  .eye-container {
    margin: 16px 32px;
    flex-shrink: 0;
  }
}
</style>
