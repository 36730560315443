import { Component } from "../component";
import { Container } from "@pixi/display";
import { WeatherIcon } from "./projectWeatherIcon";
import { ProjectWeatherStats } from "./projectWeatherStats";
import { RoundedRectangle } from "./RoundedRectangle";

export class WeatherDetail extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.project = this.props.project;
    this.projectColor = this.props.projectColor;

    this.container = new Container();
    this.container.y = -10;
    this.container.x = -10;
    this.container.pivot.set(0, 160);

    this.createWeatherDetailBackground();
    this.createWeatherIcon();
    this.createStats();
  }

  createWeatherDetailBackground() {
    const { container } = this;

    this.weatherDetailBackground = new RoundedRectangle({
      x: 0,
      y: 0,
      width: 422,
      height: 160,
      uRadius: [0.2, 0.2, 0.2, 0.2],
    });

    container.addChild(this.weatherDetailBackground.mesh);
  }

  createWeatherIcon() {
    const { container } = this;

    this.iconSprite = new WeatherIcon({
      weather: this.project.weather,
      projectColor: this.projectColor,
    });
    this.iconSprite.container.width = 80;
    this.iconSprite.container.height = 80;
    this.iconSprite.container.y =
      (this.weatherDetailBackground.height - this.iconSprite.container.height) /
      2;
    this.iconSprite.container.x = 30;
    this.iconSprite.container.y = 30;

    container.addChild(this.iconSprite.container);
    this.addComponent(this.iconSprite);
  }

  createStats() {
    const { container } = this;
    this.productStats = new ProjectWeatherStats({
      progression: this.project.progression.product,
      label: "Produit",
      projectColor: this.projectColor,
    });

    this.designStats = new ProjectWeatherStats({
      progression: this.project.progression.design,
      label: "Design",
      projectColor: this.projectColor,
    });

    this.designStats.container.y += 40;

    this.technicalStats = new ProjectWeatherStats({
      progression: this.project.progression.technical,
      label: "Technique",
      projectColor: this.projectColor,
    });

    this.technicalStats.container.y += 80;

    container.addChild(this.productStats.container);
    this.addComponent(this.productStats);
    container.addChild(this.designStats.container);
    this.addComponent(this.designStats);
    container.addChild(this.technicalStats.container);
    this.addComponent(this.technicalStats);
  }
}
