import CloudyIcon from "@/components/icons/weather/CloudyIcon";
import PartlyCloudyIcon from "@/components/icons/weather/PartlyCloudyIcon";
import RainyIcon from "@/components/icons/weather/RainyIcon";
import StormyIcon from "@/components/icons/weather/StormyIcon";
import SunnyIcon from "@/components/icons/weather/SunnyIcon";
import CrossIcon from "@/components/icons/CrossIcon";
import MiruIcon from "@/components/icons/MiruIcon";
import CalendarIcon from "@/components/icons/CalendarIcon";
import GraphIcon from "@/components/icons/GraphIcon";
import DashboardIcon from "@/components/icons/DashboardIcon";
import AddIcon from "@/components/icons/AddIcon";
import ZoomInIcon from "@/components/icons/ZoomInIcon";
import ZoomOutIcon from "@/components/icons/ZoomOutIcon";
import CenterIcon from "@/components/icons/CenterIcon";
import TrashIcon from "@/components/icons/TrashIcon";
import StarIcon from "@/components/icons/StarIcon";
import ErrorIcon from "@/components/icons/ErrorIcon";
import InsertPictureIcon from "@/components/icons/InsertPictureIcon";
import DropdownIcon from "@/components/icons/DropdownIcon";
import ArrowIcon from "@/components/icons/ArrowIcon";
import SearchIcon from "@/components/icons/SearchIcon";
import ColorPickerIcon from "@/components/icons/ColorPickerIcon";
import EditIcon from "@/components/icons/EditIcon";
import ListIcon from "@/components/icons/ListIcon";
import EyeIcon from "@/components/icons/EyeIcon";

export default function registrar(app) {
  app
    .component("CloudyIcon", CloudyIcon)
    .component("PartlyCloudyIcon", PartlyCloudyIcon)
    .component("RainyIcon", RainyIcon)
    .component("StormyIcon", StormyIcon)
    .component("SunnyIcon", SunnyIcon)
    .component("CrossIcon", CrossIcon)
    .component("MiruIcon", MiruIcon)
    .component("CalendarIcon", CalendarIcon)
    .component("GraphIcon", GraphIcon)
    .component("DashboardIcon", DashboardIcon)
    .component("AddIcon", AddIcon)
    .component("ZoomInIcon", ZoomInIcon)
    .component("ZoomOutIcon", ZoomOutIcon)
    .component("CenterIcon", CenterIcon)
    .component("TrashIcon", TrashIcon)
    .component("StarIcon", StarIcon)
    .component("ErrorIcon", ErrorIcon)
    .component("InsertPictureIcon", InsertPictureIcon)
    .component("DropdownIcon", DropdownIcon)
    .component("ArrowIcon", ArrowIcon)
    .component("SearchIcon", SearchIcon)
    .component("ColorPickerIcon", ColorPickerIcon)
    .component("EditIcon", EditIcon)
    .component("ListIcon", ListIcon)
    .component("EyeIcon", EyeIcon);
}
