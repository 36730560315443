export default {
  async fetchNotes({ state }) {
    if (!state.loading) {
      try {
        const response = await this.$axios.$get("Notes");
        state.notes = response.notes.reverse();
      } catch (e) {
        return e;
      }
    }
  },

  async sendNote({ state }, payload) {
    try {
      const response = await this.$axios.$post("Notes", payload);
      state.notes.push(response.note);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteNote({ state }, noteId) {
    try {
      this.$axios.$delete(`Notes/${noteId}`);
      state.notes = state.notes.filter((item) => item.id != noteId);
    } catch (e) {
      console.log(e);
    }
  },
};
