<template>
  <div class="timeline">
    <div class="scroller" ref="scroller">
      <div
        class="period"
        :class="{
          current: periodIndex === currentPeriodIndex,
        }"
        v-for="(period, periodIndex) in periods"
        :key="periodIndex"
      >
        <div
          class="start-date date"
          :class="{ centered: period.startDate === period.endDate }"
        >
          {{
            new Date(period.startDate).toLocaleDateString("FR-fr", {
              day: "numeric",
              month: "numeric",
            })
          }}
        </div>
        <div class="end-date date" v-if="period.startDate !== period.endDate">
          {{
            new Date(period.endDate).toLocaleDateString("FR-fr", {
              day: "numeric",
              month: "numeric",
            })
          }}
        </div>
        <span> {{ period.name || period.type }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "MinimalPropalTimeline",
  props: {
    periods: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentPeriodIndex() {
      return this.periods.findIndex(
        (period) => new Date(period.startDate).getTime() >= new Date().getTime()
      );
    },
  },
  mounted() {
    if (this.currentPeriodIndex >= 0) {
      const modal =
        document.querySelector(".project-detail.modal") ||
        document.querySelector(".edition .project");
      const currentPeriod = document.querySelector(".timeline .period.current");

      gsap.to(this.$refs.scroller, {
        scrollTo: {
          x: currentPeriod,
          offsetX: modal.clientWidth / 2 - currentPeriod.clientWidth + 40,
        },
        duration: 0,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.timeline {
  position: relative;
  align-self: unset !important;
  width: 100%;
  margin: 0 -20px;

  &:before {
    content: "";
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  .scroller {
    @include hideScrollbar;

    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 20px;
    overflow: auto;
    width: 100%;
    z-index: 0;
  }
}

.period {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  height: 45px;
  width: max-content;
  min-width: 180px;
  flex-shrink: 0;
  border: 4px solid mix($secondary-color, $white-color, 30%);
  background: mix($secondary-color, $white-color, 10%);
  border-radius: 25px;
  color: $secondary-color;
  flex-shrink: 0;
  transform-origin: center;
  padding: 0 10px;
  font-weight: 600;

  .date {
    top: -24px;
  }

  &.current {
    height: 53px;
    background: $secondary-color;
    border: 4px solid $white-color;
    color: $white-color;

    .date {
      top: -20px;
    }
  }

  &:last-child {
    &::before {
      display: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 180px;
    right: 4px;
    height: 6px;
    width: 12px;
    z-index: 1;
    border-radius: 6px;
    background: mix($secondary-color, $white-color, 20%);
  }

  span {
    transition: 0.3s;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    margin-right: 4px;
  }
}

.date {
  position: absolute;
  top: -20px;
  color: rgba($text-color, 0.5);
  font-size: 12px;
  font-weight: $font-bold;

  &.start-date {
    left: 0;
  }

  &.end-date {
    right: 0;
  }

  &.centered {
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
