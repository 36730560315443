export function formatStringNumber(n) {
  const number = n.toLocaleString("fr");
  return number;
}

export function formatNumber(n) {
  if (typeof n !== "string") {
    return n;
  }
  //'\u202F' is the narrow non breaking space from formatStringNumber
  let stringNumber = n.split("\u202F").join("");
  stringNumber = stringNumber.replace(",", ".");
  return Number(stringNumber);
}
