import getters from "./getters";
import actions from "./actions";

export let state = {
  users: [],
  usersDeleted: [],
  businessRoles: [],
};

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
};
