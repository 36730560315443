<template>
  <div
    class="search-container"
    v-clickout="
      () => {
        searchOpen = false;
        search = '';
      }
    "
  >
    <button
      class="secondary-button"
      @click="openCloseSearch()"
      :class="{ active: searchOpen }"
    >
      <ListIcon />
    </button>
    <div class="select" :class="{ open: searchOpen }">
      <div class="projects">
        <div
          class="project"
          v-for="project in filteredProject"
          :key="project.id"
          :style="{
            propal: project.type === 1,
            color: project.type === 1 ? 'inherit' : project.color,
            borderColor:
              projectHovered === project.id
                ? project.type === 1
                  ? '#06326c'
                  : project.color
                : 'transparent',
            background:
              projectHovered === project.id
                ? project.type === 1
                  ? '#06326c1a'
                  : `${project.color}1a`
                : 'transparent',
          }"
          @mouseover="projectHovered = project.id"
          @mouseleave="projectHovered = null"
          @click="selectProject(project)"
        >
          <b>{{ project.name }},</b>{{ project.clientName }}
        </div>
      </div>
      <div class="search-bar">
        <Input ref="input" v-model="search" :placeholder="$t('search')" />
        <SearchIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "@/helpers/debounce";

import Input from "@/components/inputs/Input";

export default {
  name: "SearchProject",
  components: {
    Input,
  },
  data() {
    return {
      search: "",
      searchDebounce: () => {},
      searchOpen: false,
      projectHovered: null,
      searchResults: null,
    };
  },
  computed: {
    ...mapGetters({ projects: "project/projects" }),
    filteredProject() {
      return this.searchResults || this.projects;
    },
  },
  watch: {
    search() {
      this.searchDebounce();
    },
  },
  mounted() {
    this.searchDebounce = debounce(this.searchProject, 300);
  },
  methods: {
    async searchProject() {
      this.searchResults = await this.$store.dispatch(
        "project/searchProjects",
        this.search
      );
    },
    async selectProject(project) {
      this.$store.dispatch("modals/setModal", {
        modalType: "ProjectPreview",
        modalData: {
          project,
        },
      });

      //TODO scroll to project in background gsap pixi
      this.searchOpen = false;
      this.search = "";
    },
    openCloseSearch() {
      this.searchOpen = !this.searchOpen;
      this.search = "";
      if (this.searchOpen) {
        this.$refs.input.focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-container {
  position: relative;
  display: flex;
}

.select {
  background-color: $white-color;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  position: absolute;
  left: 70px;
  bottom: 0;
  height: 398px;
  padding: 0 14px 12px;
  width: 370px;
  transition: 0.3s;
  transform-origin: bottom left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform: scale(0);
  pointer-events: initial;

  &.open {
    transform: scale(1);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 24px;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.projects {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 0 60px;
  width: 100%;
}

.project {
  padding: 12px 20px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 12px;
  flex-shrink: 0;
  border: 2px solid transparent;
  transition: 0.3s;
  cursor: pointer;

  b {
    margin-right: 4px;
  }
}

.search-bar {
  position: absolute;
  bottom: 12px;
  left: 14px;
  right: 14px;
  display: flex;
  flex-shrink: 0;

  .input {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  svg {
    width: 40px;
    position: absolute;
    right: 0;
    top: 4px;
  }
}
</style>
