import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Texture } from "@pixi/core";
import { Component } from "../component";
import { BitmapText } from "@pixi/text-bitmap";
import Device from "@/helpers/device";
import Data from "./data";

export class Days extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.daysBackground = [];
    this.daysBorder = [];
    this.dayTexts = [];
    this.dayWeekTexts = [];
    this.monthTexts = [];
    this.weekTexts = [];
    this.weekNumberTexts = [];

    this.container = new Container();
    this.container.sortableChildren = true;
    this.container.interactive = false;
    this.container.interactiveChildren = false;

    const today = new Date(new Date().setHours(0, 0, 0, 0));

    this.currentWeekNumber = this.getWeekNumber(today);

    this.createDay();
  }

  createDay() {
    const { dayWidth, dayInMs, firstDate, daysInterval } = Data;

    for (let day = 0; day < daysInterval; day++) {
      const date = new Date(firstDate + day * dayInMs);
      const weekday = date.getDay();
      const isMonday = weekday === 1;

      const weekNumber = this.getWeekNumber(date);

      const isCurrentWeek =
        weekNumber === this.currentWeekNumber &&
        date.getFullYear() === new Date().getFullYear();

      this.createDayBackground(day * dayWidth, isCurrentWeek, day);
      this.createDayBorder(day * dayWidth, isMonday, day);
      this.createDayWeekText(day * dayWidth + dayWidth * 0.5, weekday, day);
      this.createDayText(
        day * dayWidth + dayWidth * 0.5,
        date.getDate(),
        isMonday,
        day
      );
      if (date.getDate() === 1) {
        this.createMonthText(date, day * dayWidth, day);
      }
      if (date.getDay() === 4) {
        this.createWeekText(day, weekNumber, day * dayWidth + dayWidth * 0.5);
        this.createWeekNumberText(
          day,
          weekNumber,
          day * dayWidth + dayWidth * 0.5
        );
      }
    }
  }

  createDayBackground(x, currentWeek, index) {
    if (currentWeek) {
      const day = new Sprite(Texture.WHITE);
      this.container.addChild(day);
      day.tint = 0xdce7fa;
      day.y = 0;
      day.zIndex = -1;

      this.optimizeObject(day);
      this.daysBackground[index] = day;
      this.refreshDayBackground(x, currentWeek, index);
    }
  }

  getWeekNumber(aDate) {
    let date = new Date(aDate.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));

    let week1 = new Date(date.getFullYear(), 0, 4);

    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }

  refreshDayBackground(x, currentWeek, index) {
    if (currentWeek) {
      const { dayWidth } = Data;
      const { height } = Device;
      let day = this.daysBackground[index];
      if (day) {
        day.x = x;
        day.height = height;
        day.width = dayWidth;
      }
    }
  }

  createDayBorder(x, isMonday, index) {
    let day = new Sprite(Texture.WHITE);
    day.y = 0;
    day.width = 2;
    this.optimizeObject(day);
    this.daysBorder.push(day);
    this.container.addChild(day);

    if (isMonday) {
      day.tint = 0x06326c;
      day.alpha = 0.06;
    } else {
      day.alpha = 0;
    }
    this.refreshDayBorder(x, index);
  }

  refreshDayBorder(x, index) {
    const { height } = Device;
    let day = this.daysBorder[index];
    day.x = x;
    day.height = height;
  }

  createDayWeekText(x, weekday, index) {
    const { dayWidth } = Data;

    const weekdays = ["D", "L", "M", "M", "J", "V", "S"];
    let text = new BitmapText(`${weekdays[weekday]}`, {
      fontName: "Montserrat-Regular",
      fontSize: 24,
      align: "center",
      tint: weekday === 1 ? 0x7d95b4 : 0xb8c6d8,
      textWidth: dayWidth,
    });
    text.scale.set(0.5);
    this.optimizeObject(text);
    text.roundPixels = true;
    text.y = 170;
    text.anchor.set(0.5);
    this.dayWeekTexts[index] = text;
    text.meta = {
      zoomLevel: 3,
    };
    this.container.addChild(text);
    this.refreshDayWeekText(x, index);
  }

  refreshDayWeekText(x, index) {
    let text = this.dayWeekTexts[index];
    text.x = x;
  }

  createDayText(x, day, isMonday, index) {
    let text = new BitmapText(day < 10 ? `0${day}` : `${day}`, {
      fontName: "Montserrat-Regular",
      fontSize: 24,
      tint: isMonday ? 0x7d95b4 : 0xb8c6d8,
      align: "center",
    });
    text.scale.set(0.5);
    this.optimizeObject(text);
    text.roundPixels = true;
    text.y = 150;
    text.anchor.set(0.5);
    this.dayTexts[index] = text;
    text.meta = {
      zoomLevel: 3,
    };

    this.container.addChild(text);

    this.refreshDayText(x, index);
  }

  refreshDayText(x, index) {
    let text = this.dayTexts[index];
    text.x = x;
  }

  createWeekText(day, weekNumber, x) {
    let text = new BitmapText(`Semaine ${weekNumber}`, {
      fontName: "Montserrat-Regular",
      fontSize: 24,
      tint: 0x0068ff,
      align: "center",
    });
    text.scale.set(0.5);
    text.anchor.set(0.5);
    text.y = 150;
    this.optimizeObject(text);
    text.roundPixels = true;
    text.meta = {
      zoomLevel: 2,
    };

    this.weekTexts.push({ text, index: day });
    this.container.addChild(text);

    this.refreshWeekText(x, day);
  }

  refreshWeekText(x, day) {
    let text = this.weekTexts.find((week) => week.index == day).text;
    text.x = x;
  }

  createWeekNumberText(day, weekNumber, x) {
    let text = new BitmapText(`${weekNumber}`, {
      fontName: "Montserrat-Regular",
      fontSize: 24,
      tint: 0x0068ff,
      align: "center",
    });
    text.scale.set(0.5);
    text.anchor.set(0.5);
    text.y = 150;
    this.optimizeObject(text);
    text.roundPixels = true;
    text.meta = {
      zoomLevel: 1,
    };

    this.weekNumberTexts.push({ text, index: day });
    this.container.addChild(text);

    this.refreshWeekNumberText(x, day);
  }

  refreshWeekNumberText(x, day) {
    let text = this.weekNumberTexts.find((week) => week.index == day).text;
    text.x = x;
  }

  createMonthText(date, x, index) {
    let month = date.toLocaleDateString("fr-FR", { month: "long" });
    const firstLetter = month[0].toUpperCase();
    month = month.slice(1, month.length);

    const monthText = new BitmapText(`${firstLetter}${month}`, {
      fontName: "Montserrat-Bold",
      fontSize: 24,
      tint: 0x06326c,
      align: "center",
    });
    monthText.scale.set(0.6);
    monthText.roundPixels = true;
    monthText.y = 100;

    this.optimizeObject(monthText);
    this.monthTexts.push({ sprite: monthText, index });
    this.container.addChild(monthText);
    this.refreshMonthText(x, index);
  }

  refreshMonthText(x, index) {
    const monthSprite = this.monthTexts.find(
      (month) => month.index === index
    ).sprite;
    monthSprite.x = x;
  }

  refresh() {
    const { dayWidth, dayInMs, firstDate, daysInterval } = Data;

    for (let day = 0; day < daysInterval; day++) {
      const date = new Date(firstDate + day * dayInMs);

      const weekNumber = this.getWeekNumber(date);

      const isCurrentWeek =
        weekNumber === this.currentWeekNumber &&
        date.getFullYear() === new Date().getFullYear();

      this.refreshDayBackground(day * dayWidth, isCurrentWeek, day);
      this.refreshDayBorder(day * dayWidth, day);
      this.refreshDayWeekText(day * dayWidth + dayWidth * 0.5, day);
      this.refreshDayText(day * dayWidth + dayWidth * 0.5, day);

      if (date.getDate() === 1) {
        this.refreshMonthText(day * dayWidth, day);
      }
      if (date.getDay() === 4) {
        this.refreshWeekText(day * dayWidth + dayWidth * 0.5, day);
        this.refreshWeekNumberText(day * dayWidth + dayWidth * 0.5, day);
      }
    }
  }

  resize() {
    super.resize();
    this.refresh();
  }
}
