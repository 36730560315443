<template>
  <div class="topbar" :class="{ fixed: fixedPosition }">
    <GlobalWeather />
    <UserMenu v-if="tvUser.id || user.id" />
    <TVMenu v-if="loggedInTV" />
  </div>
</template>

<script>
import GlobalWeather from "@/components/GlobalWeather.vue";
import UserMenu from "@/components/UserMenu.vue";
import TVMenu from "@/components/TVMenu.vue";
import { mapGetters } from "vuex";

export default {
  name: "TopbarComponent",
  components: {
    GlobalWeather,
    UserMenu,
    TVMenu,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      qrCodeUrl: "auth/qrCodeUrl",
      loggedInTV: "auth/loggedInTV",
      tvUser: "auth/tvUser",
    }),
    fixedPosition() {
      return this.$route.meta.fixedNavigation;
    },
  },
};
</script>

<style scoped lang="scss">
.topbar {
  position: absolute;
  top: 20px;
  right: 50px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1;

  &.fixed {
    position: fixed;
  }
}
</style>
