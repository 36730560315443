import gsap from "gsap";
import { Container } from "@pixi/display";
import { Component } from "../component";
import { Days } from "./days";
import { Projects } from "./projects";
import Device from "@/helpers/device";
import { invlerp } from "@/helpers/math";
import Data from "./data";
import VirtualScroll from "virtual-scroll";

export class Calendar extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    const { dayInMs } = Data;

    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    Data.firstDate = startDate.getTime();

    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 1);
    Data.lastDate = endDate.getTime();

    Data.daysInterval = (Data.lastDate - Data.firstDate) / dayInMs;
    Data.offsetInterval = (new Date().getTime() - Data.firstDate) / dayInMs;
    this.proxy = {
      progress: {
        x: 0,
        y: 220,
      },
      x: 0,
      y: 220,
    };

    this.scroller = new VirtualScroll({
      preventTouch: true,
      el: document.body.querySelector(".pixi-calendar"),
    });
    this.scroller.on((e) => {
      const { deltaX, originalEvent, deltaY } = e;

      originalEvent.preventDefault();
      const { minX, maxX, minY, maxY } = this.getMinMax();
      const x = Math.max(Math.min(minX, this.proxy.x + deltaX), maxX);
      const y = Math.max(Math.min(minY, this.proxy.y + deltaY), maxY);

      if (this.overwriteAnimation) this.overwriteAnimation.kill();

      const direction = Math.abs(deltaX) >= Math.abs(deltaY);
      const args = {
        overwrite: "auto",
        duration: 0.0625,
        [direction ? "x" : "y"]: direction ? x : y,
        onUpdate: () => {
          this.container.x = this.proxy.x;
          this.projectsContainerSprite.container.y = this.proxy.y;
          this.setScrollProgress();
        },
      };

      this.overwriteAnimation = gsap.to(this.proxy, args);
    });
    window.pixiCalendar = this;

    this.container = new Container();
    this.days = new Days();
    this.today = new Date().getTime();
    this.addComponent(this.days);

    this.projects = this.props.projects;
    this.projectsContainerSprite = new Projects({ projects: this.projects });

    this.addComponent(this.projectsContainerSprite);

    this.setOffset();

    this.components.forEach((component) => {
      this.container.addChild(component.container);
    });

    this.days.container.children.forEach((children) => {
      if (children.meta) {
        const { zoomLevel } = children.meta;
        const lower = invlerp(zoomLevel - 0.5, zoomLevel, 2);
        const upper = 1.0 - invlerp(zoomLevel, zoomLevel + 0.5, 2);
        children.alpha = lower * upper;
      }
    });
    this.projectsContainerSprite.refresh();
    this.setScrollProgress();
  }

  getOffset(compare = this.today) {
    const { width } = Device;
    const { firstDate, dayWidth, dayInMs } = Data;

    const offsetInDays = (compare - firstDate) / dayInMs;
    return -offsetInDays * dayWidth + (width + dayWidth) * 0.5;
  }

  getMinMax() {
    const { lastDate } = Data;
    const minX = 0;
    const maxX = this.getOffset(lastDate);
    const minY = 220;
    const maxY = -this.projectsContainerSprite.container.height;

    return {
      minX,
      maxX,
      minY,
      maxY,
    };
  }

  getOffsetFromProgress() {
    const { width } = Device;
    const { dayWidth } = Data;
    const offsetInDays = this.proxy.progress.x;
    return offsetInDays * dayWidth + (width + dayWidth) * 0.5;
  }

  setOffset(x = this.getOffset(), y = 220) {
    this.container.x = x;
    this.proxy.x = x;
    this.projectsContainerSprite.container.y = y;
    this.proxy.y = y;
  }

  setScrollProgress() {
    const { width } = Device;
    const { dayWidth } = Data;

    let x = this.container.x;
    x = (x - (width + dayWidth) * 0.5) / dayWidth;
    this.proxy.progress.x = x;

    let y = this.projectsContainerSprite.container.y;
    this.proxy.progress.y = y;

    this.projectsContainerSprite.updateProgress();
  }

  zoomIn(newZoomLevel) {
    if (this.tl) this.tl.kill();
    this.tl = new gsap.timeline({
      onUpdate: () => {
        this.refresh(vars);
        this.projectsContainerSprite.updateProgress();
      },
    });
    const { dayWidth, dayWidths, zoomLevel } = Data;
    const vars = {
      x: dayWidth,
      zoomLevel,
    };
    this.tl.to(
      vars,
      {
        x: dayWidths[newZoomLevel - 1],
        zoomLevel: newZoomLevel,
        ease: "expo.out",
        duration: 0.628,
      },
      "a"
    );
  }

  zoomOut(newZoomLevel) {
    if (this.tl) this.tl.kill();
    this.tl = new gsap.timeline({
      onUpdate: () => {
        this.refresh(vars);
        this.projectsContainerSprite.updateProgress();
      },
    });
    const { dayWidth, dayWidths, zoomLevel } = Data;
    const vars = {
      x: dayWidth,
      zoomLevel,
    };
    this.tl.to(
      vars,
      {
        x: dayWidths[newZoomLevel - 1],
        zoomLevel: newZoomLevel,
        ease: "expo.out",
        duration: 0.628,
      },
      "a"
    );
  }

  centerCaldendar() {
    if (this.tl) this.tl.kill();
    this.tl = new gsap.timeline({
      onUpdate: () => {
        this.setOffset(vars.x, vars.y);
        this.setScrollProgress();
      },
    });
    const vars = {
      x: this.container.x,
      y: this.proxy.y,
    };
    this.tl.to(
      vars,
      {
        x: this.getOffset(),
        y: 220,
        ease: "expo.out",
        duration: 0.628,
      },
      "a"
    );
  }

  updateProjects(projects) {
    this.projects = projects;
    this.container.removeChild(this.projectsContainerSprite.container);
    this.projectsContainerSprite.container.destroy();
    this.projectsContainerSprite.dispose();
    this.projectsContainerSprite = new Projects({ projects: this.projects });
    this.container.addChild(this.projectsContainerSprite.container);
    this.addComponent(this.projectsContainerSprite);
    this.projectsContainerSprite.refresh();
  }

  scroll(x, y) {
    if (this.tl) this.tl.kill();
    this.tl = new gsap.timeline({
      onUpdate: () => {
        this.setOffset(vars.x, vars.y);
        this.setScrollProgress();
      },
    });
    const vars = {
      x: this.proxy.x,
      y: this.proxy.y,
    };
    this.tl.to(
      vars,
      {
        x: vars.x + x,
        y: vars.y + y,
        ease: "expo.out",
        duration: 0.628,
      },
      "a"
    );
  }

  refresh(vars) {
    Data.dayWidth = vars.x;
    Data.zoomLevel = vars.zoomLevel;
    this.setOffset(this.getOffsetFromProgress(), this.proxy.y);
    this.projectsContainerSprite.setZoomLevel(vars);
    super.refresh();
    this.days.container.children.forEach((children) => {
      if (children.meta) {
        const { zoomLevel } = children.meta;
        const lower = invlerp(zoomLevel - 0.5, zoomLevel, vars.zoomLevel);
        const upper = 1.0 - invlerp(zoomLevel, zoomLevel + 0.5, vars.zoomLevel);
        children.alpha = lower * upper;
      }
    });
  }

  resize() {
    super.resize();
    this.setOffset(this.getOffsetFromProgress());
    this.setScrollProgress();
  }

  dispose() {
    super.dispose();
    this.scroller.destroy();
  }
}
