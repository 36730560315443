<template>
  <svg viewBox="0 0 44 44">
    <title>icon_target</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-10.000000, -10.000000)">
        <g transform="translate(10.000000, 10.000000)">
          <g id="Group-17" transform="translate(6.000000, 7.000000)">
            <path
              d="M15.5599267,13.6127752 C14.1757046,13.6127752 13.0493561,14.7627219 13.0493561,16.1763109 C13.0493561,17.5898999 14.1757046,18.7398467 15.5599267,18.7398467 C16.9443096,18.7398467 18.0704973,17.5898999 18.0704973,16.1763109 C18.0704973,14.7627219 16.9443096,13.6127752 15.5599267,13.6127752 M15.5599267,20.2100712 C13.3816659,20.2100712 11.6096687,18.4005262 11.6096687,16.1763109 C11.6096687,13.9520957 13.3816659,12.1425507 15.5599267,12.1425507 C17.7381875,12.1425507 19.5103455,13.9520957 19.5103455,16.1763109 C19.5103455,18.4005262 17.7381875,20.2100712 15.5599267,20.2100712"
              id="Fill-1"
              fill="CurrentColor"
            ></path>
            <path
              d="M15.5599267,20.2100712 C13.3816659,20.2100712 11.6096687,18.4005262 11.6096687,16.1763109 C11.6096687,13.9520957 13.3816659,12.1425507 15.5599267,12.1425507 C17.7381875,12.1425507 19.5103455,13.9520957 19.5103455,16.1763109 C19.5103455,18.4005262 17.7381875,20.2100712 15.5599267,20.2100712 Z"
              id="Stroke-3"
              stroke="CurrentColor"
              stroke-width="0.646075733"
            ></path>
            <path
              d="M15.5599749,7.29136799 C15.043906,7.29136799 14.6257456,6.86422147 14.6257456,6.33742933 L14.6257456,0.953938656 C14.6257456,0.427146512 15.043906,0 15.5599749,0 C16.0760438,0 16.4942043,0.427146512 16.4942043,0.953938656 L16.4942043,6.33742933 C16.4942043,6.86422147 16.0760438,7.29136799 15.5599749,7.29136799"
              id="Fill-5"
              fill="CurrentColor"
            ></path>
            <path
              d="M15.5599749,7.29136799 C15.043906,7.29136799 14.6257456,6.86422147 14.6257456,6.33742933 L14.6257456,0.953938656 C14.6257456,0.427146512 15.043906,0 15.5599749,0 C16.0760438,0 16.4942043,0.427146512 16.4942043,0.953938656 L16.4942043,6.33742933 C16.4942043,6.86422147 16.0760438,7.29136799 15.5599749,7.29136799 Z"
              id="Stroke-7"
              stroke="CurrentColor"
              stroke-width="0.625925926"
            ></path>
            <path
              d="M25.1473288,16.1876709 C25.1473288,10.7810335 20.8549541,6.39810318 15.5600232,6.39810318 C10.2650922,6.39810318 5.97255671,10.7810335 5.97255671,16.1876709 C5.97255671,21.5943082 10.2650922,25.9772386 15.5600232,25.9772386"
              id="Stroke-9"
              stroke="CurrentColor"
              stroke-width="2.422784"
              stroke-linecap="round"
            ></path>
            <line
              x1="0"
              y1="16.1876709"
              x2="1.94964711"
              y2="16.1876709"
              stroke="CurrentColor"
              stroke-width="2.422784"
              stroke-linecap="round"
            ></line>
            <line
              x1="29.0503529"
              y1="16.1876709"
              x2="31"
              y2="16.1876709"
              stroke="CurrentColor"
              stroke-width="2.422784"
              stroke-linecap="round"
            ></line>
            <line
              x1="15.5599749"
              y1="30"
              x2="15.5599749"
              y2="26.2523721"
              stroke="CurrentColor"
              stroke-width="2.422784"
              stroke-linecap="round"
            ></line>
          </g>
          <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
