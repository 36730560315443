<template>
  <div
    class="weather-icon"
    :class="{ edition }"
    :style="{ backgroundColor: edition ? `${color}1A` : '#ffffff' }"
  >
    <component :is="`${weatherIcon}Icon`" />
  </div>
</template>

<script>
import weather from "@/enums/weather";

export default {
  name: "WeatherIcon",
  props: {
    weatherId: {
      type: Number,
    },
    edition: {
      type: Boolean,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      weather,
    };
  },
  computed: {
    weatherIcon() {
      return (
        this.weather.find((weather) => weather.id === this.weatherId)?.name ||
        "Sunny"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.weather-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: $white-color;

  svg {
    width: 56px;
  }

  &.edition {
    width: 50px;
    height: 50px;

    svg {
      width: 35px;
    }
  }
}

@media (max-width: $layout-breakpoint-small) {
  .weather-icon {
    &.edition {
      width: 40px;
      height: 40px;

      svg {
        width: 24px;
      }
    }
  }
}
</style>
