<template>
  <div class="loader">
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: "LoaderComponent",
};
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  > span {
    position: absolute;
    inset: 0 0 0 0;
    border: 2px solid $secondary-color;
    border-radius: 50%;
    animation: loader-anim 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  > span:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes loader-anim {
    0% {
      scale: 0;
      opacity: 0;
    }
    50% {
      scale: 0.5;
      opacity: 1;
    }
    100% {
      scale: 1;
      opacity: 0;
    }
  }
}
</style>
