import router from "@/router";
import { isExpired } from "@/helpers/date";

export default {
  async loginWithGoogle({ dispatch }, { token }) {
    try {
      const { user } = await this.$axios.$post(
        `/Auth/SignIn/Google`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await dispatch("registerUser", user);

      return user;
    } catch (e) {
      dispatch("clearUser");
      throw e.response.data;
    }
  },

  async registerUser({ dispatch }, user) {
    if (user.claim.accessToken) {
      await dispatch("setUser", { user, accessToken: user.claim.accessToken });
    }
    dispatch("setAuthorization");
  },

  async setUser({ state }, { user, accessToken }) {
    state.accessToken = accessToken;
    state.refreshToken = user.claim.refreshToken;
    state.loggedIn = true;
    state.user = user;

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", user.claim.refreshToken);
    localStorage.setItem("miruUserInfo", JSON.stringify(user));
  },

  setAuthorization({ dispatch, getters }) {
    const authorization = `Bearer ${getters.accessToken}`;
    this.$axios.defaults.headers.common["Authorization"] = authorization;

    dispatch("unsetInterceptor");
    this.$axios.authorizationInterceptor = this.$axios.interceptors.request.use(
      async (config) => {
        try {
          await dispatch("checkTokenExpired", "accessTokenExpirationDate");
        } catch (e) {
          if (e.response.status === 409) {
            dispatch("clearUser");
            router.push({ name: "Login" });
          }
          throw e;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  unsetInterceptor() {
    if (!isNaN(this.$axios.authorizationInterceptor)) {
      this.$axios.interceptors.request.eject(
        this.$axios.authorizationInterceptor
      );
      this.$axios.authorizationInterceptor = null;
    }
  },

  clearUser({ state, dispatch }) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("miruUserInfo");
    state.loggedIn = false;
    state.user = null;

    delete this.$axios.defaults.headers.common["Authorization"];
    dispatch("unsetInterceptor");
  },

  async checkTokenExpired({ state, dispatch }, key) {
    const tokenExpirationDate = state.user.claim[key];
    const expiration = isExpired(tokenExpirationDate);
    return expiration ? await dispatch("refreshToken") : true;
  },

  async refreshToken({ state, dispatch }) {
    const { accessToken, refreshToken } = state.user.claim;
    if (!state.tokenRefresher.isLoading) {
      state.tokenRefresher.isLoading = true;
      try {
        state.tokenRefresher.promise = await this.$axios.$post(
          "auth/refreshtoken",
          {
            accessToken,
            refreshToken,
          }
        );
        const user = state.tokenRefresher.promise.userClaim;
        await dispatch("registerUser", user);
        state.tokenRefresher.isLoading = false;
        return user;
      } catch (e) {
        state.tokenRefresher.isLoading = false;
        dispatch("clearUser");
        router.push({ name: "Login" });
        throw e;
      }
    } else {
      return state.tokenRefresher.promise;
    }
  },

  loginAsTv({ state, dispatch }, qrCodeUrl) {
    dispatch("clearUser");
    state.loggedInTV = true;
    state.qrCodeUrl = qrCodeUrl;
    state.loggedIn = false;
  },

  setTVUser({ state }, { user }) {
    state.tvUser = user;
  },

  async logout({ dispatch }) {
    dispatch("clearUser");
  },
};
