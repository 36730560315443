<template>
  <template v-if="loading">
    <div class="loading">
      <div class="container">
        <Loader />
      </div>
    </div>
  </template>
  <template v-else>
    <div class="list">
      <div class="note" v-for="note in notes" :key="note.id">
        <div class="msg">
          <div class="profile-pic" :style="roleColor(note)">
            <img
              v-if="note.owner.pictureUrl"
              :src="note.owner.pictureUrl"
              :alt="note.owner.lastName"
              referrerpolicy="no-referrer"
            />
          </div>
          <div class="content">
            <div class="img-container">
              <img
                v-if="note.pictureUrl"
                :src="note.pictureUrl"
                alt=""
                referrerpolicy="no-referrer"
                @click="growPicture(note.id)"
              />
            </div>
            <span v-if="note.content">{{ note.content }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div class="control">
    <button class="primary-button" @click="previous">
      {{ $t("timeline") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";

export default {
  name: "RemoteStatsControls",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      selectedImage: null,
    };
  },
  computed: {
    ...mapGetters({
      notes: "notes/notes",
      businessRoles: "user/businessRoles",
    }),
  },
  async created() {
    if (!this.notes.length) {
      await this.fetchNotes();
    }
  },
  methods: {
    roleColor(note) {
      return {
        border: ` 2px solid ${
          this.businessRoles.find((item) => item.name === note.owner.roles[0])
            ?.color || "#c1c6cA"
        }`,
      };
    },
    async fetchNotes() {
      this.loading = true;
      await this.$store.dispatch("notes/fetchNotes");
      this.loading = false;
    },
    growPicture(noteId) {
      if (!this.selectedImage) {
        this.selectedImage = noteId;
        const argument = {
          method: "growPicture",
          noteId,
        };
        this.$socket.invoke("sendInstruction", JSON.stringify(argument));
      } else {
        const argument = {
          method: "shrinkImage",
          noteId,
        };
        this.$socket.invoke("sendInstruction", JSON.stringify(argument));
        this.selectedImage = null;
      }
    },
    previous() {
      const argument = {
        method: "openRemoteTimeline",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
      this.$router.push({
        name: "RemoteTimeLineControls",
        query: { roomToken: this.$route.query.roomToken },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.remote {
  padding: 100px 0 0 0;
  height: 100vh;
  position: relative;

  .list {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    padding: 20px 0;

    .note {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px;

      .msg {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 14px 15px 14px 20px;
        background-color: #eff1f3;
        border-radius: 10px;

        .profile-pic {
          width: 32px;
          height: 32px;
          overflow: hidden;
          border-radius: 50%;
          flex-shrink: 0;

          img {
            width: 100%;
            object-fit: contain;
            z-index: 90;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        > span {
          word-break: break-word;
          font-size: var(--big-size);
          color: $secondary-color;
          font-weight: bold;
          line-height: 23px;
        }

        .img-container {
          border-radius: 10px;
          overflow: hidden;
          width: 100%;

          > img {
            object-fit: contain;
            width: 100%;
          }
        }
      }
    }
  }

  .loading {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

    > .container {
      width: 100px;
      height: 100px;
    }
  }

  .control {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 116px;
    background-color: $background-color;
    box-shadow: 0 5px 4px 8px rgb(0 0 0 / 5%);
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px;

    > button {
      width: 160px;
      height: 65px;
    }
  }
}
</style>
