<template>
  <div class="top-bar">
    <button
      class="recenter"
      @click="recenter()"
      v-if="$route.name === 'RemoteTimeLineControls'"
    >
      <CenterIcon />
    </button>
    <MiruIcon class="logo" />
    <UserMenu class="profile" />
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu";

export default {
  name: "TopBar",
  components: {
    UserMenu,
  },
  methods: {
    recenter() {
      const argument = {
        method: "center",
      };
      this.$socket.invoke("sendInstruction", JSON.stringify(argument));
    },
  },
};
</script>

<style scoped lang="scss">
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $background-color;
  padding: 40px 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.logo {
  width: 92px;
  margin-left: auto;
}

.recenter {
  background: none;

  svg {
    width: 34px;
    color: $secondary-color;
  }
}

.profile {
  margin-left: auto;
  position: relative;
  top: 0;
  right: 0;
}
</style>
