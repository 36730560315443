import { createApp, ref, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import clickout from "./directives/clickoutside";
import { axiosHelper, instance as axiosInstance } from "./helpers/axios";

import authHelper from "./helpers/auth";
import VueSignalR from "./helpers/signalR";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Flip from "gsap/Flip";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import regitrar from "./components/registrar";

import GoogleIdentity from "vue-google-identity";

var WebFont = require("webfontloader");
gsap.registerPlugin(ScrollToPlugin, Flip, ScrollTrigger);

var fontsLoaded = ref(false);

const app = createApp({
  setup() {
    provide("FontsLoaded", fontsLoaded);
    const WebFontConfig = {
      google: {
        families: [
          "Montserrat:300,400,500,600,700",
          "Mulish:300,400,500,600,700",
        ],
      },
      active: function () {
        fontsLoaded.value = true;
      },
    };
    WebFont.load(WebFontConfig);
  },
  render: () => h(App),
});
app.use(store);
app.use(router);
app.use(i18n);
app.use(authHelper, { router, store, axios: axiosInstance });
app.use(VueSignalR, process.env.VUE_APP_SOCKET_URL);

app.use(GoogleIdentity, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

app.directive("clickout", clickout);
app.config.productionTip = false;
app.use(axiosHelper, {
  apiUrl: process.env.VUE_APP_API_URL,
  store,
});
regitrar(app);
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_AI_KEY,
    connectionString: process.env.VUE_APP_AI_CNX,
    disableTelemetry: false,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableAjaxPerfTracking: true,
    enableUnhandledPromiseRejectionTracking: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

app.mount("#app");

if (process.env.NODE_ENV !== "development") {
  console.log("Miru is crafted with love by L'Atelier");
  console.log("Version :", process.env.VUE_APP_BUILD_NUMBER);
}
