<template>
  <svg viewBox="0 0 44 44">
    <title>list_icon</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-10.000000, -10.000000)">
        <g transform="translate(10.000000, 10.000000)">
          <path
            d="M37.2920991,24.7531278 C38.0366804,24.7531278 38.64,25.3397823 38.64,26.0632928 L38.64,35.5257464 C38.64,36.2492569 38.0366804,36.8359114 37.2920991,36.8359114 C36.5475178,36.8359114 35.9441982,36.2492569 35.9441982,35.5257464 L35.9441982,27.3736834 L8.05603373,27.3736834 L8.056,34.739 L30.0984233,34.739467 C30.8430046,34.739467 31.4463242,35.3261215 31.4463242,36.0498575 C31.4463242,36.773368 30.8430046,37.3600225 30.0984233,37.3600225 L6.70790089,37.3600225 C5.9633196,37.3600225 5.36,36.773368 5.36,36.0498575 C5.36,35.9600864 5.3692824,35.8724244 5.3869654,35.7877294 C5.3692824,35.703127 5.36,35.6154895 5.36,35.5257464 L5.36,26.0632928 C5.36,25.3397823 5.9633196,24.7531278 6.70790089,24.7531278 L37.2920991,24.7531278 Z M37.2920991,6.64 C38.0366804,6.64 38.64,7.22665453 38.64,7.95016506 L38.64,17.4126186 C38.64,18.1361291 38.0366804,18.7227836 37.2920991,18.7227836 C36.5475178,18.7227836 35.9441982,18.1361291 35.9441982,17.4126186 L35.9441982,9.26055558 L8.05603373,9.26055558 L8.056,16.626 L30.0984233,16.6263392 C30.8430046,16.6263392 31.4463242,17.2129937 31.4463242,17.9367297 C31.4463242,18.6602402 30.8430046,19.2468948 30.0984233,19.2468948 L6.70790089,19.2468948 C5.9633196,19.2468948 5.36,18.6602402 5.36,17.9367297 C5.36,17.8469586 5.3692824,17.7592966 5.3869654,17.6746016 C5.3692824,17.5899992 5.36,17.5023617 5.36,17.4126186 L5.36,7.95016506 C5.36,7.22665453 5.9633196,6.64 6.70790089,6.64 L37.2920991,6.64 Z"
            id="Combined-Shape"
            fill="CurrentColor"
          ></path>
          <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
