<template>
  <div class="project-detail">
    <div class="close" @click="closeModal()"><CrossIcon /></div>
    <div class="dates">
      {{ new Date(project.periods[0]?.startDate).toLocaleDateString() }}
      -
      {{
        new Date(
          project.periods[project.periods.length - 1]?.endDate
        ).toLocaleDateString()
      }}
    </div>
    <h1 class="title-1" :style="{ color: project.color }">
      {{ project.name }}
    </h1>
    <div class="line">
      <p class="client-name" :style="{ color: project.color }">
        {{ project.clientName }}
      </p>
      <div class="team">
        <TeamMember
          class="member"
          :userId="project.referrer.id"
          :role="project.referrer.businessRole"
          :referrer="true"
          :hoverDisabled="true"
        />
        -
        <TeamMember
          class="member"
          v-for="member in project.members.slice(0, 4)"
          :key="member.id"
          :userId="member.id"
          :role="member.businessRole"
          :hoverDisabled="true"
        />
        <div
          class="more"
          v-if="project.members.length > 4"
          :style="{ background: project.color }"
          @mouseenter="hovered = true"
          @mouseleave="hovered = false"
        >
          +{{ project.members.length - 4 }}
          <div class="more-members">
            <TransitionGroup @enter="onMemberEnter" @leave="onMemberLeave">
              <TeamMember
                class="member"
                v-for="(member, index) in moreMembers"
                :key="member.id"
                :userId="member.id"
                :role="member.businessRole"
                :hoverDisabled="true"
                :data-index="index"
              />
            </TransitionGroup>
          </div>
        </div>
      </div>
    </div>

    <div class="line" v-if="project.type === 1">
      <div class="item">
        <div class="label">{{ $t("projects.sprintNumber") }}</div>
        <p>{{ project.sprintTotalCount }}</p>
      </div>
      <div class="item">
        <div class="label">{{ $t("projects.estimateAmount") }}</div>
        <p>{{ formatStringNumber(project.price) }} €</p>
      </div>
    </div>

    <ProjectWeather
      :project="project"
      class="weather"
      :style="{
        background: loggedInTV ? `${project.color}26` : `${project.color}0D`,
      }"
      :edition="editionMode && !loggedInTV"
      v-if="project.type === 2"
    />
    <MinimalPropaleTimeline
      :periods="project.periods"
      class="timeline"
      v-if="project.type === 1"
    />
    <MinimalTimeline :periods="project.periods" class="timeline" v-else />
    <div class="description" v-if="project.description">
      <div class="label">{{ $t("description") }}</div>
      <p v-html="project.description"></p>
    </div>
    <div class="events" v-if="events.length">
      <div class="label">{{ $t("projects.weekEvents") }}</div>
      <p class="event" v-for="event in events" :key="event.name">
        <span>{{ new Date(event.date).toLocaleDateString() }}: </span>
        {{ event.name }}
      </p>
    </div>
    <div class="links">
      <div class="label" v-if="project.links.length">
        {{ $t("projects.useFullLinks") }}
      </div>
      <a
        class="link"
        v-for="link in project.links"
        :key="link.name"
        :href="link.url"
        target="_blank"
      >
        <div class="logo">
          <img
            :src="`https://s2.googleusercontent.com/s2/favicons?domain=${getLinkDomain(
              link.url
            )}&sz=64`"
            alt="link logo"
            v-if="link.url && getLinkDomain(link.url)"
          />
        </div>
        <p>{{ link.name }}</p>
      </a>
      <button class="primary-button icon" @click="openEdition()">
        <EditIcon />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatStringNumber } from "@/helpers/formater";
import gsap from "gsap";

import TeamMember from "@/components/TeamMember";
import ProjectWeather from "@/components/ProjectWeather";
import MinimalTimeline from "@/components/project/MinimalTimeline";
import MinimalPropaleTimeline from "@/components/project/MinimalPropaleTimeline";

export default {
  name: "ProjectPreview",
  components: {
    TeamMember,
    ProjectWeather,
    MinimalTimeline,
    MinimalPropaleTimeline,
  },
  data() {
    return {
      formatStringNumber,
      hovered: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      modalResolve: "modals/modalResolve",
      modalData: "modals/modalData",
      loggedInTV: "auth/loggedInTV",
      projects: "project/projects",
    }),
    project() {
      return this.projects.find(
        (project) => project.id === this.modalData.projectId
      );
    },
    events() {
      return this.currentPeriodIndex >= 0
        ? this.project.periods[this.currentPeriodIndex].events
        : [];
    },
    editionMode() {
      return (
        (!this.loggedInTV && this.user.id === this.project.referrer.id) ||
        this.user.roles?.includes("Admin")
      );
    },
    currentPeriodIndex() {
      return this.project.periods.findIndex(
        (period) =>
          new Date().getTime() >= new Date(period.startDate).getTime() &&
          new Date().getTime() <= new Date(period.endDate).getTime()
      );
    },
    moreMembers() {
      return this.hovered
        ? this.project.members.slice(4, this.project.members.length)
        : [];
    },
  },
  methods: {
    closeModal() {
      document.querySelector(".modal-container").classList.remove("open");
      this.modalResolve(false);
    },
    async openEdition() {
      this.$store.dispatch("project/setProject", this.project);
      this.$router.push({ name: "Edition", params: { id: this.project.id } });
      this.closeModal();
    },
    getLinkDomain(url) {
      const usefullDomains = ["invisionapp.com", "visualstudio.com"];

      let domainInfo = new URL(url);
      const domain = usefullDomains.find((domain) =>
        domainInfo.hostname.includes(domain)
      );
      return domain || domainInfo.hostname;
    },
    onMemberEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        delay: el.dataset.index * 0.1,
        onComplete: done,
      });
    },
    onMemberLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        delay: (this.moreMembers.length - el.dataset.index) * 0.1,
        onComplete: done,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.project-detail {
  width: 660px;
  padding: 40px;
  overflow-x: hidden !important;
}

.close {
  position: absolute;
  right: 30px;
  top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  svg {
    width: 40px;
  }

  &:hover {
    color: $primary-color;
  }
}

.dates {
  align-self: flex-start;
  margin-bottom: 10px;
  font-weight: $font-bold;
  letter-spacing: 0.5px;
}

.title-1 {
  align-self: flex-start;
}

.timeline {
  align-self: flex-start;
  margin-bottom: 30px;
}

.client-name {
  font-weight: $font-semibold;
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  .item {
    width: 48%;

    p {
      font-weight: $font-bold;
      color: $primary-color;
    }
  }
}

.team {
  display: flex;
  align-items: center;
  gap: 6px;

  .more {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $white-color;
    font-weight: $font-bold;
    font-size: 16px;
    cursor: default;

    .more-members {
      position: absolute;
      top: 0;
      padding-top: 46px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      z-index: 10;
      transition: 0.3s;

      .member {
        opacity: 0;
      }
    }
  }
}

.weather {
  margin: 20px -40px;
  width: calc(100% + 80px);
  padding: 28px 40px;
}

.description {
  margin-bottom: 40px;
  width: 100%;

  p {
    color: mix($secondary-color, $white-color, 50%);
    line-height: 20px;
  }
}

.events {
  margin-bottom: 40px;
  width: 100%;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 40px;
  width: 100%;

  .label {
    width: 100%;
  }

  .link {
    display: flex;
    align-items: center;

    .logo {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $light-grey-color;
      flex-shrink: 0;
      margin-right: 10px;
      overflow: hidden;

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
}

.label {
  color: $secondary-color;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.1;
  font-weight: $font-bold;
  margin-bottom: 10px;
}

.event {
  margin: 10px 0;

  span {
    font-weight: $font-bold;
    color: $primary-color;
  }
}

.primary-button {
  margin-left: auto;
}
</style>
