<template>
  <svg viewBox="0 0 35 35">
    <title>Rain</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Rain">
        <rect id="Rectangle" x="0" y="0" width="35" height="35"></rect>
        <path
          d="M16,31.0993206 C16,29.4363757 14.2562404,28.2149771 13.2156903,25.584127 C12.9028358,24.793171 12.2010528,24.8248281 11.8052301,25.5619197 C10.4183053,28.1443391 9,29.4297608 9,31.0993206 C9,33.2536598 10.5773476,35 12.510579,35 C14.4435728,35 16,33.2536598 16,31.0993206"
          id="Fill-1"
          fill="#9CDCFF"
        ></path>
        <path
          d="M26,23.044855 C26,21.3585876 23.9980916,19.3581353 22.8179866,17.4525045 C22.4506202,16.85928 21.5894561,16.8467786 21.2061069,17.4300965 C19.9489504,19.3423317 18,21.3519831 18,23.044855 C18,25.2292895 19.802958,27 22.0119275,27 C24.2211355,27 26,25.2292895 26,23.044855"
          id="Fill-4"
          fill="#9CDCFF"
        ></path>
        <path
          d="M29.157613,8.36295988 L29.1734521,8.43984177 C32.3200728,8.80805682 34.8243911,11.1918279 34.991194,14.1912402 C35.1573945,17.1756829 32.9532056,19.7688014 29.8875566,20.4429015 L29.8871859,20.4430369 C21.7742681,22.7215717 13.5770451,23.5297032 6.90960133,22.6554683 C3.18153938,22.237905 0.2078671,19.420441 0.0103669368,15.8718796 C-0.196285377,12.158878 2.7099493,8.95999806 6.63720242,8.39275944 C7.66613979,3.81168927 11.9723508,0.262614556 17.3176085,0.0138380926 C23.0552321,-0.253210088 28.0241589,3.37409449 29.157613,8.36295988 Z"
          id="Combined-Shape"
          fill="#B8BFC9"
        ></path>
      </g>
    </g>
  </svg>
</template>
