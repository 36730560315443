<template>
  <div
    class="modal-container"
    ref="modalContainer"
    @mousedown.self="closeModal()"
  >
    <component :is="modalType" class="modal">
      <template v-slot:header v-if="header">
        <template v-if="header.headerType === 'user'">
          <UserCard :user="header" class="card" />
        </template>
        <template v-if="header.headerType === 'product'">
          <ProductCard
            :project="header"
            class="card"
            :style="{ width: '75%' }"
          />
        </template>
        <template v-if="header.headerType === 'businessRole'">
          <JobCard :businessRole="header" class="card" />
        </template>
      </template>
    </component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectPreview from "./ProjectPreview.vue";
import UserCard from "../dashboard/UserCard.vue";
import JobCard from "../dashboard/JobCard.vue";
import ProductCard from "../dashboard/ProductCard.vue";
import DeleteNote from "./DeleteNote.vue";
import ConfirmationModal from "./ConfirmationModal";

export default {
  name: "ModalTemplate",
  components: {
    ProjectPreview,
    DeleteNote,
    ConfirmationModal,
    UserCard,
    JobCard,
    ProductCard,
  },

  computed: {
    ...mapGetters({
      modalType: "modals/modalType",
      modalData: "modals/modalData",
      modalResolve: "modals/modalResolve",
    }),

    header() {
      return this.modalData.header;
    },
  },
  watch: {
    $route() {
      this.modalResolve(false);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.modalContainer.classList.add("open");
    }, 300);
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.closeModal();
      }
    });
  },
  methods: {
    closeModal() {
      this.$refs.modalContainer?.classList.remove("open");
      setTimeout(() => {
        this.modalResolve(false);
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: rgba($secondary-color, 0.2);
  z-index: 300;

  .modal {
    @include hideScrollbar;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 90vh;
    overflow: auto;
    background: $white-color;
    border-radius: 16px;
  }

  @media (max-width: $layout-breakpoint-large) {
    .modal {
      max-width: $layout-breakpoint-medium;
    }
  }

  @media (min-width: $layout-breakpoint-extralarge) {
    .modal {
      max-width: 60vw;
    }
  }
  .card {
    margin-bottom: 50px;
  }
}
</style>
