<template>
  <div class="input" :class="{ error, light }">
    <label class="label" v-if="label" :for="label">{{ label }}</label>
    <div class="input-container" v-clickout="() => (isOpen = false)">
      <input
        :id="label"
        type="text"
        :value="getDateValue()"
        :placeholder="$t('datePlaceholder')"
        @focus="
          isOpen = true;
          error = false;
        "
      />
      <div class="date-picker" :class="{ open: isOpen }">
        <div
          class="month"
          v-for="(month, index) in 2"
          :key="`month_${month}`"
          :class="{ next: month === 2, previous: month === 1 }"
        >
          <div class="month-name">
            <DropdownIcon @click.prevent="setCalendar(index)" />
            {{
              calendar.getMonthName({
                year: index ? nextYear : currentYear,
                month: index ? nextMonth : currentMonth,
                day: 1,
              })
            }}
            {{ index ? nextYear : currentYear }}
          </div>
          <div class="weekdays">
            <div class="day" v-for="weekday in 7" :key="`weekday_${weekday}`">
              {{ calendar.weekdays[weekday] }}
            </div>
          </div>
          <div class="days">
            <div
              class="day empty"
              v-for="day in getEmptyDays(index)"
              :key="`empty_${day}`"
            ></div>
            <div
              class="day"
              v-for="day in calendar.getDaysPerMonth({
                month: index ? nextMonth : currentMonth,
                year: index ? nextYear : currentYear,
              })"
              :key="`dat_${day}`"
              :class="{
                selected:
                  date &&
                  calendar.isSameDate(
                    DateTime.utc(
                      index ? nextYear : currentYear,
                      index ? nextMonth : currentMonth,
                      day
                    ),
                    date
                  ),
                disabled:
                  dateIsBefore(
                    DateTime.utc(
                      index ? nextYear : currentYear,
                      index ? nextMonth : currentMonth,
                      day
                    ),
                    this.min
                  ) ||
                  dateIsAfter(
                    DateTime.utc(
                      index ? nextYear : currentYear,
                      index ? nextMonth : currentMonth,
                      day
                    ),
                    this.max
                  ),
              }"
              @click.prevent="
                selectDate(
                  DateTime.utc(
                    index ? nextYear : currentYear,
                    index ? nextMonth : currentMonth,
                    day
                  )
                )
              "
            >
              {{ day }}
            </div>
            <div
              class="day empty"
              v-for="day in 31 -
              calendar.getDaysPerMonth({
                month: index ? nextMonth : currentMonth,
                year: index ? nextYear : currentYear,
              })"
              :key="`empty_${day}_end`"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="error-message" v-if="error">
      <ErrorIcon />{{ $t("mandatoryField") }}
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import calendar from "@/helpers/date.js";

export default {
  name: "DateInput",
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    light: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isOpen: false,
      error: false,
      today: null,
      currentMonth: 0,
      nextMonth: 0,
      currentYear: 0,
      nextYear: 0,
      calendar,
      DateTime,
      date: null,
    };
  },
  watch: {
    modelValue(value) {
      this.date = DateTime.fromISO(value) || null;
      this.initCalendar();
    },
  },
  created() {
    this.date = DateTime.fromISO(this.modelValue) || null;
    this.today = DateTime.utc();
    this.initCalendar();
  },
  methods: {
    initCalendar() {
      if (this.modelValue) {
        this.date = DateTime.fromISO(this.modelValue);
        this.currentYear = this.date.year;
        this.currentMonth = this.date.month;
      } else {
        this.currentYear = this.today.year;
        this.currentMonth = this.today.month;
      }
      if (this.currentMonth === 12) {
        this.nextYear = this.currentYear + 1;
        this.nextMonth = 1;
      } else {
        this.nextYear = this.currentYear;
        this.nextMonth = this.currentMonth + 1;
      }
    },
    check() {
      this.error = false;

      if (this.required) {
        this.error = this.error || !this.modelValue;
      }

      return this.error;
    },
    selectDate(date) {
      this.date = date;
      this.$emit("update:modelValue", this.date.toISO());
      this.isOpen = false;
    },
    getDateValue() {
      return this.modelValue
        ? new Date(this.modelValue).toLocaleDateString(calendar.locale)
        : this.modelValue;
    },
    setCalendar(index) {
      if (index) {
        this.currentMonth += 1;
        this.nextMonth += 1;
        if (this.currentMonth === 12) {
          this.nextYear += 1;
          this.nextMonth = 1;
        }
        if (this.currentMonth === 13) {
          this.currentMonth = 1;
          this.currentYear += 1;
        }
      } else {
        this.currentMonth -= 1;
        this.nextMonth -= 1;
        if (this.currentMonth === 0) {
          this.currentMonth = 12;
          this.currentYear -= 1;
        }
        if (this.nextMonth === 0) {
          this.nextMonth = 12;
          this.nextYear = this.currentYear;
        }
      }
    },
    getEmptyDays(index) {
      return this.calendar.getWeekday({
        year: index ? this.nextYear : this.currentYear,
        month: index ? this.nextMonth : this.currentMonth,
        day: 1,
      }) > 0
        ? this.calendar.getWeekday({
            year: index ? this.nextYear : this.currentYear,
            month: index ? this.nextMonth : this.currentMonth,
            day: 1,
          }) - 1
        : 6;
    },
    dateIsBefore(date1, date2) {
      return date2 && date1.toISO() < date2;
    },
    dateIsAfter(date1, date2) {
      return date2 && date1.toISO() > date2;
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  @include input;
  overflow: visible;
  width: 160px;

  .input-container {
    position: relative;
    padding: 0;
  }
}

.date-picker {
  position: absolute;
  background-color: $white-color;
  display: flex;
  border-radius: 10px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
  bottom: 54px;
  opacity: 0;
  width: 505px;
  height: 306px;
  overflow: hidden;
  transition: opacity 0.3s, transform 0.2s;
  transform-origin: left bottom;
  transform: scale(0);
  padding: 20px 14px;

  &.open {
    transform: scale(1);
    opacity: 1;
  }

  .month {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;

    &.next {
      .month-name svg {
        transform: rotate(-90deg);
        right: 0;
      }
    }
    &.previous {
      .month-name svg {
        transform: rotate(90deg);
        left: 0;
      }
    }
  }

  .month-name {
    color: $primary-color;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    color: $primary-color;

    svg {
      width: 44px;
      transform: rotate(90deg);
      position: absolute;
      cursor: pointer;
    }
  }

  .weekdays {
    display: flex;
    width: 100%;
    font-weight: 600;
    color: $text-color;
  }

  .days {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 100%;

    .day {
      cursor: pointer;
      &:hover {
        background-color: rgba($primary-color, 0.15);
      }
      &.selected {
        background-color: $primary-color;
        color: $white-color;
      }
      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .day {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    color: $text-color;
    cursor: default;

    &.empty {
      pointer-events: none;
    }
  }
}
</style>
