<template>
  <div class="activities" :class="{ 'tv-mode': loggedInTV }">
    <Discussion class="card"></Discussion>
    <TurnOver class="card"></TurnOver>
    <DailyJoke class="card"></DailyJoke>
  </div>
</template>

<script>
import Discussion from "@/components/activities/Discussion.vue";
import TurnOver from "@/components/activities/TurnOver.vue";
import DailyJoke from "@/components/activities/DailyJoke.vue";
import { mapGetters } from "vuex";

export default {
  name: "ActivitiesComponent",
  components: { Discussion, TurnOver, DailyJoke },
  computed: {
    ...mapGetters({
      loggedInTV: "auth/loggedInTV",
    }),
  },
};
</script>

<style scoped lang="scss">
.activities {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 1fr);
  background-color: #f4f6fa;
  gap: 35px;
  padding: 110px 61px 40px 50px;
  box-shadow: 2px 2px 12px 0 rgb(0 0 0 / 5%);

  &.tv-mode {
    background-color: #e6efff;
  }

  .card {
    border-radius: 14px;
    background-color: #ffffff;
    padding: 24px 15px;
  }

  .card:nth-child(1) {
    grid-column: span 4;
    grid-row: span 6;
  }

  .card:nth-child(2) {
    grid-column: span 12;
    grid-row: span 5;
  }

  .card:nth-child(3) {
    grid-column: 5 / 17;
    grid-row: 6 / 7;
  }
}
</style>
