<template>
  <div class="dashboard">
    <ProductsJobsManage class="card"></ProductsJobsManage>
    <UserList class="card"></UserList>
  </div>
</template>

<script>
import ProductsJobsManage from "@/components/dashboard/ProductsJobsManage.vue";
import UserList from "@/components/dashboard/UserList.vue";
import { mapGetters } from "vuex";

export default {
  name: "DashboardComponent",
  components: { ProductsJobsManage, UserList },

  async created() {
    await this.$store.dispatch("user/getUsers");
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 1fr);
  background-color: #f4f6fa;
  gap: 35px;
  padding: 110px 61px 40px 50px;
  box-shadow: 2px 2px 12px 0 rgb(0 0 0 / 5%);

  .card {
    border-radius: 14px;
    background-color: #ffffff;
  }

  .card:nth-child(1) {
    grid-column: span 4;
    grid-row: span 6;
  }

  .card:nth-child(2) {
    grid-column: span 12;
    grid-row: span 6;
    padding: 24px;
  }
}
</style>
