<template>
  <ProjectTimelineCreation
    :currentPeriodIndex="currentPeriodIndex"
    :currentPeriod="period"
    :periods="periods"
    :error="checkError"
    :propal="isPropal"
    @addPeriod="addPeriod"
    @updatePeriod="updatePeriod"
    v-if="periods.length"
  />

  <div class="form">
    <div class="content">
      <button
        class="link-font"
        :class="{ visible: periods.length > 1 }"
        @click="deletePeriod()"
      >
        {{ $t("delete") }}
      </button>
      <div class="line">
        <SelectButtons
          :options="periodsEnums"
          :label="$t('projects.periodType')"
          v-model="period.type"
          :propal="isPropal"
        />
        <Input
          class="custom-name"
          :label="`${$t('name')} (${$t('optional')})`"
          :placeholder="$t('name')"
          v-model="period.name"
          :light="true"
          ref="periodTypeInput"
        />
      </div>
      <Date
        class="start-date"
        :label="
          period.type === 'Soutenance'
            ? $t('projects.date')
            : $t('projects.startDate')
        "
        v-model="period.startDate"
        :light="true"
        :required="true"
        :min="isPropal && previousPeriod ? previousPeriod.endDate : null"
        :max="
          isPropal && nextPeriod && period.type === 'Soutenance'
            ? nextPeriod.startDate
            : period.endDate
        "
        ref="startDateInput"
        @click="userChangeStartDate = true"
      />
      <Date
        class="end-date"
        :label="$t('projects.endDate')"
        v-model="period.endDate"
        :light="true"
        :required="true"
        ref="endDateInput"
        :min="period.startDate"
        :max="isPropal && nextPeriod ? nextPeriod.startDate : null"
        :class="{
          disabled: period.type === 'Propale' || period.type === 'Soutenance',
        }"
        @click="userChangeEndDate = true"
        v-if="period.type !== 'Soutenance'"
      />
      <div class="events" v-if="!isPropal">
        <label for="events" class="label">{{
          $t("projects.ponctualEvents")
        }}</label>
        <div
          class="line"
          v-for="(event, index) in period.events"
          :key="event.id"
        >
          <Select
            class="input event-name light"
            :placeholder="$t('projects.choseAEvent')"
            v-model="event.type"
            :light="true"
            :options="eventsEnums"
            :hasSearch="false"
          />
          <Date
            class="input date"
            v-model="event.date"
            :light="true"
            :required="!!event.type"
            ref="eventDateInput"
            :min="period.startDate"
            :max="period.endDate"
          />

          <Input
            class="custom-name"
            v-model="event.name"
            :light="true"
            ref="eventNameInput"
            :label="$t('projects.eventName')"
            v-if="event.type === 'Autre'"
          />
          <div
            class="delete"
            v-if="event.type && index !== period.events?.length - 1"
            @click="deleteEvent(event)"
          >
            <TrashIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import projectTypes from "@/enums/projectTypes.json";
import { Interval, DateTime } from "luxon";
import { createId } from "@/helpers/guid.js";

import ProjectTimelineCreation from "@/components/project/ProjectTimelineCreation.vue";
import Select from "@/components/inputs/Select.vue";
import Date from "@/components/inputs/Date.vue";
import Input from "@/components/inputs/Input.vue";
import SelectButtons from "@/components/inputs/SelectButtons.vue";

export default {
  name: "PlanningForm",
  components: {
    ProjectTimelineCreation,
    Select,
    Date,
    Input,
    SelectButtons,
  },
  props: {
    checkError: {
      type: Boolean,
    },
  },
  data() {
    return {
      projectTypes,
      period: { ...this.defaultPeriod },
      defaultPeriod: {
        name: "",
        type: "Sprint",
        events: [],
        startDate: "",
        endDate: "",
        id: createId(),
      },
      defaultEvent: {
        type: "",
        name: "",
        date: "",
        id: createId(),
      },
      currentPeriodIndex: 0,
      userChangeEndDate: false,
      userChangeStartDate: false,
    };
  },
  computed: {
    ...mapGetters({ project: "project/currentProject" }),
    periods() {
      return this.project.periods || [];
    },
    previousPeriod() {
      return this.currentPeriodIndex
        ? this.project.periods[this.currentPeriodIndex - 1]
        : null;
    },
    nextPeriod() {
      return this.currentPeriodIndex !== this.periods.length - 1
        ? this.project.periods[this.currentPeriodIndex + 1]
        : null;
    },
    periodsEnums() {
      return this.projectTypes.find((p) => p.type === this.project.type)
        .periods;
    },
    eventsEnums() {
      return this.projectTypes.find((p) => p.type === this.project.type).events;
    },
    currentPeriod() {
      return this.periods[this.currentPeriodIndex];
    },
    lastEvent() {
      return (
        this.currentPeriod?.events[this.currentPeriod?.events.length - 1]
          ?.type || null
      );
    },
    isPropal() {
      return this.project.type === 1;
    },
  },
  watch: {
    "period.id"() {
      this.userChangeEndDate = false;
      this.userChangeStartDate = false;

      if (!this.isPropal) {
        if (!this.period.startDate && this.currentPeriodIndex > 0) {
          this.period.startDate = this.addDaysToDate(
            this.periods[this.currentPeriodIndex - 1].endDate,
            1
          );
        }
        if (!this.period.endDate && this.currentPeriodIndex > 0) {
          const duration = this.getPeriodDuration(
            this.periods[this.currentPeriodIndex - 1].startDate,
            this.periods[this.currentPeriodIndex - 1].endDate
          );
          this.period.endDate = this.addDaysToDate(
            this.period.startDate,
            duration
          );
        }
        if (
          !this.period.startDate &&
          !this.currentPeriodIndex &&
          this.periods.length > 1
        ) {
          const duration = this.getPeriodDuration(
            this.periods[1].startDate,
            this.periods[1].endDate
          );
          this.period.startDate = this.removeDaysToDate(
            this.periods[1].startDate,
            duration + 1
          );
          this.period.endDate = this.addDaysToDate(
            this.period.startDate,
            duration
          );
        }
      }
    },
    "period.endDate"(value, oldValue) {
      if (!value || !oldValue || !this.userChangeEndDate) return;
      if (!this.isPropal) {
        if (DateTime.fromISO(value) > DateTime.fromISO(oldValue)) {
          const difference = this.getDateDifference(oldValue, value);

          this.periods
            .filter(
              (period, periodIndex) => periodIndex > this.currentPeriodIndex
            )
            .forEach((period) => {
              const duration = this.getPeriodDuration(
                period.startDate,
                period.endDate
              );
              period.startDate = this.addDaysToDate(
                period.startDate,
                difference
              );
              period.endDate = this.addDaysToDate(period.startDate, duration);
            });
        } else if (DateTime.fromISO(value) < DateTime.fromISO(oldValue)) {
          const difference = this.getDateDifference(value, oldValue);

          this.periods
            .filter(
              (period, periodIndex) => periodIndex > this.currentPeriodIndex
            )
            .forEach((period) => {
              const duration = this.getPeriodDuration(
                period.startDate,
                period.endDate
              );
              period.startDate = this.removeDaysToDate(
                period.startDate,
                difference
              );
              period.endDate = this.addDaysToDate(period.startDate, duration);
            });
        }
      }
    },
    "period.startDate"(value, oldValue) {
      if (!this.isPropal) {
        if (!value || !oldValue || !this.userChangeStartDate) return;
        if (DateTime.fromISO(value) > DateTime.fromISO(oldValue)) {
          const difference = this.getDateDifference(oldValue, value);

          this.periods
            .filter(
              (period, periodIndex) =>
                periodIndex < this.currentPeriodIndex &&
                projectTypes[1].periods.find((p) => p.name === period.type)
            )
            .forEach((period) => {
              const duration = this.getPeriodDuration(
                period.startDate,
                period.endDate
              );
              period.endDate = this.addDaysToDate(period.endDate, difference);
              period.startDate = this.removeDaysToDate(
                period.endDate,
                duration
              );
            });
        } else if (DateTime.fromISO(value) < DateTime.fromISO(oldValue)) {
          const difference = this.getDateDifference(value, oldValue);

          this.periods
            .filter(
              (period, periodIndex) =>
                periodIndex < this.currentPeriodIndex &&
                projectTypes[1].periods.find((p) => p.name === period.type)
            )
            .forEach((period) => {
              const duration = this.getPeriodDuration(
                period.startDate,
                period.endDate
              );
              period.endDate = this.removeDaysToDate(
                period.endDate,
                difference
              );
              period.startDate = this.removeDaysToDate(
                period.endDate,
                duration
              );
            });
        }
      }

      if (this.isPropal && this.period.type === "Soutenance") {
        this.period.endDate = this.period.startDate;
      }
    },
    lastEvent(value, oldValue) {
      if (value && !oldValue) {
        this.addEvent();
      }
    },
    checkError(error) {
      if (error) {
        this.check();
      }
    },
    isPropal() {
      this.setType();
    },
    "period.type"(type) {
      if (this.period.name !== type && type !== "Autre") {
        this.period.name = "";
      }
    },
    "project.type"(type, oldType) {
      if (type === 2 && this.$route.params.id) {
        this.addPeriod(this.periods.length);
      }
      if (type === 1 && oldType === 2 && this.$route.params.id) {
        this.project.periods = this.project.periods.filter((period) =>
          projectTypes[0].periods.find((p) => p.name === period.type)
        );
        this.currentPeriodIndex = this.project.periods.length - 1;
        this.period = this.project.periods[this.currentPeriodIndex];
      }
    },
    "project.id"() {
      if (!this.project) {
        this.period.type = this.defaultPeriod.type;

        this.periods.push({
          ...this.period,
          id: createId(),
        });
      }
      this.period = this.periods[0];

      this.setEvents();

      this.periods.forEach((period) => {
        if (period.name === period.type) {
          period.name = "";
        }
        period.id = createId();
      });
    },
  },
  created() {
    if (!this.$route.params.id || (this.$route.params.id && this.project.id)) {
      this.setType();
      this.period.type = this.defaultPeriod.type;

      if (!this.periods.length) {
        this.periods.push({
          ...this.period,
          id: createId(),
        });
      }
      this.period = this.periods[0];

      this.setEvents();

      this.periods.forEach((period) => {
        if (period.name === period.type) {
          period.name = "";
        }
        period.id = createId();
      });
    }
  },
  methods: {
    setType() {
      this.defaultPeriod.type = this.project.type === 1 ? "Propale" : "Sprint";
      if (this.$route.name === "Creation") {
        this.project.periods.forEach(
          (period) => (period.type = this.defaultPeriod.type)
        );
      }
    },
    addPeriod(index) {
      this.currentPeriodIndex = index;

      if (index < this.periods.length) {
        this.periods.splice(index, 0, {
          ...this.defaultPeriod,
          events: [{ ...this.defaultEvent }],
          id: createId(),
        });
        this.$nextTick(() => {
          this.setNextPeriodDate(index);
        });
      } else {
        this.periods.push({
          ...this.defaultPeriod,
          events: [{ ...this.defaultEvent }],
          id: createId(),
        });
      }

      this.period = this.periods[index];
    },
    updatePeriod(index) {
      this.currentPeriodIndex = index;
      this.period = this.periods[index];
      if (
        !this.period.events.length ||
        this.period.events[this.period.events.length - 1].name
      ) {
        this.setEvents();
      }
    },
    setEvents() {
      if (this.period.events) {
        this.addEvent();
      } else {
        this.period.events = [{ ...this.defaultEvent }];
      }
    },
    deletePeriod() {
      this.periods.splice(this.currentPeriodIndex, 1);
      this.currentPeriodIndex -= 1;

      this.$nextTick(() => {
        this.setNextPeriodDate(this.currentPeriodIndex);
      });
    },
    setNextPeriodDate(index) {
      this.periods.forEach((period, periodIndex) => {
        if (periodIndex > index) {
          const duration = this.getPeriodDuration(
            period.startDate,
            period.endDate
          );
          period.startDate = this.addDaysToDate(
            this.periods[periodIndex - 1].endDate,
            1
          );
          period.endDate = this.addDaysToDate(period.startDate, duration);
        }
      });
    },
    getPeriodDuration(start, end) {
      const duration = new Interval.fromDateTimes(
        DateTime.fromISO(start),
        DateTime.fromISO(end)
      ).length("days");
      return Number(duration);
    },
    getDateDifference(date1, date2) {
      const difference = new Interval.fromDateTimes(
        DateTime.fromISO(date1),
        DateTime.fromISO(date2)
      ).length("days");
      return Number(difference);
    },
    addDaysToDate(date, days) {
      return DateTime.fromISO(date).plus({ days: days }).toISO();
    },
    removeDaysToDate(date, days) {
      return DateTime.fromISO(date).minus({ days: days }).toISO();
    },
    addEvent() {
      this.currentPeriod.events.push({
        ...this.defaultEvent,
        id: createId(),
      });
    },
    deleteEvent(deletedEvent) {
      this.currentPeriod.events = this.currentPeriod.events.filter(
        (event) => event.id !== deletedEvent.id
      );
    },
    check() {
      const inputs = ["periodTypeInput", "startDateInput", "endDateInput"];
      inputs.forEach((input) => this.$refs[input]?.check());
      this.$refs.eventDateInput?.forEach((input) => input.check());
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  position: relative;
  padding: 40px 0 0;
  background-color: mix($primary-color, $white-color, 5%);
  margin: 24px 0 80px;

  &::before {
    content: "";
    position: absolute;
    left: calc((100% - 30px) / 2);
    top: -15px;
    width: 30px;
    height: 30px;
    background: mix($primary-color, $white-color, 5%);
    transform: rotate(45deg);
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 20px;
  }

  .link-font {
    color: $red-color;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 20px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;

    &.visible {
      opacity: 1;
      pointer-events: initial;
    }
  }

  .period-label {
    color: $secondary-color;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    width: 100%;
  }

  .name-label {
    font-size: 11px;
    margin-right: 14px;
    font-weight: 600;
  }

  :deep(.custom-name) {
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-top: 26px;

    .label {
      width: fit-content;
      flex-shrink: 0;
      margin: 0 10px 0 0;
      text-transform: capitalize;
      font-weight: $font-regular;
    }

    .error-message {
      left: unset;
      right: 40px;
    }
  }

  .label {
    color: $secondary-color;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.1;
    font-weight: $font-bold;
    margin-bottom: 10px;
  }

  .events {
    display: flex;
    flex-direction: column;
    width: 100%;

    .line {
      align-items: flex-start;

      :deep(.custom-name) {
        margin: 0;
      }
    }

    .event-name {
      width: 48%;
    }
  }

  .line {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: -10px;

    .delete {
      width: 48px;
      height: 48px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-color;
      cursor: pointer;
      transition: 0.3s;

      svg {
        width: 48px;
      }

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .textarea,
  .line {
    width: 100%;
  }
}
</style>
