<template>
  <div class="login view">
    <LoginBackground />
    <div class="login-form">
      <MiruIcon class="logo" />
      <GoogleSignIn :callback="callback" />
    </div>
  </div>
</template>

<script>
import LoginBackground from "@/components/LoginBackground.vue";

export default {
  name: "LoginComponent",
  components: {
    LoginBackground,
  },
  data() {
    return {
      loadingGoogleLogin: false,
      isInit: false,
      isSignIn: false,
    };
  },
  methods: {
    async callback(response) {
      await this.$store.dispatch("auth/loginWithGoogle", {
        token: response.credential,
      });
      if (this.$route.query.redirectUrl) {
        this.$router.replace(this.$route.query.redirectUrl);
      } else {
        this.$router.replace({ name: "Timeline" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #031e41;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  background-color: $white-color;
  position: fixed;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  width: 90%;
  max-width: 550px;
}

.logo {
  margin-bottom: 64px;
  width: 168px;
}

.google-btn {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: $white-color;
  width: 100%;
  border-radius: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  transition: 0.3s;

  &::before {
    content: "";
    position: absolute;
    left: 16px;
    height: 26px;
    aspect-ratio: 1/1;
    background-image: url("~@/assets/googleLogo.png");
    background-size: contain;
  }

  &:hover {
    color: $secondary-color;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  }

  &.loading {
    &::before {
      animation-name: rotate;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }
}

@media (min-width: $layout-breakpoint-large) {
  .login-form {
    padding: 60px 40px;
  }
}
</style>
