export default {
  users: ({ users }) => users,
  usersDeleted: ({ usersDeleted }) => usersDeleted,
  userHexColor:
    ({ businessRoles }) =>
    (user) => {
      const role = businessRoles.find((r) => r.name === user?.businessRole);
      if (role) {
        return `0x${role.color.split("#")[1]}`;
      } else {
        return null;
      }
    },
  businessRoles: ({ businessRoles }) => businessRoles,
};
