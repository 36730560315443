import { Component } from "../component";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Texture } from "@pixi/core";
import { BitmapText } from "@pixi/text-bitmap";
import { RoundedRectangle } from "./RoundedRectangle";
import gsap from "gsap";
import projectTypes from "@/enums/projectTypes";

import Data from "./data";

export class ProjectSprint extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    const { dayInMs } = Data;

    this.sprint = this.props.sprint;
    this.project = this.props.project;
    this.projectsPeriods = this.project.periods.filter((period) =>
      projectTypes[1].periods.find((p) => p.name === period.type)
    );
    this.sprintIndex = this.props.sprintIndex;
    this.projectStart = this.props.projectStart;
    this.projectColor = this.props.projectColor;

    this.startDate =
      new Date(this.sprint.startDate).getTime() || new Date().getTime();
    this.endDate =
      new Date(this.sprint.endDate).getTime() || new Date().getTime();

    this.container = new Container();
    this.container.y = 42;
    this.container.sortableChildren = true;

    this.sprintDurationInDays = (this.endDate - this.startDate) / dayInMs;

    if (
      this.projectsPeriods.length &&
      this.sprint.startDate !== this.projectsPeriods[0].startDate
    ) {
      this.createSprintBorder();
    }
    this.createSprintName(2);
    this.createSprintName(3);
    this.createShortSprintName();
    this.createSprintEvents();
  }

  createSprintBorder() {
    const { container } = this;

    this.projectSprintBorder = new Sprite(Texture.WHITE);
    this.projectSprintBorder.width = 2;
    this.projectSprintBorder.height = 48;
    this.projectSprintBorder.tint = `0x${this.projectColor}`;

    this.optimizeObject(this.projectSprintBorder);

    container.addChild(this.projectSprintBorder);
  }

  createSprintName(zoomLevel) {
    const { container } = this;

    this.sprintNumber =
      this.sprint.type === "Sprint" && this.sprint.name === this.sprint.type
        ? this.project.periods
            .filter((p) => p.type === "Sprint")
            .findIndex((p) => p.startDate === this.sprint.startDate) + 1
        : "";

    this[`projectSprintName${zoomLevel}`] = new BitmapText(
      `${this.sprint.name} ${this.sprintNumber}`,
      {
        fontName: "Montserrat-Regular",
        fontSize: 14,
        align: "center",
        tint: `0x${this.projectColor}`,
      }
    );
    this[`projectSprintName${zoomLevel}`].anchor.set(0.0, 0.5);
    this[`projectSprintName${zoomLevel}`].position.y = 21;
    this[`projectSprintName${zoomLevel}`].meta = {
      zoomLevel: zoomLevel,
    };

    this.optimizeObject(this[`projectSprintName${zoomLevel}`]);

    container.addChild(this[`projectSprintName${zoomLevel}`]);
  }

  createShortSprintName() {
    const { container } = this;

    this.sprintNumber =
      this.sprint.type === "Sprint" && this.sprint.name === this.sprint.type
        ? this.project.periods
            .filter((p) => p.type === "Sprint")
            .findIndex((p) => p.startDate === this.sprint.startDate) + 1
        : "";
    this.projectShortSprintName = new BitmapText(
      `${this.sprint.name[0]}${this.sprintNumber}`,
      {
        fontName: "Montserrat-Regular",
        fontSize: 14,
        align: "center",
        tint: `0x${this.projectColor}`,
      }
    );
    this.projectShortSprintName.anchor.set(0.0, 0.5);
    this.projectShortSprintName.position.y = 21;
    this.projectShortSprintName.position.y = 21;
    this.projectShortSprintName.meta = {
      zoomLevel: 1,
    };

    this.optimizeObject(this.projectShortSprintName);

    container.addChild(this.projectShortSprintName);
  }

  refreshSprintPosition() {
    const { dayWidth, dayInMs } = Data;
    const sprintWidth = this.sprintDurationInDays * dayWidth + dayWidth;

    this.container.x =
      ((this.startDate - this.projectStart) / dayInMs) * dayWidth;
    this.projectSprintName2.x =
      (sprintWidth - this.projectSprintName2.width) / 2;
    this.projectSprintName3.x =
      (sprintWidth - this.projectSprintName3.width) / 2;
    this.projectShortSprintName.x =
      (sprintWidth - this.projectShortSprintName.width) / 2;
  }

  refreshEventsPosition() {
    const { dayWidth, dayInMs } = Data;

    this.sprint.events.forEach((event, index) => {
      const eventDate = new Date(event.date).getTime();

      this.sprintEventBorders[index].x =
        ((eventDate - this.startDate) / dayInMs) * dayWidth;
      this.sprintEventSprites[index].x =
        ((eventDate - this.startDate) / dayInMs) * dayWidth;
      this.sprintEventSprites[index].refresh();
      this.sprintEventNames[index].x =
        ((eventDate - this.startDate) / dayInMs) * dayWidth + 10;
    });
  }

  createSprintEvents() {
    this.sprintEventBorders = [];
    this.sprintEventSprites = [];
    this.sprintEventNames = [];

    this.sprint.events.forEach((event, index) => {
      const eventSpriteBorder = new Sprite(Texture.WHITE);
      eventSpriteBorder.width = 2;
      eventSpriteBorder.height = 48;
      eventSpriteBorder.tint = `0x${this.projectColor}`;
      eventSpriteBorder.zIndex = 0;

      eventSpriteBorder.visible =
        new Date(event.date).setHours(0, 0, 0, 0) !==
        new Date(this.projectsPeriods[0].startDate).setHours(0, 0, 0, 0);
      this.sprintEventBorders.push(eventSpriteBorder);

      const eventSprite = new RoundedRectangle({
        x: 0,
        y: 9,
        width: 30,
        height: 30,
        uColor: `0x${this.projectColor}`,
        uRadius: [0.5, 0.5, 0.0, 0.0],
        uOpacity: 0.5,
      });

      this.onEventHover = this.onEventHover.bind(this);
      this.onEventLeave = this.onEventLeave.bind(this);
      eventSprite.mesh.interactive = true;
      eventSprite.mesh.zIndex = 2;
      eventSprite.mesh.on("mouseover", (e) => this.onEventHover(e, index));
      eventSprite.mesh.on("mouseout", (e) => this.onEventLeave(e, index));

      this.sprintEventSprites.push(eventSprite);

      const eventName = new BitmapText(`${event.name}`, {
        fontName: "Montserrat-Regular",
        fontSize: 24,
        tint: 0xffffff,
        align: "center",
      });
      eventName.y = 14;
      eventName.scale.set(0.6);
      eventName.alpha = 0;
      eventName.zIndex = 10;

      this.sprintEventNames.push(eventName);

      this.container.addChild(eventSpriteBorder);
      this.container.addChild(eventSprite.mesh);
      this.container.addChild(eventName);
    });
  }

  onEventHover(e, index) {
    gsap.to(this.sprintEventSprites[index], {
      width: this.sprintEventNames[index].width + 20,
      uOpacity: 1,
      ease: "expo.out",
      duration: 0.628,
      onUpdate: () => {
        this.sprintEventSprites[index].refresh();
        this.sprintEventSprites[index].uniforms.uOpacity =
          this.sprintEventSprites[index].uOpacity;
      },
    });
    gsap.to(this.sprintEventNames[index], {
      alpha: 1,
      ease: "expo.out",
      duration: 0.628,
    });
  }

  onEventLeave(e, index) {
    gsap.to(this.sprintEventSprites[index], {
      width: 30,
      uOpacity: 0.5,
      ease: "expo.out",
      duration: 0.628,
      onUpdate: () => {
        this.sprintEventSprites[index].refresh();
        this.sprintEventSprites[index].uniforms.uOpacity =
          this.sprintEventSprites[index].uOpacity;
      },
    });
    gsap.to(this.sprintEventNames[index], {
      alpha: 0,
      ease: "expo.out",
      duration: 0.628,
    });
  }

  refresh() {
    this.refreshSprintPosition();
    this.refreshEventsPosition();
  }
}
