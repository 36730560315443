<template>
  <div class="remote-timeline">
    <Projects :projects="futureProjects" :title="$t('future')" />
    <Projects :projects="currentProjects" :title="$t('onGoing')" />
    <Projects :projects="passedProjects" :title="$t('passed')" />
    <Controls />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getTimeBeforeProjectEnd,
  getTimeBeforeProjectStart,
} from "@/helpers/date.js";

import Projects from "@/components/remote/Projects";
import Controls from "@/components/remote/Controls";

export default {
  name: "RemoteTimeLineControls",
  components: {
    Projects,
    Controls,
  },
  computed: {
    ...mapGetters({ projects: "project/projects" }),
    passedProjects() {
      return this.projects
        .filter(
          (project) =>
            getTimeBeforeProjectEnd(project) < 0 &&
            getTimeBeforeProjectStart(project) < 0
        )
        .sort(
          (p1, p2) => getTimeBeforeProjectEnd(p2) - getTimeBeforeProjectEnd(p1)
        );
    },
    futureProjects() {
      return this.projects
        .filter(
          (project) =>
            getTimeBeforeProjectEnd(project) >= 0 &&
            getTimeBeforeProjectStart(project) > 0
        )
        .sort(
          (p1, p2) => getTimeBeforeProjectEnd(p2) - getTimeBeforeProjectEnd(p1)
        );
    },
    currentProjects() {
      return this.projects
        .filter(
          (project) =>
            getTimeBeforeProjectEnd(project) >= 0 &&
            getTimeBeforeProjectStart(project) <= 0
        )
        .sort(
          (p1, p2) =>
            getTimeBeforeProjectEnd(p2) - getTimeBeforeProjectEnd(p1) &&
            p2.type - p1.type
        );
    },
  },
  async created() {
    await this.$store.dispatch("project/getProjects");
    await this.$store.dispatch("user/getUsers");
    await this.$store.dispatch("project/getClients");
  },
};
</script>

<style lang="scss" scoped>
.remote-timeline {
  padding-bottom: 200px;
}
</style>
