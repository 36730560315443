<template>
  <div class="jobs" ref="jobs">
    <div
      v-for="(businessRole, index) in businessRolesSort"
      :key="index"
      class="job-container"
    >
      <div class="job-infos">
        <div
          class="job-color"
          :style="{
            backgroundColor: businessRole.color,
            boxShadow: `${businessRole.color}10 0px 0px 0px 4px`,
          }"
        ></div>
        <p class="job-name" :style="{ color: businessRole.color }">
          {{ businessRole.name }}
        </p>
      </div>
      <div class="job-delete" @click="deleteBusinessRole(businessRole)">
        <TrashIcon />
      </div>
    </div>

    <div class="creation-container" v-if="creation">
      <div
        class="color-picker"
        :style="{
          background: color || '#79adf9',
        }"
        @click.stop="colorsOpen = !colorsOpen"
      >
        <span v-if="!color"><Plus /></span>
      </div>
      <ColorPicker
        v-model="color"
        class="color-choice"
        v-if="colorsOpen"
        :style="{ maxWidth: `${$refs.jobs.clientWidth - 40}px` }"
      />
      <Input
        class="custom-name"
        v-model="businessRoleName"
        :placeholder="$t('name')"
        ref="periodTypeInput"
        @blur="createBusinessRole"
      />
    </div>
    <div class="creation-button" @click="creation = true"><AddIcon /></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/inputs/Input.vue";
import ColorPicker from "@/components/inputs/ColorPicker";

export default {
  name: "JobsList",
  components: { Input, ColorPicker },

  data() {
    return {
      creation: false,
      color: "",
      businessRoleName: "",
      colorsOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      businessRoles: "user/businessRoles",
    }),

    businessRolesSort() {
      return [...this.businessRoles].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else return 1;
      });
    },
  },

  watch: {
    color() {
      this.colorsOpen = false;
      this.createBusinessRole();
    },
  },

  methods: {
    createBusinessRole() {
      if (this.color !== "" && this.businessRoleName !== "") {
        this.$store.dispatch("user/createBusinessRoles", {
          name: this.businessRoleName,
          color: this.color,
        });
        this.creation = false;
        this.color = "";
        this.businessRoleName = "";
      }
    },
    async deleteBusinessRole(businessRole) {
      const response = await this.$store.dispatch("modals/setModal", {
        modalType: "ConfirmationModal",
        modalData: {
          header: { ...businessRole, headerType: "businessRole" },
          dangerousAction: true,
          title: this.$t("deleteBusinessRoleModal.title"),
          text: this.$t("deleteBusinessRoleModal.text"),
          button: this.$t("deleteBusinessRoleModal.button"),
        },
      });

      if (response) {
        this.$store.dispatch("user/deleteBusinessRole", businessRole.name);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.jobs {
  padding: 26px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .job-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .job-infos {
    display: flex;
    align-items: center;
  }

  .job-color {
    width: 18px;
    height: 18px;
    border-radius: 6px;
  }

  .job-name {
    margin-left: 25px;
    line-height: 18px;
    font-weight: 600;
  }

  .job-delete {
    color: $red-color;
    width: 40px;
    cursor: pointer;
  }

  .creation-container {
    position: relative;
    display: flex;
    gap: 25px;
    align-items: center;

    .color-picker {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      cursor: pointer;
      transition: 0.3s;

      svg {
        width: 10px;
        color: $white-color;
      }

      &:hover {
        box-shadow: 0 0 0 4px rgba($primary-color, 0.07);
      }
    }

    .color-choice {
      top: -125px;
      right: initial;
    }

    :deep(.custom-name) {
      margin: 0;
      padding: 0;
    }
  }
  .creation-button {
    border-radius: 6px;
    background-color: rgba(193, 218, 255, 0.37);
    cursor: pointer;
    text-align: center;
    padding: 16px;
    margin-top: 12px;

    svg {
      color: $primary-color;
      width: 10px;
    }
  }
}
</style>
