export default {
  async fetch({ state }) {
    if (!state.loading) {
      try {
        const response = await this.$axios.$get("Financials");
        state.data = response.financialStats;
      } catch (e) {
        return e;
      }
    }
  },

  async fetchStatistics({ state }, { type }) {
    if (!state.loading) {
      try {
        const response = await this.$axios.$get("Projects/stats", {
          params: {
            type,
          },
        });
        state.statistics = response;
      } catch (e) {
        return e;
      }
    }
  },
};
