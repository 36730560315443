<template>
  <div class="view creation">
    <div class="content">
      <button class="link-font" @click="cancelEdition()">
        {{ $t("projects.cancelEdition") }}
      </button>
      <div class="line">
        <div class="title">
          <h1 class="title-1" :style="{ color: project.color }">
            {{ project.clientName }}
          </h1>
          <h2 class="title-2" :style="{ color: project.color }">
            {{ project.name }}
          </h2>
        </div>
        <div class="switch-button" v-if="oldType === 1">
          <label class="label" :class="{ selected: project.type === 1 }">
            {{ $t("projects.transformPropalToProduct") }}</label
          >
          <div
            class="switch"
            @click="project.type = project.type === 1 ? 2 : 1"
            :class="{ product: project.type === 2 }"
          >
            <div class="cursor"></div>
          </div>
        </div>
      </div>
    </div>
    <PlanningForm :checkError="checkError" />
    <div class="content">
      <MainInformationForm :checkError="checkError" />
      <AssetsForm />
      <button class="primary-button" @click="updateProject">
        {{ $t("save") }}
      </button>
      <button class="link-font delete" @click="deleteProject">
        {{
          project.type === 1
            ? $t("projects.deletePropal")
            : $t("projects.deleteProject")
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AssetsForm from "@/components/project/AssetsForm.vue";
import PlanningForm from "@/components/project/PlanningForm.vue";
import MainInformationForm from "@/components/project/MainInformationForm";

export default {
  name: "CreationComponent",
  components: {
    AssetsForm,
    MainInformationForm,
    PlanningForm,
  },
  data() {
    return {
      checkError: false,
      oldType: 1,
    };
  },
  computed: {
    ...mapGetters({
      project: "project/currentProject",
    }),
  },
  async created() {
    if (!this.project.id) {
      const project = await this.$store.dispatch("project/getProject", {
        projectId: this.$route.params.id,
      });
      await this.$store.dispatch("project/setProject", project);
    }
    this.oldType = this.project.type;

    this.project.referrer = {
      id: this.project.referrer.id,
      role: this.project.referrer.businessRole,
    };
    this.project.members = this.project.members.map((member) => ({
      id: member.id,
      role: member.businessRole,
    }));
  },
  methods: {
    cancelEdition() {
      this.$router.push({ name: "Timeline" });
    },
    async updateProject() {
      if (this.checkError) return;
      this.checkError = true;
      this.$nextTick(async () => {
        if (document.querySelector(".error")) {
          document
            .querySelector(".error")
            .scrollIntoView({ behavior: "smooth" });
          this.checkError = false;
          return;
        }

        if (this.project.type !== this.oldType) {
          const response = await this.$store.dispatch("modals/setModal", {
            modalType: "ConfirmationModal",
            modalData: {
              title: this.$t("updateProjectTypeModal.title"),
              text: this.$t("updateProjectTypeModal.text"),
              button: this.$t("updateProjectTypeModal.button"),
            },
          });
          if (!response) return;
        }

        await this.$store.dispatch("project/updateProject");
        this.$router.push({ name: "Timeline" });
      });
    },
    async deleteProject() {
      const response = await this.$store.dispatch("modals/setModal", {
        modalType: "ConfirmationModal",
        modalData: {
          dangerousAction: true,
          title:
            this.project.type === 1
              ? this.$t("deleteProjectModal.titlePropal")
              : this.$t("deleteProjectModal.title"),
          text:
            this.project.type === 1
              ? this.$t("deleteProjectModal.textPropal")
              : this.$t("deleteProjectModal.text"),
          button: this.$t("deleteProjectModal.button"),
        },
      });

      if (response) {
        this.$store.dispatch("project/deleteProject", this.project.id);
        this.$router.push({ name: "Timeline" });
      }
    },
  },
  unmounted() {
    this.$store.dispatch("project/resetProject");
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title-1,
    .title-2 {
      text-align: left;
      align-self: flex-start;
    }

    .title-2 {
      margin-bottom: 50px;
    }
  }

  .primary-button {
    margin-top: 70px;
  }

  .link-font {
    align-self: flex-start;
    margin-bottom: 24px;

    &.delete {
      color: $red-color;
      align-self: center;
      margin-top: 50px;
    }
  }

  .switch-button {
    display: flex;
    align-items: center;
    align-self: flex-start;

    .label {
      font-size: 16px;
      font-weight: 500;
      color: $secondary-color;

      &.product.selected {
        color: $primary-color;
      }
    }

    .switch {
      position: relative;
      display: flex;
      align-items: center;
      width: 64px;
      height: 24px;
      border-radius: 12px;
      background: mix($secondary-color, $white-color, 20%);
      margin-left: 30px;
      $cursor-width: 32px;
      cursor: pointer;

      .cursor {
        position: absolute;
        left: 0;
        width: $cursor-width;
        height: $cursor-width;
        border-radius: 50%;
        background: $secondary-color;
        box-shadow: 1px 2px 5px 0 rgba($secondary-color, 0.3);
        transition: 0.3s ease-in-out;
      }

      &.product {
        background: mix($primary-color, $white-color, 20%);

        .cursor {
          left: calc(100% - #{$cursor-width});
          background: $primary-color;
          box-shadow: 1px 2px 5px 0 rgba($primary-color, 0.3);
        }
      }
    }
  }
}
</style>
