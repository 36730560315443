<template>
  <div class="form">
    <Input
      class="form-input"
      :label="$t('projects.projectName')"
      :placeholder="$t('projects.projectName')"
      v-model="project.name"
      :required="true"
      :maxLength="56"
      ref="projectNameInput"
    />
    <Select
      class="form-input color"
      type="color"
      :label="$t('projects.client')"
      :placeholder="$t('projects.clientName')"
      v-model="project.clientName"
      :colorValue="project.color"
      @updateColor="updateColor"
      :options="clients"
      :required="true"
      :hasSearch="true"
      ref="clientNameInput"
    />
    <Input
      class="form-input"
      type="number"
      :label="$t('projects.sprintNumber')"
      :placeholder="$t('projects.sprintNumber')"
      v-model="project.sprintTotalCount"
      :required="true"
      ref="sprintNumberInput"
      v-if="isPropal"
    />
    <Input
      class="form-input"
      type="number"
      :label="$t('projects.estimateAmount')"
      :placeholder="$t('projects.estimateAmount')"
      v-model="project.price"
      :required="true"
      ref="estimateAmountInput"
      v-if="isPropal"
    />
    <div
      class="team-input"
      v-clickout="
        () => (
          (selectReferrer = false),
          (project.referrer = project.referrer.role
            ? project.referrer
            : { id: '', role: '' })
        )
      "
    >
      <label>{{ $t("projects.projectReferrer") }}</label>
      <TeamMember
        :userId="project.referrer.id"
        :hoverDisabled="true"
        :role="project.referrer.role"
        v-if="project.referrer.role || selectReferrer"
        @deleteTeamMember="deletereferrer"
        :edition="true"
      />
      <div
        class="add"
        @click.stop="selectReferrer = true"
        v-else
        :class="{ error: error && !project.referrer.id }"
      >
        <AddIcon />
      </div>
      <div class="error-message" v-if="error && !project.referrer.id">
        <ErrorIcon />{{ $t("projects.youMustFirstChooseAReferrer") }}
      </div>

      <TeamMemberSelect
        :currentUserId="project.referrer.id"
        :currentRole="project.referrer.role"
        @updateUser="(value) => (project.referrer.id = value)"
        @updateRole="
          (value) => ((project.referrer.role = value), (selectReferrer = false))
        "
        :referrerSelect="true"
        v-if="selectReferrer"
      />
    </div>
    <div class="team-input" v-clickout="() => closeTeamInput()">
      <label>{{ $t("projects.team") }}</label>
      <TeamMember
        v-for="member in project.members"
        :key="member.id"
        :userId="member.id"
        :role="member.role"
        :hoverDisabled="true"
        @deleteTeamMember="deleteTeamMember"
        :edition="true"
      />
      <div
        class="add"
        @click.stop="addTeamMember(this.project)"
        :class="{ error: error && !project.members.length }"
      >
        <Plus />
      </div>
      <div class="error-message" v-if="error && !project.members.length">
        <Cross />{{ $t("projects.youMustFirstChooseAMember") }}
      </div>
      <TeamMemberSelect
        :users="users"
        :currentUserId="updatedMember.id"
        :currentRole="updatedMember.role"
        @updateUser="(value) => (updatedMember.id = value)"
        @updateRole="
          (value) => ((updatedMember.role = value), (selectMember = false))
        "
        v-if="selectMember"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createId } from "@/helpers/guid.js";

import Input from "@/components/inputs/Input";
import Select from "@/components/inputs/Select.vue";
import TeamMember from "@/components/TeamMember.vue";
import TeamMemberSelect from "@/components/inputs/TeamMemberSelect.vue";

export default {
  name: "MainInformationForm",
  props: {
    checkError: {
      type: Boolean,
    },
  },
  components: {
    Input,
    Select,
    TeamMember,
    TeamMemberSelect,
  },
  data() {
    return {
      selectReferrer: false,
      selectMember: false,
      updatedMember: { role: "", id: null },
      error: false,
    };
  },
  computed: {
    ...mapGetters({
      users: "user/users",
      businessRoles: "user/businessRoles",
      clients: "project/clients",
      project: "project/currentProject",
    }),
    isPropal() {
      return this.project.type === 1;
    },
  },
  watch: {
    checkError(error) {
      if (error) {
        this.check();
        this.error = true;
      }
    },
  },
  methods: {
    updateColor(color) {
      this.project.color = color;
    },
    addTeamMember() {
      this.project.members.push({
        role: "",
        id: createId(),
      });
      this.selectMember = true;
      this.updatedMember =
        this.project.members[this.project.members.length - 1];
    },
    deleteTeamMember(user) {
      this.project.members = this.project.members.filter(
        (member) => member.id !== user.id
      );
    },
    deletereferrer() {
      this.project.referrer = {
        role: "",
        id: createId(),
      };
    },
    closeTeamInput() {
      this.selectMember = false;
      this.project.members = this.project.members.filter(
        (member) => member.id && member.role
      );
    },
    check() {
      const inputs = [
        "projectNameInput",
        "clientNameInput",
        "sprintNumberInput",
        "estimateAmountInput",
      ];

      inputs.forEach((input) => this.$refs[input]?.check());
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  label {
    color: $secondary-color;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    width: 100%;
  }
}

.team-input {
  width: 45%;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;

  .error-message {
    top: 100%;
    margin-top: 10px;
  }
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: rgba($primary-color, 0.5);
  color: $white-color;
  transition: 0.3s;
  cursor: pointer;
  svg {
    width: 18px;
  }

  &:hover {
    box-shadow: 0 0 0 4px rgba($primary-color, 0.07);
  }

  &.error {
    background: $red-color;
  }
}

.form-input {
  width: 45%;
  margin-bottom: 40px;
}
</style>
