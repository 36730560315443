<template>
  <svg viewBox="0 0 44 44">
    <title>icon_dropdown</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-249.000000, -3.000000)">
        <g id="icon_dropdown" transform="translate(249.000000, 3.000000)">
          <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
          <path
            d="M29.1436781,19.5530575 L22.4329466,26.6471335 C22.4131031,26.6671918 22.3813451,26.6671918 22.3615016,26.6471335 L15.5946347,19.4109655 C15.5813934,19.3947706 15.5777873,19.3724107 15.5852324,19.3526678 C15.5926776,19.3329248 15.6099851,19.3189521 15.6303572,19.3162375 L25.2039939,19.3162375 C25.5562976,19.3162375 25.8418961,19.0217154 25.8418961,18.6584041 L25.8418961,18.6584041 C25.8418961,18.2950927 25.5562976,18.0005706 25.2039939,18.0005706 L15.5946347,18.0005706 C15.1660637,17.9880326 14.7564232,18.1827052 14.4872364,18.5268374 C14.1123231,19.044463 14.1555008,19.7657964 14.5893007,20.2319417 L21.4786449,27.5996763 C21.7198275,27.8556013 22.0511636,28 22.3972241,28 C22.7432846,28 23.0746206,27.8556013 23.3158033,27.5996763 L30.0520509,20.463499 C30.2271026,20.2777876 30.2801979,20.0034362 30.1876851,19.7626565 C30.0951724,19.5218768 29.8742701,19.3594826 29.6233806,19.3478135 L29.6233806,19.3478135 C29.4423677,19.3422922 29.2677346,19.4170102 29.1436781,19.5530575 Z"
            id="Path"
            fill="CurrentColor"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
