import getters from "./getters";
import actions from "./actions";

export let state = {
  loggedIn: false,
  accessToken: undefined,
  refreshToken: undefined,
  user: {},
  tvUser: {},
  qrCodeUrl: null,
  loggedInTV: false,
  tokenRefresher: {
    isLoading: false,
    promise: null,
  },
};
const namespaced = true;

export default {
  state,
  getters,
  actions,
  namespaced,
};
