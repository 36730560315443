<template>
  <div class="periods-timeline">
    <div class="scroller" ref="scroller">
      <div
        class="add-period"
        :class="{
          visible: periods.length > 1,
          propal: isPropalPeriod(periods[0]),
          disabled: isPropalPeriod(periods[0]) && !propal,
        }"
        @click="addPeriod(0)"
      >
        <Plus />
      </div>
      <div
        class="period-container"
        v-for="(period, periodIndex) in periods"
        :key="period.id"
        :class="{
          propal: isPropalPeriod(period),
          disabled: isPropalPeriod(period) && !propal,
        }"
      >
        <div
          class="period"
          @click="updatePeriod(periodIndex)"
          :class="{
            error: error && checkPeriodError(period),
            current: periodIndex === currentPeriodIndex,
          }"
        >
          <div
            class="start-date date"
            :class="{ centered: period.startDate === period.endDate }"
          >
            {{
              period.startDate
                ? new Date(period.startDate).toLocaleDateString("FR-fr", {
                    day: "numeric",
                    month: "numeric",
                  })
                : "00/00"
            }}
          </div>
          <div class="end-date date" v-if="period.startDate !== period.endDate">
            {{
              period.endDate
                ? new Date(period.endDate).toLocaleDateString("FR-fr", {
                    day: "numeric",
                    month: "numeric",
                  })
                : "00/00"
            }}
          </div>
          <span> {{ period.name || period.type }}</span>
          <span>{{ getPeriodSprintIndex(period) }}</span>
        </div>
        <div
          class="add-period"
          :class="{
            visible:
              periodIndex === currentPeriodIndex ||
              periodIndex === currentPeriodIndex - 1 ||
              periodIndex === periods.length - 1,
          }"
          @click="addPeriod(periodIndex + 1)"
        >
          <AddIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import projectTypes from "@/enums/projectTypes.json";

export default {
  name: "ProjectTimelineCreation",
  props: {
    periods: {
      type: Array,
      default: () => {
        return [];
      },
    },
    currentPeriodIndex: {
      type: Number,
    },
    currentPeriod: {
      type: Object,
    },
    error: {
      type: Boolean,
    },
    propal: {
      type: Boolean,
    },
  },
  emits: ["addPeriod", "updatePeriod", "updateTimelineHover"],
  watch: {
    currentPeriodIndex() {
      this.$nextTick(() => {
        this.scrollToCurrentPeriod();
      });
    },
    "periods.length"() {
      this.$nextTick(() => {
        this.scrollToCurrentPeriod();
      });
    },
  },
  mounted() {
    this.scrollToCurrentPeriod(0);
  },
  methods: {
    addPeriod(index) {
      this.$emit("addPeriod", index);
    },
    updatePeriod(index) {
      this.$emit("updatePeriod", index);
    },
    scrollToCurrentPeriod(duration = 0.5) {
      const period =
        document.querySelectorAll(".period")[this.currentPeriodIndex];
      gsap.to(this.$refs.scroller, {
        duration,
        ease: "ease-in-out",
        scrollTo: {
          x: period,
          offsetX: (window.innerWidth - period.clientWidth) * 0.5,
        },
      });
    },
    getPeriodSprintIndex(period) {
      return period.type === "Sprint" && !period.name
        ? this.periods
            .filter((p) => p.type === "Sprint")
            .findIndex((p) => p.id === period.id) + 1
        : "";
    },
    checkPeriodError(period) {
      return (
        !period.type ||
        !period.startDate ||
        !period.endDate ||
        period.events.find((ev) => ev.type && !ev.date)
      );
    },
    isPropalPeriod(period) {
      return projectTypes[0].periods.find((p) => p.name === period.type);
    },
  },
};
</script>

<style scoped lang="scss">
.periods-timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100vw;
  overflow: hidden;

  .scroller {
    @include hideScrollbar;
    display: flex;
    align-items: center;
    overflow: auto;
    width: 100vw;
    scroll-snap-type: x mandatory;
    padding: 24px 0;
  }
}

.period-container {
  display: flex;
  align-items: center;
  position: relative;

  &.disabled {
    pointer-events: none;

    .add-period {
      opacity: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: $light-grey-color;
    z-index: -1;
  }

  &:last-child {
    margin-right: 50vw;
    &::before {
      right: 24px;
    }
  }

  &.propal {
    &::before {
      display: none;
    }

    .period {
      height: 45px;
      border-radius: 25px;
      background: mix($secondary-color, $white-color, 10%);
      border: 4px solid mix($secondary-color, $white-color, 30%);
      color: $secondary-color;
      box-shadow: none;

      &.current {
        height: 53px;
        border-radius: 25px;
        background: $secondary-color;
        border: 4px solid $white-color;
        color: $white-color;

        .date {
          top: -24px;
        }
      }
    }

    &:last-child {
      .add-period {
        &::after {
          display: none;
        }
      }
    }

    .add-period {
      border-radius: 50%;
      margin: 0 38px;
      opacity: 1;
      transform: scale(1);
      width: 25px;
      height: 25px;

      &::before {
        content: "";
        position: absolute;
        left: -36px;
        width: 30px;
        height: 6px;
        border-radius: 6px;
        background: mix($secondary-color, $white-color, 20%);
      }

      &::after {
        content: "";
        position: absolute;
        right: -36px;
        width: 30px;
        height: 6px;
        border-radius: 6px;
        background: mix($secondary-color, $white-color, 20%);
      }

      &:hover,
      &.visible {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.period {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 180px;
  border-radius: 10px;
  background-color: mix($primary-color, $white-color, 14%);
  border: 2px solid $white-color;
  color: $primary-color;
  font-weight: 600;
  transition: 0.3s;
  z-index: 1;
  scroll-snap-align: center;
  cursor: pointer;

  &:hover {
    border-color: $primary-color;
  }

  span {
    transition: 0.3s;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    margin-right: 4px;
  }

  &.error {
    background: $light-red-color;
    color: $red-color;

    &.current {
      border-color: $red-color;
    }
  }

  &.current {
    height: 48px;
    border-color: $primary-color;
    flex-shrink: 0;
    transform-origin: center;
    width: max-content;
    padding: 0 10px;
    min-width: 180px;
    box-shadow: 1px 2px 5px 1px rgba(0, 104, 255, 0.25);
  }
}

.add-period {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $light-grey-color;
  border: 2px dashed $grey-color;
  border-radius: 10px;
  margin: 0 8px;
  opacity: 0;
  transform: scale(0.5);
  transition: 0.3s;
  cursor: pointer;

  &:first-child {
    position: relative;
    margin: 0 8px 0 50vw !important;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      right: -30px;
      height: 1px;
      background: $light-grey-color;
      z-index: -1;
    }
  }

  svg {
    color: $grey-color;
    width: 10px;
  }

  &.visible,
  &:hover {
    transform: scale(1);
    opacity: 1;
  }

  &.propal {
    border-radius: 50%;
    opacity: 1;
    transform: scale(1);

    &:first-child {
      margin: 0 38px 0 50vw !important;
    }

    &::before {
      content: "";
      position: absolute;
      left: unset;
      right: -36px;
      width: 30px;
      height: 6px;
      border-radius: 6px;
      background: mix($secondary-color, $white-color, 20%);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0;
  }
}

.date {
  position: absolute;
  top: -20px;
  color: rgba($text-color, 0.5);
  font-size: 12px;
  font-weight: $font-bold;

  &.start-date {
    left: 0;
  }

  &.end-date {
    right: 0;
  }

  &.centered {
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
