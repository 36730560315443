<template>
  <div class="statistics">
    <div class="row">
      <h3 class="section-title">{{ $t("statistics.title") }}</h3>
      <div class="tabs">
        <span
          :key="period"
          v-for="period in EType"
          :class="['p1', { active: period === type }]"
          @click="handleSelect(period)"
        >
          {{ $t(`statistics.${period}`) }}
        </span>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Loader v-if="loading" />
      <div class="row standard-text" v-else>
        <div class="card blue-frame" :class="{ 'tv-mode': loggedInTV }">
          <span class="standard-text">{{
            $t("statistics.completedSprintTotalCount")
          }}</span>
          <span class="kpi">{{ statistics.completedSprintTotalCount }}</span>
        </div>
        <div class="card blue-frame" :class="{ 'tv-mode': loggedInTV }">
          <span class="standard-text">{{
            $t("statistics.sentPropalTotalCount")
          }}</span>
          <span class="highlight" v-if="statistics.wonPropalTotalCount"
            >({{
              $t(
                "statistics.wonPropalTotalCount",
                statistics.wonPropalTotalCount
              )
            }})</span
          >
          <span class="kpi">{{ statistics.sentPropalTotalCount }}</span>
        </div>
        <div class="card blue-frame" :class="{ 'tv-mode': loggedInTV }">
          <span class="standard-text">{{
            $t("statistics.createdProductTotalCount")
          }}</span>
          <span class="highlight" v-if="statistics.inProgressProductTotalCount"
            >({{
              $t(
                "statistics.inProgressProductTotalCount",
                statistics.inProgressProductTotalCount
              )
            }})</span
          >
          <span class="kpi">{{ statistics.createdProductTotalCount }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const EType = ["None", "LastMonth", "CurrentFiscalYear", "PreviousFiscalYear"];

import { mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
export default {
  name: "StatisticsComponent",
  components: { Loader },
  data() {
    return {
      loading: true,
      type: EType[2],
      EType: EType.filter((_, i) => i > 0),
    };
  },
  computed: {
    ...mapGetters({
      statistics: "financials/statistics",
      loggedInTV: "auth/loggedInTV",
    }),
  },
  async mounted() {
    await this.fetch();
    this.loading = false;
  },
  methods: {
    async fetch() {
      const { type } = this;
      this.loading = true;
      await this.$store.dispatch("financials/fetchStatistics", { type });
      this.loading = false;
    },
    handleSelect(period) {
      this.type = period;
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  width: 42px;
  height: 42px;
  margin-top: 24px;
  margin-bottom: 91px;
}
.row {
  display: flex;
  column-gap: 27px;
  &:nth-child(2) {
    margin-top: 24px;
  }
  @media (max-width: $layout-breakpoint-tv) {
    column-gap: 20px;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
}
.card {
  height: 133px;
  max-height: 100%;
  overflow: hidden;
  padding: 1em 2.5em 1.8em 1.8em;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: $layout-breakpoint-tv) {
    height: auto;
    // padding: 0.5em;
  }
  .highlight {
    color: #0068ff;
  }
  span.standard-text {
    display: block;
    font-weight: 900;
  }
  .kpi {
    color: #0068ff;
    font-family: Montserrat;
    font-size: 55px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: right;
    margin-top: auto;
    @media (max-width: $layout-breakpoint-tv) {
      font-size: 40px;
    }
  }
}
.tabs {
  .p1 {
    border: 2px solid rgba(0, 104, 255, 0.15);
    transform: scaleX(-1);
    border-radius: 8px;
    padding: 4px 20px;
    background-color: #ffffff;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
      padding-right: 40px;
      margin-right: -20px;
    }
    &.active {
      background-color: #0068ff;
      border-color: #0068ff;
      color: #ffffff;
    }
  }
}
</style>
