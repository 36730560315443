<template>
  <div class="event">
    <div class="content">
      <span :style="{ color: event.color }">
        <b>{{ event.projectName }}</b
        >, {{ event.clientName }}
      </span>
      <span>- {{ event.name }}</span>
    </div>
    <div class="background" :style="{ backgroundColor: event.color }"></div>
  </div>
</template>

<script>
export default {
  name: "EventComponent",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  min-width: 489px;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 21px;
  margin-top: 8px;
  position: relative;
  overflow: hidden;
  cursor: default;

  .content {
    z-index: 1;
    font-family: $title-font;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 17px;

    > span:nth-child(2) {
      margin-left: 3px;
    }
  }

  .background {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 0;
    opacity: 0.1;
  }
}
</style>
