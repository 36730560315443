<template>
  <svg viewBox="0 0 44 44">
    <title>icon_insert_picture</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Statistics" transform="translate(-435.000000, -885.000000)">
        <g id="Message-Zone" transform="translate(50.000000, 159.000000)">
          <g id="Message-input" transform="translate(15.000000, 619.831956)">
            <g transform="translate(370.000000, 106.168044)">
              <path
                d="M29.0782247,14 L15.9227427,14 C14.8623234,14 14,14.8655042 14,15.9314433 L14,28.0707279 C14,29.13541 14.8623234,30 15.9227427,30 L29.0781678,30 C30.1385871,30 31,29.13541 31,28.0707279 L31,15.9314433 C31,14.8655042 30.1386441,14 29.0782247,14 Z M25,17 C26.1043697,17 27,17.8956916 27,19 C27,20.1043084 26.1043084,21 25,21 C23.8953239,21 23,20.1043084 23,19 C23,17.8956916 23.8953239,17 25,17 Z M28.4544263,28 L22.3965927,28 L16.6086475,28 C16.0886595,28 15.8570794,27.604884 16.0914865,27.1174269 L19.3226465,20.3960324 C19.5567651,19.9086359 20.0029635,19.8651313 20.3189487,20.2987834 L23.5679935,24.7580617 C23.8839786,25.1917744 24.4362747,25.2286745 24.8018182,24.8401024 L25.5966561,23.994793 C25.9619112,23.6062209 26.5000724,23.6543304 26.7981727,24.1015548 L28.8563742,27.1892276 C29.1538975,27.6371186 28.9744143,28 28.4544263,28 Z M16,14 L16,14 L16,14 L16,17 L14,17 L14,16 C14,14.8954305 14.8954305,14 16,14 Z"
                id="Shape"
                fill="#031E41"
                fill-rule="nonzero"
                opacity="0.854354858"
              ></path>
              <rect
                fill="#031E41"
                opacity="0.933290027"
                x="12"
                y="13"
                width="1"
                height="5"
              ></rect>
              <rect
                fill="#031E41"
                opacity="0.933290027"
                transform="translate(12.500000, 15.500000) rotate(-90.000000) translate(-12.500000, -15.500000) "
                x="12"
                y="13"
                width="1"
                height="5"
              ></rect>
              <rect x="0" y="0" width="44" height="44"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
