import { createRouter, createWebHistory } from "vue-router";
import Timeline from "@/views/Timeline.vue";
import TimelineTV from "@/views/TimelineTV.vue";
import Activities from "@/views/Activities.vue";
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import WeatherEdition from "@/views/WeatherEdition.vue";
import RemoteControls from "@/views/RemoteControls.vue";
import Creation from "@/views/Creation.vue";
import Edition from "@/views/Edition.vue";
import RemoteStatsControls from "@/views/remote/RemoteStatsControls.vue";
import RemoteTimeLineControls from "@/views/remote/RemoteTimeLineControls.vue";

const routes = [
  {
    path: "/",
    name: "Timeline",
    component: Timeline,
    meta: {
      middleware: ["auth"],
      navigation: true,
      fixedNavigation: true,
      order: 1,
    },
  },
  {
    path: "/creation",
    name: "Creation",
    component: Creation,
    meta: {
      middleware: ["auth"],
      navigation: true,
    },
  },
  {
    path: "/edition/:id",
    name: "Edition",
    component: Edition,
    meta: {
      middleware: ["auth"],
      navigation: true,
    },
  },
  {
    path: "/activities",
    name: "Activities",
    component: Activities,
    meta: {
      middleware: ["auth"],
      navigation: true,
      fixedNavigation: true,
      order: 2,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: ["admin", "auth"],
      navigation: true,
      fixedNavigation: true,
      order: 3,
    },
  },
  {
    path: "/tv",
    name: "TV",
    component: TimelineTV,
    meta: {
      tv: true,
      navigation: true,
    },
  },
  {
    path: "/remote",
    name: "Remote",
    component: RemoteControls,
    meta: {
      middleware: ["auth"],
      navigation: false,
    },
    children: [
      {
        path: "timeline",
        name: "RemoteTimeLineControls",
        component: RemoteTimeLineControls,
        meta: {
          middleware: ["auth"],
          navigation: false,
        },
      },
      {
        path: "stats",
        name: "RemoteStatsControls",
        component: RemoteStatsControls,
        meta: {
          middleware: ["auth"],
          navigation: false,
        },
      },
    ],
  },
  {
    path: "/projects/weather/edit",
    name: "WeatherEdition",
    component: WeatherEdition,
    meta: {
      middleware: ["auth"],
      navigation: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      navigation: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
