<template>
  <div class="job-infos">
    <div
      class="job-color"
      :style="{
        backgroundColor: businessRole.color,
        boxShadow: `${businessRole.color}10 0px 0px 0px 4px`,
      }"
    ></div>
    <p class="job-name" :style="{ color: businessRole.color }">
      {{ businessRole.name }}
    </p>
  </div>
</template>

<script>
export default {
  name: "JobCard",
  props: {
    businessRole: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.job-infos {
  display: flex;
  align-items: center;
  padding: 12px 35px 12px 12px;
  background-color: #f4f6fa;
  border-radius: 6px;
  .job-color {
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }

  .job-name {
    margin-left: 25px;
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
