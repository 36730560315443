<template>
  <div class="user-menu" @click="openMenu()">
    <UserProfile />
    <div class="menu" :class="{ open: menuOpen }">
      <ComingEvents />
      <div class="buttons">
        <button class="tertiary logout" @click="logout">
          {{ $t("logout") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import ComingEvents from "@/components/userMenu/ComingEvents";
import { onSignout } from "vue-google-identity";

export default {
  name: "UserMenu",
  components: {
    UserProfile,
    ComingEvents,
  },
  data() {
    return {
      socketConnected: false,
      menuOpen: false,
    };
  },
  methods: {
    logout() {
      this.stopSocket();
      this.$store.dispatch("auth/logout");
      onSignout();
      this.$router.push({ name: "Login" });
    },
    async stopSocket() {
      if (!this.$route.query.roomToken) return;
      await this.$socket.invoke(
        "sendInstruction",
        JSON.stringify({
          method: "openRemoteTimeline",
        })
      );
      await this.$socket.invoke(
        "sendInstruction",
        JSON.stringify({
          method: "openProject",
          project: null,
        })
      );
      await this.$socket.invoke(
        "sendInstruction",
        JSON.stringify({
          method: "leaveRoom",
        })
      );
      setTimeout(() => {
        this.$socket.stop();
        this.$router.replace({ query: null });
      }, 1000);
    },
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.user-menu {
  position: relative;
  background-color: $white-color;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 68px;
  width: 68px;

  &:hover,
  &.open {
    .menu {
      transform: scale(1);
    }
  }
}

.menu {
  position: absolute;
  background-color: $white-color;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  top: 80px;
  right: 0;
  overflow: hidden;
  transition: 0.3s;
  transform-origin: top right;
  transform: scale(0);
  width: max-content;

  .buttons {
    display: flex;
    flex-direction: column;
    padding: 30px;

    button.tertiary {
      background-color: rgba(0, 0, 0, 0);
      width: fit-content;
      margin-left: auto;
      text-decoration: underline;
      font-family: $text-font;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      letter-spacing: 0.7px;
      cursor: pointer;

      &.remote {
        margin-bottom: 12px;
        color: $secondary-color;
      }

      &.logout {
        color: $red-color;

        &::after {
          background-color: $red-color;
        }
      }
    }
  }
}

@media (max-width: $layout-breakpoint-small) {
  .user-menu {
    width: auto;
    height: auto;
    background: none;
    box-shadow: none;
    right: 0;
    padding: 0;
  }

  .menu {
    top: 50px;
    padding: 14px 20px;
  }
}
</style>
