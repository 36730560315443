<template>
  <div class="events" v-if="dates.length">
    <div class="section-title uppercase">{{ $t("weeklyEvents") }}</div>
    <div class="list-container">
      <div v-for="date in dates" :key="date">
        <span class="dates">{{ formattedDate(date) }}</span>
        <Event
          v-for="event in weekEventByDate(date)"
          :key="event.projectId"
          :event="event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import calendar from "@/helpers/date.js";
import Event from "@/components/userMenu/Event.vue";
export default {
  name: "ComingEvents",
  components: { Event },
  data() {
    return {
      calendar,
    };
  },
  computed: {
    ...mapGetters({
      nextEvents: "project/nextEvents",
    }),
    weekEvents() {
      return this.nextEvents.filter((item) => {
        const date = new Date(item.date);
        const itemDate = {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
        };
        return calendar.getWeekNumber(itemDate) === this.getCurrentWeekNumber();
      });
    },
    dates() {
      return Array.from(new Set(this.weekEvents.map((item) => item.date)));
    },
  },
  methods: {
    getCurrentWeekNumber() {
      const today = new Date();
      return calendar.getWeekNumber({
        year: today.getFullYear(),
        month: today.getMonth(),
        day: today.getDate(),
      });
    },
    weekEventByDate(date) {
      return this.weekEvents.filter((item) => item.date === date);
    },
    formattedDate(date) {
      return new Date(date).toLocaleString("fr-FR", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.events {
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid rgba(6, 50, 108, 0.09);

  .list-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .dates {
      color: $primary-color;
      font-family: $title-font;
      font-size: 19px;
      font-weight: bold;
      line-height: 21px;
    }
  }
}
</style>
