<template>
  <div class="colors-palette">
    <ColorPickerIcon class="picker-icon" />
    <div class="colors">
      <div
        class="color"
        v-for="color in colorPalette"
        :key="color"
        :style="{ background: color || '#79adf9' }"
        @click.stop="updateColor(color)"
      ></div>
    </div>
  </div>
</template>

<script>
import colorPalette from "@/enums/colorPalette.json";

export default {
  name: "ColorPicker",
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      colorPalette,
    };
  },
  methods: {
    updateColor(color) {
      this.$emit("update:modelValue", color);
    },
  },
};
</script>

<style lang="scss" scoped>
.colors-palette {
  background-color: $white-color;
  position: absolute;
  right: 0;
  top: -72px;
  width: 334px;
  border-radius: 14px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  align-items: center;
  z-index: 10;

  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 40px);
  }

  .color {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 0 0 4px rgba($primary-color, 0.07);
    }
  }

  .picker-icon {
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    margin-right: 10px;
  }
}
</style>
