<template>
  <div class="navigation" id="navigation" :class="{ fixed: fixedPosition }">
    <router-link :to="{ name: 'Timeline' }" class="logo">
      <MiruIcon />
    </router-link>
    <router-link
      :to="{ name: 'Timeline' }"
      class="link"
      :class="{
        selected: routeName === 'Timeline' || routeName === 'TimelineTV',
      }"
    >
      <CalendarIcon /> {{ $t("timeline") }}
    </router-link>
    <router-link
      :to="{ name: 'Activities' }"
      class="link"
      :class="{ selected: routeName === 'Activities' }"
    >
      <GraphIcon />
      {{ $t("activities") }}
    </router-link>
    <router-link
      :to="{ name: 'Dashboard' }"
      class="link"
      :class="{ selected: routeName === 'Dashboard' }"
      v-if="user.roles.includes('Admin')"
    >
      <DashboardIcon />
      {{ $t("Dashboard") }}
    </router-link>
    <div
      class="active-link"
      :class="routeName"
      v-if="
        routeName === 'Timeline' ||
        routeName === 'TimelineTV' ||
        routeName === 'Activities' ||
        routeName === 'Dashboard'
      "
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationComponent",
  data() {
    return {
      routeName: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    fixedPosition() {
      return this.$route.meta.fixedNavigation;
    },
  },
  watch: {
    "$route.name"(value) {
      this.routeName = value;
    },
  },
  mounted() {
    this.routeName = this.$route.name;
  },
};
</script>

<style scoped lang="scss">
.navigation {
  position: absolute;
  top: 20px;
  left: 50px;
  z-index: 1;
  background: $white-color;
  height: 62px;
  padding: 0 20px;
  border-radius: 14px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  overflow: hidden;
  transform-origin: left top;

  &.fixed {
    position: fixed;
  }

  &.reduced {
    width: 46px;
    height: 48px;
    box-shadow: none;
    background-color: rgba($white-color, 0);

    &:hover {
      width: 418px;
      height: 62px;

      background: $white-color;
      box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.05);

      .logo {
        width: 98px;
      }
    }
  }
}

.reduced-logo {
  display: none;
}

.logo {
  width: 98px;
  flex-shrink: 0;
  transition: 0.4s;
}

.link {
  width: 120px;
  height: 42px;
  position: relative;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $title-font;
  font-size: 14px;
  font-weight: 600;
  z-index: 3;
  transition: 0.6s;
  color: $text-color;
  flex-shrink: 0;

  svg {
    width: 28px;
    margin-right: 4px;
  }

  &.selected,
  &.router-link-active {
    color: $white-color;
  }

  &:not(.selected):hover {
    color: $primary-color;
  }
}

.active-link {
  width: 120px;
  height: 42px;
  position: absolute;
  left: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  pointer-events: none;
  border-radius: 11px;
  z-index: 0;
  background-color: $primary-color;
  box-shadow: 1px 2px 5px 0 rgba(3, 86, 208, 0.58);
  flex-shrink: 0;

  &.Activities {
    left: 278px;
  }

  &.Dashboard {
    left: 418px;
  }
}
</style>
