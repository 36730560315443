<template>
  <div class="search-bar">
    <Input
      class="search-input"
      ref="input"
      :placeholder="$t('search')"
      @input="(e) => $emit('research', e.target.value)"
    />
    <SearchIcon />
  </div>
</template>

<script>
import Input from "@/components/inputs/Input";

export default {
  name: "SelectInput",

  components: { Input },
};
</script>

<style scoped lang="scss">
.search-bar {
  display: flex;
  flex-shrink: 0;
  position: relative;

  .search-input {
    margin: 0;
    padding: 0;

    :deep(.input-container) {
      border: none;
      max-width: 100%;
      width: 350px;
      padding: 0 40px 0 25px;
    }
  }

  svg {
    width: 40px;
    position: absolute;
    right: 0;
    top: 4px;
    color: $secondary-color;
  }
}
</style>
