<template>
  <div class="input" :class="{ error, light }">
    <label class="label" v-if="label" :for="label">{{ label }}</label>
    <input
      class="input-container"
      :id="label"
      :type="inputType"
      v-model="value"
      @blur="$emit('blur', $event)"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxLength"
      ref="input"
    />
    <div class="error-message" v-if="error">
      <ErrorIcon />{{ $t("mandatoryField") }}
    </div>
  </div>
</template>

<script>
import { formatNumber, formatStringNumber } from "@/helpers/formater.js";

export default {
  name: "InputComponent",
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
    },
  },
  emits: ["update:modelValue", "blur"],
  data() {
    return {
      value: "",
      error: false,
    };
  },
  computed: {
    inputType() {
      if (this.type === "number") return "text";
      return this.type;
    },
  },
  watch: {
    modelValue(modelValue) {
      if (this.value !== modelValue) {
        this.value =
          this.type === "number"
            ? formatStringNumber(formatNumber(modelValue))
            : modelValue;
      }
    },
    value(value) {
      this.error = false;
      if (this.type === "number" && value) {
        let string = value
          .split("")
          .filter((v) => v !== " " && !isNaN(Number(v)))
          .join("");
        this.value = formatStringNumber(formatNumber(string));
        this.$emit("update:modelValue", formatNumber(string));
      } else {
        this.$emit("update:modelValue", value);
      }
    },
  },
  created() {
    if (this.type === "number") {
      this.value = formatStringNumber(this.modelValue);
    } else {
      this.value = this.modelValue;
    }
  },
  methods: {
    check() {
      this.error = false;

      if (this.required) {
        this.error = this.error || !this.value;
      }

      if (this.type == "number") {
        this.error = this.error || this.value === "0";
      }

      return this.error;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  @include input;
}
</style>
