<template>
  <div class="timeline">
    <div class="scroller" ref="scroller">
      <div
        class="period"
        v-for="(period, index) in periods"
        :key="index"
        :class="{
          previous: index < currentPeriodIndex,
          current: index === currentPeriodIndex,
          next:
            (currentPeriodIndex < 0 && index >= 1) ||
            (currentPeriodIndex >= 0 && index > currentPeriodIndex),
          small:
            currentPeriodIndex >= 0 &&
            index !== currentPeriodIndex &&
            ((index === 0 && currentPeriodIndex - index > 1) ||
              (index === periods.length - 1 && index - currentPeriodIndex > 1)),
        }"
        :style="{
          zIndex:
            index > currentPeriodIndex
              ? periods.length - index - currentPeriodIndex
              : index,
        }"
      >
        {{
          index === currentPeriodIndex
            ? getPeriodFullName(period)
            : getPeriodName(period)
        }}

        <div class="start-date date">
          {{
            new Date(period.startDate).toLocaleDateString("fr-FR", {
              month: "numeric",
              day: "numeric",
            })
          }}
        </div>
        <div class="end-date date">
          {{
            new Date(period.endDate).toLocaleDateString("fr-FR", {
              month: "numeric",
              day: "numeric",
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "MinimalTimeline",
  props: {
    periods: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentPeriodIndex() {
      return this.periods.findIndex(
        (period) =>
          new Date(period.startDate).setHours(0, 0, 0, 0) <=
            new Date().setHours(0, 0, 0, 0) &&
          new Date(period.endDate).setHours(0, 0, 0, 0) >=
            new Date().setHours(0, 0, 0, 0)
      );
    },
  },
  mounted() {
    if (this.currentPeriodIndex >= 0) {
      const modal =
        document.querySelector(".project-detail.modal") ||
        document.querySelector(".edition .project");
      const currentPeriod = document.querySelector(".timeline .period.current");

      gsap.to(this.$refs.scroller, {
        scrollTo: {
          x: currentPeriod,
          offsetX: modal.clientWidth / 2 - currentPeriod.clientWidth - 20,
        },
        duration: 0,
      });
    }
  },
  methods: {
    getPeriodName(period) {
      const name = period.name[0];
      let sprintNumber =
        period.type === "Sprint" && period.name === period.type
          ? this.periods
              .filter((p) => p.type === "Sprint")
              .findIndex((p) => p.startDate === period.startDate) + 1
          : "";

      if (period.type === "Sprint 0") {
        sprintNumber = 0;
      }
      return `${name}${sprintNumber}`;
    },
    getPeriodFullName(period) {
      const name = period.name;
      let sprintNumber =
        period.type === "Sprint" && period.name === period.type
          ? this.periods
              .filter((p) => p.type === "Sprint")
              .findIndex((p) => p.startDate === period.startDate) + 1
          : "";

      return `${name} ${sprintNumber}`;
    },
  },
};
</script>

<style scoped lang="scss">
.timeline {
  position: relative;
  width: 100%;
  margin: 0 -20px;

  &:before {
    content: "";
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  .scroller {
    @include hideScrollbar;

    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 20px;
    overflow: auto;
    width: 100%;
    z-index: 0;
  }

  .period {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 10px;
    background: mix($primary-color, $white-color, 14%);
    color: $primary-color;
    text-transform: uppercase;
    border: 4px solid $white-color;
    font-weight: $font-bold;
    cursor: default;

    &.small {
      height: 40px;
    }

    &.current {
      z-index: 100 !important;
      border: 2px solid $primary-color;
      box-shadow: 1px 2px 5px 1px rgba(0, 104, 255, 0.25);
      min-width: 45px;
      width: fit-content;
      padding: 0 8px;

      .date {
        bottom: unset;
        top: -20px;
        opacity: 1;

        &.end-date {
          right: -15px !important;
        }

        &.start-date {
          left: -15px !important;
        }
      }
    }

    &.next {
      width: 70px;
      padding-left: 25px;
      margin-left: -25px;
      border: 4px solid $white-color;
    }

    &.previous {
      width: 70px;
      padding-right: 25px;
      margin-right: -25px;
      border: 4px solid $white-color;

      .date {
        &.start-date {
          left: 0;
        }

        &.end-date {
          right: -5px;
        }
      }
    }

    &:hover {
      .date {
        opacity: 1;
      }
    }

    &:first-child:not(.previous) {
      .date {
        &.start-date {
          left: 0;
        }

        &.end-date {
          right: -34px;
        }
      }
    }

    &:last-child {
      .date {
        &.start-date {
          left: -15px;
        }
        &.end-date {
          right: 0;
        }
      }
    }
  }

  .date {
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    font-weight: $font-bold;
    color: mix($secondary-color, $white-color, 50%);
    opacity: 0;
    transition: 0.3s;

    &.end-date {
      right: -20px;
    }

    &.start-date {
      left: 0;
    }
  }
}
</style>
