<template>
  <svg viewBox="0 0 44 44">
    <title>icon_edit</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-953.000000, -902.000000)">
        <g transform="translate(-29.000000, 264.000000)">
          <g transform="translate(687.000000, 0.000000)">
            <g transform="translate(291.000000, 635.000000)">
              <g transform="translate(4.000000, 3.000000)">
                <path
                  d="M34,20.5 L34,33.0625 C34,33.580267 33.580267,34 33.0625,34 L10.9375,34 C10.419733,34 10,33.580267 10,33.0625 L10,10.9375 C10,10.419733 10.419733,10 10.9375,10 L23.62,10 C24.137767,10 24.5575,10.419733 24.5575,10.9375 C24.5575,11.455267 24.137767,11.875 23.62,11.875 L11.875,11.875 L11.875,32.125 L32.125,32.125 L32.125,20.5 C32.125,19.982233 32.544733,19.5625 33.0625,19.5625 C33.580267,19.5625 34,19.982233 34,20.5 Z"
                  id="Path"
                  fill="CurrentColor"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M29.5,10.75 L20.5675,19.75 C20.4621471,19.8467985 20.3866791,19.9717111 20.35,20.11 L19.4875,23.605 C19.410177,23.8732042 19.4881952,24.1621603 19.69,24.355 C19.8383727,24.51527 20.0492376,24.6029022 20.2675,24.595 L20.4475,24.5575 L23.9425,23.68 C24.0778552,23.6444278 24.2018117,23.5747022 24.3025,23.4775 L33.25,14.5 C34.1987114,13.450837 34.1582941,11.8421041 33.158095,10.841905 C32.1578959,9.84170594 30.549163,9.80128855 29.5,10.75 Z M32.1175,11.89 C32.3171903,12.0884986 32.4294759,12.3584367 32.4294759,12.64 C32.4294759,12.9215633 32.3171903,13.1915014 32.1175,13.39 L32.1175,13.4275 L30.6175,11.9275 L30.6625,11.8825 C31.0622192,11.4898945 31.7027808,11.4898945 32.1025,11.8825 C32.1025,11.8825 32.0875,11.8975 32.1025,11.905 L32.1175,11.89 Z M23.3275,22.165 L21.37,22.66 L21.8425,20.7025 L29.4475,13.0675 L30.9475,14.5675 L23.3275,22.165 Z"
                  id="Shape"
                  fill="CurrentColor"
                  fill-rule="nonzero"
                ></path>
                <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
