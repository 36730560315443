import gsap from "gsap";
import { Component } from "../component";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Texture } from "@pixi/core";
import { WeatherIcon } from "./projectWeatherIcon";
import { WeatherDetail } from "./projectWeatherDetail";
import { RoundedRectangle } from "./RoundedRectangle";

export class ProjectWeather extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.width = 50;
    this.project = this.props.project;
    this.projectColor = this.props.projectColor;
    if (this.project.periods.length) {
      this.startDate = new Date(this.project.periods[0].startDate).getTime();
    } else {
      this.startDate = new Date().getTime();
    }

    this.container = new Container();

    if (this.project.weather) {
      this.createWeatherBackground();
      this.createWeatherIcon();
      this.createWeatherDetailBackground();
    }
  }

  createWeatherBackground() {
    const { container } = this;
    this.iconContainer = new Container();
    this.iconContainer.pivot.set(0, 50);
    this.iconContainer.position.y = 50;

    this.projectWeather = new RoundedRectangle({
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      uRadius: [0.5, 0.5, 0.5, 0.5],
    });

    this.iconContainer.addChild(this.projectWeather.mesh);

    this.clickContainer = new Sprite(Texture.WHITE);
    this.clickContainer.width = 50;
    this.clickContainer.height = 50;
    this.clickContainer.alpha = 0;
    this.clickContainer.position.y = 0;

    this.onWeatherHover = this.onWeatherHover.bind(this);
    this.onWeatherLeave = this.onWeatherLeave.bind(this);
    this.clickContainer.interactive = true;
    this.clickContainer.on("mouseover", () => this.onWeatherHover());
    this.clickContainer.on("mouseout", () => this.onWeatherLeave());

    container.addChild(this.clickContainer);
    container.addChild(this.iconContainer);
  }

  createWeatherDetailBackground() {
    const { container } = this;
    this.weatherDetailBackground = new WeatherDetail({
      project: this.project,
      projectColor: this.projectColor,
    });
    this.weatherDetailBackground.container.scale.set(0);
    this.optimizeObject(this.weatherDetailBackground.container);

    container.addChild(this.weatherDetailBackground.container);
    this.addComponent(this.weatherDetailBackground);
  }

  createWeatherIcon() {
    this.iconSprite = new WeatherIcon({
      weather: this.project.weather,
      projectColor: this.projectColor,
    });
    this.iconSprite.container.width = 37.5;
    this.iconSprite.container.height = 37.5;
    this.optimizeObject(this.iconSprite.container);

    this.iconContainer.addChild(this.iconSprite.container);
    this.addComponent(this.iconSprite);
  }

  onWeatherHover() {
    gsap.to(this.iconContainer.scale, {
      x: 0.3,
      y: 0.3,
      ease: "expo.out",
      duration: 0.628,
    });
    gsap.to(this.iconSprite.container, {
      alpha: 0,
      ease: "linear",
      duration: 0.3,
    });
    gsap.to(this.weatherDetailBackground.container, {
      width: 422,
      height: 160,
      alpha: 1,
      ease: "expo.out",
      duration: 0.628,
    });
  }

  onWeatherLeave() {
    gsap.to(this.iconContainer.scale, {
      x: 1,
      y: 1,
      ease: "expo.out",
      duration: 0.628,
    });
    gsap.to(this.iconSprite.container, {
      alpha: 1,
      ease: "linear",
      duration: 0.3,
    });
    gsap.to(this.weatherDetailBackground.container, {
      width: 0,
      height: 0,
      alpha: 0,
      ease: "expo.out",
      duration: 0.628,
    });
  }
}
