import getters from "./getters.js";
import actions from "./actions.js";

export const state = {
  data: [],
  statistics: {},
  loading: false,
};

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
};
