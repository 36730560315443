<template>
  <div class="input" :style="{ marginLeft: marginLeft }">
    <div
      class="input-container"
      ref="input"
      :style="{ height: (users.length + 1) * 44 + 'px' }"
    >
      <div class="column">
        <div class="options">
          <div
            class="option"
            v-for="user in filteredUsers"
            :key="`${user.firstName} ${user.lastName}`"
            :class="{
              selected:
                currentUser &&
                `${user.firstName} ${user.lastName}` ===
                  `${currentUser.firstName} ${currentUser.lastName}`,
            }"
            @click.stop="selectDefaultRole(user)"
          >
            <TeamMember
              :userId="user.id"
              :hoverDisabled="true"
              class="picture"
            />
            {{ `${user.firstName} ${user.lastName}` }}
            <div class="see-more" @click.stop="selectUser(user.id)">
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
      <div class="column" :class="{ open: isOpen }">
        <div class="options">
          <div
            class="option"
            v-for="role in businessRoles"
            :key="role.name"
            :class="{ selected: role.name === currentRole }"
            :style="{ color: role.color || '#06326c' }"
            @click.stop="
              () => {
                $emit('updateRole', role.name);
              }
            "
          >
            {{ role.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamMember from "@/components/TeamMember.vue";

import { mapGetters } from "vuex";

export default {
  name: "TeamMemberSelect",
  components: {
    TeamMember,
  },
  props: {
    currentUserId: {
      type: String,
    },
    currentRole: {
      type: String,
    },
    referrerSelect: {
      type: Boolean,
    },
  },
  emits: ["updateUser", "updateRole"],
  data() {
    return {
      isOpen: false,
      marginLeft: "0",
    };
  },
  computed: {
    ...mapGetters({
      businessRoles: "user/businessRoles",
      users: "user/users",
      project: "project/currentProject",
    }),
    currentUser() {
      return this.users.find((user) => user.id === this.currentUserId);
    },
    filteredUsers() {
      return this.referrerSelect
        ? this.users
        : this.users.filter(
            (user) =>
              !this.project.members.find((member) => member.id === user.id)
          );
    },
  },
  mounted() {
    this.isInputOffset();
  },
  methods: {
    selectDefaultRole(user) {
      this.$emit("updateUser", user.id);
      const defaultRole = this.businessRoles.find(
        (role) => role.name === user.businessRole
      ) || { name: "Product Owner" };
      this.$emit("updateRole", defaultRole.name);
    },
    selectUser(userId) {
      this.$emit("updateUser", userId);
      this.isOpen = true;
    },
    isInputOffset() {
      const rightOffset = this.$refs.input?.getBoundingClientRect().right;

      if (window.innerWidth < rightOffset) {
        this.marginLeft = `${window.innerWidth - rightOffset}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  @include input;
  position: relative;
  overflow: visible;

  .input-container {
    position: absolute;
    top: 0;
    padding: 0;
    display: flex;
    width: auto;
    transition: 0.3s;
    overflow: visible;
    border: none;
    background: none;
    max-height: 276px;
    z-index: 100;

    &:hover {
      background: none;
    }
  }

  .column {
    position: relative;
    height: 100%;
    background: $white-color;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid rgba($primary-color, 0.38);
    box-shadow: 1px 2px 14px -6px rgba(6, 50, 108, 0.19);

    &:last-child {
      left: 0;
      translate: -100% 0;
      z-index: -1;
      transition: 0.6s ease-in-out;

      .option {
        padding-left: 30px;
      }

      &.open {
        translate: -15px 0;
      }
    }
  }

  .options {
    width: max-content;
    height: 100%;
    overflow-y: auto;
    opacity: 1;

    .option {
      padding: 0 15px;
      height: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover,
      &.selected {
        background-color: mix($primary-color, $white-color, 8%);
      }

      .picture {
        pointer-events: none;
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }

      .see-more {
        margin-left: auto;
        padding: 10px;

        svg {
          width: 10px;
        }
      }
    }
  }
}
</style>
