export default {
  async getUsers({ state }) {
    const { users } = await this.$axios.$get("/Users?includeDeleted=true");
    state.users = users.filter((user) => !user.deletedAt);
    state.usersDeleted = users.filter((user) => user.deletedAt);
  },
  async getBusinessRoles({ state }) {
    const { businessRoles } = await this.$axios.$get("/BusinessRoles");
    state.businessRoles = businessRoles;
  },

  async deleteBusinessRole({ state }, businessRoleName) {
    await this.$axios.$delete("/BusinessRoles", {
      data: { name: businessRoleName },
    });
    state.businessRoles = state.businessRoles.filter(
      (businessRole) => businessRole.name !== businessRoleName
    );
  },
  async createBusinessRoles({ state }, businessRoleData) {
    const { businessRole } = await this.$axios.$post(
      "/BusinessRoles",
      businessRoleData
    );

    state.businessRoles.push(businessRole);
  },
  async updateBusinessRole({ state }, user) {
    try {
      if (user.businessRole) {
        await this.$axios.put(
          `/Users/${user.id}/businessrole`,
          user.businessRole,
          { headers: { "Content-Type": "application/json" } }
        );
        const index = state.users.findIndex((p) => p.id === user.id);
        state.users.splice(index, 1, user);
      }
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateRole({ state }, user) {
    try {
      if (user.roles) {
        await this.$axios.$put(`/Users/${user.id}/roles`, user.roles[0], {
          headers: { "Content-Type": "application/json" },
        });
        const index = state.users.findIndex((p) => p.id === user.id);
        state.users.splice(index, 1, user);
      }
    } catch (err) {
      throw new Error(err);
    }
  },
  async deleteUser({ state }, userId) {
    await this.$axios.$delete(`/Users/${userId}`);
    state.users = state.users.filter((user) => user.id !== userId);
  },
};
