import { Component } from "../component";
import { Container } from "@pixi/display";
import { BitmapText } from "@pixi/text-bitmap";
import { RoundedRectangle } from "./RoundedRectangle";

import Data from "./data";

export class PropalSprint extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    const { dayInMs } = Data;

    this.sprint = this.props.sprint;
    this.propal = this.props.propal;
    this.sprintIndex = this.props.sprintIndex;
    this.projectStart = this.props.projectStart;

    this.startDate =
      new Date(this.sprint.startDate).getTime() || new Date().getTime();
    this.endDate =
      new Date(this.sprint.endDate).getTime() || new Date().getTime();

    this.container = new Container();

    this.sprintDurationInDays = (this.endDate - this.startDate) / dayInMs;

    this.createSprint();
    if (!this.sprintIndex) {
      this.createSprintName();
    }
  }

  createSprint() {
    const { container } = this;
    const attrs = {
      x: 0,
      y: 24,
      width: 1,
      height: 20,
      uRadius: [1.0, 1.0, 1.0, 1.0],
      uAlpha: 0.5,
      uColor: 0x06326c,
    };
    if (this.sprint.type === "Retour client") {
      attrs.uAlpha = 1.0;
    }
    this.progressQuad = new RoundedRectangle(attrs);

    container.addChild(this.progressQuad.mesh);
  }

  createSprintName() {
    const { container } = this;
    const text =
      this.propal.type === 1
        ? `${this.propal.clientName} - ${this.propal.name}`
        : "propale";

    this.propalSprintName = new BitmapText(`${text}`, {
      fontName: "Montserrat-SemiBold",
      fontSize: 14,
      align: "left",
      tint: 0x06326c,
    });
    this.propalSprintName.position.y = 4;

    this.optimizeObject(this.propalSprintName);

    container.addChild(this.propalSprintName);
  }

  refreshSprintPosition() {
    const { dayWidth, dayInMs } = Data;
    const sprintWidth = this.sprintDurationInDays * dayWidth + dayWidth;

    this.container.x =
      ((this.startDate - this.projectStart) / dayInMs) * dayWidth;
    this.progressQuad.refresh(Math.max(20, sprintWidth));
  }

  update() {
    this.refreshSprintPosition();
  }
}
