import { gsap } from "gsap";

export class Component {
  components = [];
  id = 0;

  constructor(props) {
    this.props = props;
    this.init();
  }

  init() {}

  onEnter() {
    const tl = new gsap.timeline();
    return tl;
  }

  refresh() {
    this.components.forEach((component) => {
      if ("refresh" in component) {
        component.refresh();
      }
    });
  }

  update() {
    this.components.forEach((component) => component.update());
  }

  resize() {
    this.components.forEach((component) => component.resize());
  }

  onLeave() {
    const tl = new gsap.timeline();
    return tl;
  }

  optimizeObject(obj) {
    obj.filters = false;
    obj.interactive = false;
    obj.interactiveChildren = false;
    obj.cullable = true;
  }

  onScroll() {}

  dispose() {
    this.components.forEach((component) => component.dispose());
  }

  addComponent(component) {
    this.components.push(component);
  }

  removeComponent(id) {
    this.components = this.components.filter(
      (component) => component.id !== id
    );
  }
}
