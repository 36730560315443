<template>
  <div class="turn-over">
    <div class="block">
      <transition name="fade" mode="out-in">
        <Loader v-if="loading" />
        <template v-else>
          <div class="row">
            <Details
              :data="financials.slice(-5)"
              :idx="idx"
              @handleIdx="(i) => (idx = i)"
            />
            <Graph
              v-if="financials.length > 0"
              :data="financials.slice(-4)"
              :idx="idx"
            />
          </div>
        </template>
      </transition>
    </div>
    <Statistics />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
import Details from "@/components/activities/Details.vue";
import Graph from "@/components/graph/index";
import Statistics from "@/components/activities/Statistics.vue";
export default {
  name: "TurnOver",
  components: { Loader, Graph, Statistics, Details },
  data() {
    return {
      loading: true,
      idx: 0,
    };
  },
  computed: {
    ...mapGetters({
      financials: "financials/data",
    }),
  },
  async mounted() {
    await this.$store.dispatch("financials/fetch");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.loader {
  width: 42px;
  height: 42px;
}
.turn-over {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  @media (max-width: $layout-breakpoint-tv) {
    row-gap: 20px;
  }
  .block {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .row {
    width: 100%;
    flex: 1;
    display: flex;
    column-gap: 35px;
    // @media (max-width: $layout-breakpoint-small) {}
  }
  &.card {
    .activities & {
      padding: 24px 30px 30px;
      @media (max-width: $layout-breakpoint-tv) {
        padding: 20px;
      }
    }
  }
}
</style>
