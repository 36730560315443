<template>
  <svg viewBox="0 0 44 44">
    <title>icon_search</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-302.000000, 1.000000)">
        <g transform="translate(302.000000, -1.000000)">
          <g transform="translate(11.000000, 12.000000)">
            <path
              d="M2.45718535,14.8797763 C-0.899839653,11.5227326 -0.806648241,6.16384099 2.67273495,2.68443849 C6.16065476,-0.803500683 11.5280533,-0.904517889 14.8850783,2.45181436 C16.9424032,4.5091507 17.7676096,7.45074328 17.086103,10.3169287 C17.0185214,10.5957931 16.810797,10.8120553 16.5397594,10.8881738 C16.2608966,10.9642924 15.977054,10.8924422 15.7735979,10.688985 C15.5879265,10.5026011 15.5153653,10.2365417 15.5708533,9.97830761 C16.0702454,7.64139548 15.3887388,5.25824308 13.7376146,3.60782112 C10.9994944,0.868974329 6.49571702,0.919482932 3.69712911,3.71737499 C0.920594121,6.49463677 0.890715959,10.9770974 3.62954755,13.7159442 C5.28920835,15.3749029 7.66381091,16.06495 9.98719375,15.5698234 C10.2454264,15.5150464 10.5121958,15.5954334 10.6985786,15.7811059 C10.8977663,15.9802947 10.9696162,16.2641388 10.8977663,16.5387349 C10.8173798,16.8140424 10.6053872,17.0174995 10.322256,17.0808131 C7.45608654,17.7623236 4.51877858,16.9413809 2.45718535,14.8797763"
              id="Fill-3"
              fill="CurrentColor"
            ></path>
            <path
              d="M2.45718535,14.8797763 C-0.899839653,11.5227326 -0.806648241,6.16384099 2.67273495,2.68443849 C6.16065476,-0.803500683 11.5280533,-0.904517889 14.8850783,2.45181436 C16.9424032,4.5091507 17.7676096,7.45074328 17.086103,10.3169287 C17.0185214,10.5957931 16.810797,10.8120553 16.5397594,10.8881738 C16.2608966,10.9642924 15.977054,10.8924422 15.7735979,10.688985 C15.5879265,10.5026011 15.5153653,10.2365417 15.5708533,9.97830761 C16.0702454,7.64139548 15.3887388,5.25824308 13.7376146,3.60782112 C10.9994944,0.868974329 6.49571702,0.919482932 3.69712911,3.71737499 C0.920594121,6.49463677 0.890715959,10.9770974 3.62954755,13.7159442 C5.28920835,15.3749029 7.66381091,16.06495 9.98719375,15.5698234 C10.2454264,15.5150464 10.5121958,15.5954334 10.6985786,15.7811059 C10.8977663,15.9802947 10.9696162,16.2641388 10.8977663,16.5387349 C10.8173798,16.8140424 10.6053872,17.0174995 10.322256,17.0808131 C7.45608654,17.7623236 4.51877858,16.9413809 2.45718535,14.8797763 Z"
              id="Stroke-5"
              stroke="CurrentColor"
              stroke-width="0.5"
            ></path>
            <path
              d="M19.2542616,20.7040585 L13.2587103,14.7084739 C12.8574893,14.3072506 12.8574893,13.6541957 13.2579989,13.2529724 C13.6535289,12.8581516 14.3065802,12.8581516 14.7078012,13.2586635 L20.7033525,19.2549595 C21.0988825,19.6497803 21.0988825,20.3028352 20.7033525,20.6983673 C20.3028429,21.0995906 19.6497916,21.0995906 19.2542616,20.7040585"
              id="Fill-7"
              fill="CurrentColor"
            ></path>
            <path
              d="M19.2542616,20.7040585 L13.2587103,14.7084739 C12.8574893,14.3072506 12.8574893,13.6541957 13.2579989,13.2529724 C13.6535289,12.8581516 14.3065802,12.8581516 14.7078012,13.2586635 L20.7033525,19.2549595 C21.0988825,19.6497803 21.0988825,20.3028352 20.7033525,20.6983673 C20.3028429,21.0995906 19.6497916,21.0995906 19.2542616,20.7040585 Z"
              id="Stroke-9"
              stroke="CurrentColor"
              stroke-width="0.5"
            ></path>
          </g>
          <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
