const instance = {};

function authHelper(Vue, { router, store }) {
  if (!store || !router) throw new Error("mission required params");
  instance.store = store;
  instance.router = router;

  router.beforeEach(async (to, from, next) => {
    if (to.meta.middleware && to.meta.middleware.includes("auth")) {
      if (store.getters["auth/loggedIn"] || store.getters["auth/loggedInTV"]) {
        if (from.name === "Login") {
          await store.dispatch("project/getProjects");
          store.dispatch("user/getUsers");
          store.dispatch("user/getBusinessRoles");
          store.dispatch("project/getClients");
          store.dispatch("project/getGlobalWeather");
        }
        if (to.meta.middleware.includes("admin")) {
          if (store.getters["auth/role"].includes("Admin")) {
            return next();
          } else {
            return next(from);
          }
        }
        return next();
      } else {
        const user = localStorage.getItem("miruUserInfo");

        if (user) {
          await store.dispatch("auth/registerUser", JSON.parse(user));
          await store.dispatch("project/getProjects");
          store.dispatch("user/getUsers");
          store.dispatch("user/getBusinessRoles");
          store.dispatch("project/getClients");
          store.dispatch("project/getGlobalWeather");

          if (to.meta.middleware.includes("admin")) {
            if (store.getters["auth/role"].includes("Admin")) {
              return next();
            } else {
              return next(from);
            }
          }
          return next();
        } else {
          let query = null;
          if (to.fullPath.includes("?") || to.name === "WeatherEdition") {
            query = { redirectUrl: to.fullPath };
          }

          return next({ name: "Login", query });
        }
      }
    }
    return next();
  });

  Object.defineProperties(Vue.config.globalProperties, {
    $auth: {
      get() {
        return instance;
      },
    },
  });
}

export default authHelper;
