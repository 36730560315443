<template>
  <div id="app">
    <transition name="modal" mode="out-in">
      <Modal v-if="modalType" />
    </transition>
    <Navigation v-if="loggedIn && !loggedInTV && $route.meta.navigation" />
    <Topbar v-if="(loggedIn || loggedInTV) && $route.meta.navigation" />
    <router-view v-slot="{ Component }">
      <transition :name="transitionName" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/modals/Template";
import Navigation from "@/components/Navigation";
import Topbar from "@/components/Topbar.vue";

export default {
  name: "App",
  components: {
    Modal,
    Navigation,
    Topbar,
  },
  data() {
    return {
      transitionName: "slide-left",
    };
  },
  computed: {
    ...mapGetters({
      modalType: "modals/modalType",
      loggedIn: "auth/loggedIn",
      loggedInTV: "auth/loggedInTV",
    }),
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.order < from.meta.order) {
        this.transitionName = "slide-right";
      } else this.transitionName = "slide-left";
      next();
    });
  },
};
</script>

<style lang="scss">
@import "@/styles/app.scss";

#app {
  width: 100vw;
  height: 100vh;
}
</style>
