<template>
  <div class="input" :class="{ error, propal }">
    <label class="label" :for="label">{{ label }}</label>
    <div class="options">
      <div
        class="option"
        v-for="option in options"
        :key="option.name"
        @click="() => $emit('update:modelValue', option.name)"
        :class="{
          selected: modelValue === option.name,
        }"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectButtons",
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
    },
    modelValue: {
      type: String,
    },
    propal: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      error: false,
    };
  },
  methods: {
    check() {
      this.error = false;

      if (this.required) {
        this.error = this.error || !this.modelValue;
      }

      return this.error;
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  @include input;

  &.error {
    .option {
      border-color: $red-color;
      color: $red-color;
    }
  }

  .options {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .option {
    background-color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid mix($primary-color, $white-color, 50%);
    height: 50px;
    width: max-content;
    padding: 0 20px;
    font-size: 14px;
    line-height: 1.1;
    color: $primary-color;
    font-weight: $font-bold;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      border-color: $primary-color;
    }

    &.selected {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white-color;
      box-shadow: 1px 2px 5px 0 rgba($primary-color, 0.58);
    }
  }

  &.propal {
    .option {
      border-radius: 25px;
      color: $secondary-color;
      border-color: mix($secondary-color, $white-color, 50%);

      &:hover {
        border-color: $secondary-color;
      }

      &.selected {
        background: $secondary-color;
        color: $white-color;
        box-shadow: 1px 2px 5px 0 rgba($secondary-color, 0.58);
      }
    }
  }
}
</style>
