import { Container } from "@pixi/display";
import { Component } from "../component";
import { Project } from "./project";
import { invlerp } from "@/helpers/math";

export class Projects extends Component {
  constructor(props) {
    super(props);
  }

  init() {
    this.projects = this.props.projects;
    this.projectSprites = [];

    this.container = new Container();
    this.container.y = 220;
    this.container.x = 0;
    this.container.height = 2000;

    this.projects.forEach((project, index) => {
      const positionY = index
        ? this.projectSprites[index - 1].container.y +
          this.projectSprites[index - 1].container.height +
          10
        : 0;
      const newProject = new Project({ project, index, positionY });
      this.addComponent(newProject);
      this.projectSprites.push(newProject);
      this.container.addChild(newProject.container);
    });
    this.setZoomLevel();
  }

  updateProgress() {
    this.projectSprites.forEach((project) => {
      project.updateProgress();
    });
  }

  setZoomLevel(vars = { zoomLevel: 2 }) {
    this.projectSprites.forEach((project) => {
      project.container.children.forEach((child) => {
        child.children.forEach((children) => {
          if (children.meta) {
            const { zoomLevel } = children.meta;
            const lower = invlerp(zoomLevel - 0.5, zoomLevel, vars.zoomLevel);
            const upper =
              1.0 - invlerp(zoomLevel, zoomLevel + 0.5, vars.zoomLevel);
            children.alpha = lower * upper;
          }
        });
      });
    });
  }
}
