<template>
  <svg viewBox="0 0 10 10">
    <title>icon_add</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-218.000000, -508.000000)" fill="CurrentColor">
        <g id="Jobs" transform="translate(50.000000, 155.000000)">
          <g id="CTA-Add" transform="translate(26.000000, 337.000000)">
            <path
              d="M147,16 C147.552,16 148,16.448 148,17 L148,17 L148,20 L151,20 C151.512571,20 151.935469,20.3862857 151.993268,20.8834315 L152,21 C152,21.552 151.552,22 151,22 L151,22 L148,22 L148,25 C148,25.5125714 147.613714,25.9354694 147.116569,25.9932682 L147,26 C146.448,26 146,25.552 146,25 L146,25 L146,22 L143,22 C142.487429,22 142.064531,21.6137143 142.006732,21.1165685 L142,21 C142,20.448 142.448,20 143,20 L143,20 L146,20 L146,17 C146,16.4874286 146.386286,16.0645306 146.883431,16.0067318 Z"
              id="icon_add"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
