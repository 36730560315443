import { DateTime } from "luxon";

export default {
  projects: ({ projects, filterByMember }) => {
    if (filterByMember) {
      return [...projects].filter(
        (project) =>
          project.members.find((m) => m.id === filterByMember.id) ||
          project.referrer.id === filterByMember.id
      );
    } else {
      return projects;
    }
  },
  nextEvents: ({ projects }) => {
    const result = projects.reduce((acc, project) => {
      const events = project.periods.reduce((acc2, period) => {
        const events2 = period.events.map((event) => ({
          ...event,
          projectName: project.name,
          clientName: project.clientName,
          color: project.color,
          projectId: project.id,
        }));
        return acc2.concat(events2);
      }, []);
      return acc.concat(events);
    }, []);

    const currentWeekNumber = DateTime.utc(new Date()).weekNumber;

    return result
      .filter(
        (event) =>
          DateTime.utc(new Date(event.date)).weekNumber === currentWeekNumber
      )
      .sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
  },
  currentProject: ({ currentProject }) => currentProject,
  clients: ({ clients }) => clients,
  globalWeather: ({ globalWeather }) => globalWeather,
  filterByMember: ({ filterByMember }) => filterByMember,
};
